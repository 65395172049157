import { Component, Injector, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDate, NgbDateStruct, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DateParts } from '@mt-ng2/date-module';

import { CustomFormControlComponentBase } from '../custom-form-component.base';
import { DynamicFormModuleConfig } from '../../libraries/dynamic-form-module.config';

@Component({
    styles: [
        `
            .selected-date {
                color: white;
                background-color: #337ab7;
            }
            .date-picker-day-view {
                text-align: center;
                width: 2rem;
                height: 2rem;
                line-height: 2rem;
                border-radius: 0.25rem;

                &.outside {
                    opacity: 0.5;
                }
            }
            .fa-calendar {
                margin-left: 8px;
            }
            .selected-date-label {
                min-width: 170px;
            }
            .date-picker-styles {
                min-width: 170px;
            }
        `,
    ],
    templateUrl: './form-date-picker.component.html',
})
export class FormDatePickerComponent extends CustomFormControlComponentBase {
    @ViewChild('datePicker', { static: false }) datePicker: ElementRef;

    model: NgbDateStruct;
    showDatePicker = false;
    selectedDateText: string;
    firstDayOfTheWeek: number;
    datepickerMinDate: NgbDateStruct = null;
    datepickerMaxDate: NgbDateStruct = null;

    get showClearButton(): boolean {
        let showClearButton = this.config.type.datepickerOptions?.showClearButton;
        if (showClearButton === undefined) {
            return this.moduleConfig.datePickerShowClearButton ?? false;
        } else {
            return showClearButton;
        }
    }

    constructor(injector: Injector, private datePipe: DatePipe, private moduleConfig: DynamicFormModuleConfig) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.config.value && !(this.config.value instanceof Date)) {
            this.config.value = new Date(this.config.value.toString());
        }

        this.firstDayOfTheWeek = this.getFirstDayOfTheWeek();
        this.datepickerMinDate = this.getDatepickerMinDate();
        // I set this to null so I always have a future date to trigger the hack logic below
        // this is reset when the date is reset to it's config value.
        this.datepickerMaxDate = null;
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        setTimeout(() => {
            this.getControl().patchValue(this.config.value);
            // HACK: This is a patch because the initial values of the ng bootstrap component don't get set properly with Ivy.
            // To fix this we set the date initially to something in the future then later back so it is displayed correctly.
            const tempDate = new Date((this.config.value as Date) ?? new Date()).mtDate.add(1, DateParts.years).add(1, DateParts.months).date;
            this.handleValueChange(tempDate);

            this.selectedDateText = this.dateAsText();

            this.subscriptions.add(this.getControl().valueChanges.subscribe(this.handleValueChange.bind(this)));

            this.changeDetectorRef.detectChanges();
        });
        setTimeout(() => {
            this.datepickerMaxDate = this.getDatepickerMaxDate();
            this.handleValueChange((this.config.value as Date) ?? new Date());
        }, 100);
    }

    handleValueChange(value: Date | string): void {
        if (!value) {
            // value is null when we are clearing out the date, so default to reset calendar to today
            value = new Date();
        }
        if (typeof value === 'string') {
            value = new Date(value);
        }
        this.selectedDateText = this.dateAsText();
        this.changeDetectorRef.detectChanges();
        this.navigateCalendarAndSetSelectedDateOnChange(value);
    }

    toggleDatePicker(): void {
        if (this.getControl().enabled) {
            this.showDatePicker = !this.showDatePicker;
        }
    }

    dateSelected(date: NgbDate): void {
        this.showDatePicker = false;
        this.getControl().patchValue(new Date(date.year, date.month - 1, date.day));
        this.getControl().markAsDirty();
    }

    getFirstDayOfTheWeek(): number {
        return this.config?.type?.datepickerOptions?.firstDayOfTheWeek;
    }

    getDatepickerMinDate(): NgbDateStruct {
        return this.config?.type?.datepickerOptions?.minDate?.day ? this.config.type.datepickerOptions.minDate : null;
    }

    getDatepickerMaxDate(): NgbDateStruct {
        return this.config?.type?.datepickerOptions?.maxDate?.day ? this.config.type.datepickerOptions.maxDate : null;
    }

    navigateCalendarAndSetSelectedDateOnChange(dateValue: Date): void {
        let ngbDate: NgbDate = new NgbDate(dateValue.getFullYear(), dateValue.getMonth() + 1, dateValue.getDate());
        let datepicker: any = this.datePicker;
        this.model = ngbDate;
        (<NgbDatepicker>datepicker).navigateTo({
            day: ngbDate.day,
            month: ngbDate.month,
            year: ngbDate.year,
        });
    }

    dateAsText(): string {
        let control = this.getControl();
        let value = control.value?.value ? new Date(control.value.value) : control.value;
        let placeHolder = this.config.placeholder ?? 'Select a date...';
        return value && value instanceof Date ? this.datePipe.transform(value) : placeHolder;
    }

    clear(): void {
        this.getControl().reset();
    }
}
