import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDonorImage } from '@model/interfaces/donor-image';
import { IImage } from '@mt-ng2/photo-control/libraries/mt-photo.component.library';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PhotoUploadService {
    constructor(private http: HttpClient) { }

    uploadPhotos(donorId: number, files: File[]): Observable<IDonorImage[]> {
        let formData: FormData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i], files[i].name);
        }

        return this.http.post<IDonorImage[]>(`/donor-portal/photos/${donorId}`, formData, { observe: 'body' });
    }

    deletePhoto(donorId: number, photoId: number): Observable<object> {
        return this.http.delete(`/donor-portal/photos/${donorId}/${photoId}`, {
            responseType: 'text' as 'json',
        });
    }
}
