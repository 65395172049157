import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, ViewRef, OnDestroy, Input } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { Observable, Subject, Subscription, forkJoin } from 'rxjs';
import { debounceTime, finalize, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PreScreeningService } from '../../../../admin-portal/common/services/pre-screening.service';
import { PreScreeningDynamicConfig } from './pre-screening.dynamic-config';
import { IPreScreening } from '@model/interfaces/pre-screening';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { IReferralType } from '@model/interfaces/referral-type';
import { IGender } from '@model/interfaces/gender';
import { GenderService } from '../../../../admin-portal/recipients/outcomes/service/gender.service';
import { CommonService } from '@common/services/common.service';
import { ReferralTypeService } from '@common/services/referral-type.service';
import { UnitsOfMeasurementService } from '@common/services/units-of-measurement.service';
import { IPreScreeningDynamicControlsParameters } from '@model/form-controls/pre-screening.form-controls';
import { IEducationCompletedType } from '@model/interfaces/education-completed-type';
import { ApplicationDataService } from '../../../../admin-portal/applications/application-data/services/application-data.service';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { PreScreeningDynamicControlsPartial } from '@model/partials/pre-screening-partial.form-controls';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ApplicationService, ICurrentSectionAndStatus } from '../../../../donor-portal/donor-application/services/application.service';
import { FormSections } from '@model/enums/form-sections.enum';
import { UnitsOfMeasurementEnums } from '@model/enums/units-of-measurement.enum';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { IApplicationPreScreeningChildren } from '@model/interfaces/application-pre-screening-children';
import { appPaths } from '../../../../donor-portal/default-routes/app-paths.library';
import { ILoggedIn } from '@mt-ng2/auth-module';
import { SharedFunctionsService } from '../../../../admin-portal/common/services/shared-functions-service';
import { PageTitlesService } from '@mt-ng2/page-titles';

@Component({
    selector: 'app-pre-screening',
    styles: [
        `
        .input-scaffold {
            .input-rounded {
                border-radius:0px!important;
            }
        }

        p {
            padding-bottom:15px;
            line-height: 22px;
        }

        ul {
            padding-left:15px;
            list-style-type: disc;
        }
            li {
            font-size:14px;
            padding-top: 5px;
            padding-bottom: 2px;
            list-style-type: disc;
            line-height: 20px;
        }
        `,
    ],
    templateUrl: './pre-screening.component.html',
})
export class PreScreeningComponent implements OnInit, OnDestroy {

    private configControls = [
        'BirthYear',
        'Email',
        'ConfirmEmail',
        'Height',
        'HeightUnitId',
        'Weight',
        'WeightUnitId',
        'GenderId',
        'EducationCompletedTypeId',
        'IsUsCitizenOrGreenCardHolder',
        'CountryCode',
        'StateCode',
        'HasStd',
        'HasHiv',
        'HasFamilyMedicalHistory',
        'IsAdopted',
        'HasBothOvaries',
        'HasGivenBirth',
        'HowManyLiveBirths',
        'IsPregnantOrBreastFeeding',
        'HasPreviouslyDonatedEggs',
        'HasRegularPeriodsWithoutBirthControl',
        'ReferralTypeId',
    ];
    config: any = { formObject: [], viewOnly: [] };
    @Input() donor: ILoggedIn;
    @Input() denied: boolean;
    @Input() applicationId: number;
    @Input() isPreScreeningComplete: Subject<boolean>;
    @Input() preScreening: IPreScreening;
    @Input() hideTrim: boolean;

    showStatesCurrentAddress$: Observable<boolean>;
    formFactory: PreScreeningDynamicConfig<IPreScreening>;
    doubleClickIsDisabled = false;
    formReady = false;
    @ViewChild('honeypot') honeypot: ElementRef;
    pageLoadTime: Date;

    preScreeningSuccess = false;
    referralTypes: IReferralType[];
    unitsOfMeasurement: IUnitsOfMeasurement[];
    heightUnits: IUnitsOfMeasurement[];
    weightUnits: IUnitsOfMeasurement[];
    states: IStateMetaItem[];
    countries: ICountryMetaItem[];
    genders: IGender[];
    educationCompletedTypes: IEducationCompletedType[];
    formCreated = false;
    preScreeningControls: any;
    preScreeningForm: FormGroup;
    subs = new Subscription();
    currentStatus: ICurrentSectionAndStatus = {
        formGroup: null,
        nextTab: 'tab-medicalIssues',
        nextTabLabel: 'Medical History',
        routerLink: null,
        sectionId: FormSections.PreScreening,
        status: 'In Progress',
    };
    selectedHeightUnitId: number;
    hasGivenBirth?: boolean;
    subscription: Subscription = new Subscription();
    isAdopted: boolean;
    submittedAndSaved = false;
    selectReferralTypeId?: number;

    additionalParameters: IPreScreeningDynamicControlsParameters;
    sharedFunctionsService: SharedFunctionsService;

    copyOfStates: IStateMetaItem[];
    constructor(
        private router: Router,
        private fb: FormBuilder,
        private applicationService: ApplicationService,
        private applicationDataService: ApplicationDataService,
        private notificationService: NotificationsService,
        private referralTypeService: ReferralTypeService,
        private unitsOfMeasurementService: UnitsOfMeasurementService,
        private preScreeningService: PreScreeningService,
        private commonService: CommonService,
        private genderService: GenderService,
        private pageTitleService: PageTitlesService,
        private elementRef: ElementRef,
    ) { }

    ngOnInit(): void {

        this.pageTitleService.setPageTitle('Donor Application - Pre-Screening');
        //
        // Per user requirements, the list of US dependent territories should not available to potential donors
        //
        const usDependentTerritories = ['AS', 'GU', 'MP', 'PR', 'VI'];
        if (!this.hideTrim) {
            this.hideTrim = false;
        }

        forkJoin([
            this.referralTypeService.getItems(),
            this.unitsOfMeasurementService.getItems(),
            this.commonService.getAllStates(),
            this.commonService.getCountries(),
            this.genderService.getItems(),
            this.applicationDataService.getPersonalInformationMetaData(),
        ]).subscribe((metaItems) => {
            this.referralTypes = this.referralTypeService.items;
            this.heightUnits = this.unitsOfMeasurementService.getHeightUnits();
            this.weightUnits = this.unitsOfMeasurementService.getWeightUnits();
            this.countries = this.commonService.getCountryMetaItems();
            this.states = this.commonService.getAllStateMetaItems().filter((s) => !usDependentTerritories.includes(s.StateCode.trim()));
            this.genders = this.genderService.items;
            this.educationCompletedTypes = (metaItems[5] as IPersonalInformationMetaData).EducationCompletedTypes;
            this.preScreening = this.preScreeningService.getEmptyApplicationPreScreening();
            this.selectedHeightUnitId = this.preScreening.HeightUnitId;
            this.pageLoadTime = new Date();
            this.createForm();
            this.currentStatus.formGroup = this.preScreeningForm;
            this.sharedFunctionsService = new SharedFunctionsService();
            //
            // Set a dummy value for country, which forces the Province control to be displayed first - rather than the State selection drop-down.
            // This works around an odd styling issue - when the dropdown list is initially rendered with no entries, it is only half height
            // and changing the available options does not change the height - so you end up being able to not see the selected state.
            //
            // This way, the state list is populated when it is displayed - so is set to full height.
            //
            if (this.preScreening.CountryCode == null) {
                this.preScreening.CountryCode = '-';
            }

        });
    }
    ngOnDestroy(): void {
        this.preScreeningService.preScreening = this.preScreening;
    }

    //
    // Should height be entered in Meters?
    //
    usingMeters(): boolean {
        return this.selectedHeightUnitId === UnitsOfMeasurementEnums.Meters;
    }

    //
    // Create the primary form
    //
    createForm(): void {
        this.preScreeningForm = this.assignFormGroups();
        this.getControls();
        this.formCreated = true;
        if (this.isSubmitted && this.isDenied) {
            setTimeout(() => {
                this.preScreeningForm.disable();
            });
        } else {
            this.subs.add(
                this.preScreeningForm.valueChanges.pipe(debounceTime(50)).subscribe(() => {
                    if (this.preScreeningForm.dirty) {
                        this.assignFormValues(this.preScreeningForm.value.PreScreening);
                    }
                    this.checkFormStatus();
                }),
            );
        }
    }

    checkFormStatus(): void {
        if (this.preScreeningForm.valid && this.preScreening.Id > 0 && this.emailsAreEqual()) {
            const control = this.preScreeningForm.get('PreScreening.Submitted');

            if (control && control.value) {
                this.currentStatus.status = 'Submitted';
                this.preScreening.Submitted = true;
            } else {
                this.currentStatus.status = 'Complete';
                this.preScreening.Complete = true;
                this.preScreening.Submitted = false;
            }
        } else {
            this.currentStatus.status = 'In Progress';
            this.preScreening.Complete = false;
        }
    }

    //
    // Create the form group
    //
    assignFormGroups(): FormGroup {
        return this.fb.group({
            PreScreening: this.fb.group({}),
        });
    }

    //
    // Generate the controls
    //
    getControls(): void {

        this.additionalParameters = {
            formGroup: 'PreScreening',
            countries: this.countries,
            states: this.states,
            heightUnits: this.heightUnits,
            weightUnits: this.weightUnits,
            referralTypes: this.referralTypes,
            genders: this.genders,
            educationCompletedTypes: this.educationCompletedTypes,

        } as IPreScreeningDynamicControlsParameters;
        this.formFactory = new PreScreeningDynamicConfig<IPreScreening>(this.preScreening, this.configControls, this.additionalParameters);
        this.config = this.formFactory.getForUpdate();

        this.copyOfStates = this.states.map((state) => {
            return {
                CountryCode: state.CountryCode,
                Id: state.Id,
                Name: state.Name,
                StateCode: state.StateCode,
            };
        });

        this.preScreeningControls = new PreScreeningDynamicControlsPartial(this.preScreening, this.additionalParameters).Form;
    }

    //
    // Gather data from the form
    //
    assignFormValues(info: any): void {
        this.preScreening.BirthYear = info.BirthYear;
        this.preScreening.Email = info.Email;
        this.preScreening.ConfirmEmail = info.ConfirmEmail;

        if (this.usingMeters()) {
            this.preScreening.Height = Number(info.Meters);
        } else {
            this.preScreening.Height =  Number(info.Feet) * 12 + Number(info.Inches);
        }

        this.preScreening.HeightUnitId = info.HeightUnitId;
        this.selectedHeightUnitId = info.HeightUnitId;
        this.preScreening.Weight = info.Weight;
        this.preScreening.WeightUnitId = info.WeightUnitId;
        this.preScreening.GenderId = info.GenderId;
        this.preScreening.EducationCompletedTypeId = info.EducationCompletedTypeId;
        this.preScreening.IsUsCitizenOrGreenCardHolder = info.IsUsCitizenOrGreenCardHolder;
        this.preScreening.CountryCode = this.findCountryCode(info.CountryCode);

        if (!this.countryHasStatesList(this.preScreening.CountryCode)) {
            this.preScreening.Province = info.Province;
            this.preScreening.StateCode = null;
        }

        this.preScreening.HasStd = info.HasStd;
        this.preScreening.HasHiv = info.HasHiv;
        this.preScreening.HasFamilyMedicalHistory = info.HasFamilyMedicalHistory;
        this.preScreening.IsAdopted = info.IsAdopted;
        this.preScreening.HasBothOvaries = info.HasBothOvaries;
        this.preScreening.HasGivenBirth = info.HasGivenBirth;
        this.preScreening.HowManyLiveBirths = info.HowManyLiveBirths;
        this.preScreening.IsPregnantOrBreastFeeding = info.IsPregnantOrBreastFeeding;
        this.preScreening.HasPreviouslyDonatedEggs = info.HasPreviouslyDonatedEggs;
        this.preScreening.HasRegularPeriodsWithoutBirthControl = info.HasRegularPeriodsWithoutBirthControl;
        this.preScreening.ReferralTypeId = info.ReferralTypeId;
        this.preScreening.OtherReferral = info.OtherReferral;
        this.preScreening.Submitted = info.Submitted;
    }

    //
    // Submit the prescreening for validation.
    //
    validateForm(): void {
        if (this.preScreeningForm.valid) {

            this.assignFormValues(this.preScreeningForm.value.PreScreening);

            this.preScreeningService.savePreScreening(this.preScreening).subscribe((answer) => {
                this.preScreening = answer;
                this.submittedAndSaved = false;
                this.checkFormStatus();

                if (this.emailsAreEqual() && this.preScreening.Submitted) {
                    this.submittedAndSaved = true;
                }
                this.success();

                if (this.preScreening.Complete && !this.preScreening.Submitted) {
                    this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
                }

                if (this.submittedAndSaved && !this.preScreening.Denied) {
                    this.router.navigateByUrl(appPaths.register);
                }
            });
        } else {
            markAllFormFieldsAsTouched(this.preScreeningForm);
            this.notificationService.error('Please complete all fields.');
            this.applicationService.scrollToFirstInvalidControl(this.elementRef);
        }
    }

    //
    // Report that the oepration was succesful
    //
    success(): void {
        this.currentStatus.formGroup.markAsPristine();
        this.notificationService.success('Information saved successfully!');
    }

    //
    // Single point to check whether or not the country code is one that we have a defined list of states for
    //
    countryHasStatesList(value: string): boolean {
        return this.sharedFunctionsService.countryHasStatesList(value, this.states);
    }

    //
    // When the country changes, refresh the list of states based on the country selection
    //
    setStatesForCountry(value): void {
        this.filterStatesForCounty(this.findCountryCode(value));
    }

    //
    // Return the country code that matches the Id provided
    //
    findCountryCode(value): string {
        return this.sharedFunctionsService.getCountryCodeById(value, this.countries);
    }

    //
    // Return a list of country-specific states
    //
    filterStatesForCounty(countryCode: string): void {
        const stateCodeControl = this.preScreeningControls.StateCode;
        stateCodeControl.options = this.sharedFunctionsService.filterStatesForCounty(countryCode, this.copyOfStates);
    }

    saveState(value: number): void {
        const stateCode = this.sharedFunctionsService.getStateCodeById(value, this.copyOfStates);
        this.preScreening.StateCode = stateCode;
    }

    //
    // Determine if the selected referral type is "Other"
    //
    otherReferralType(): boolean {
        return this.sharedFunctionsService.otherReferralType(this.selectReferralTypeId, this.referralTypes);
    }

    //
    // Setup event handler so that when the country changes, the State Code or Province is displayed based upon whether or not there are states available for  the selected country
    //
    setShowStatesCurrentAddress(countryCodeControl: AbstractControl): void {
        this.showStatesCurrentAddress$ = countryCodeControl?.valueChanges.pipe(
            startWith(countryCodeControl.value),
            map((value) => this.countryHasStatesList(this.findCountryCode(value))),
            distinctUntilChanged(),
            shareReplay(1),
        );
    }

    //
    // When the value of the "Have you given birth?" control changes, update the "has given birth flag"
    //
    onHasGivenBirthValueChange(hasGivenBirth: number): void {
        this.hasGivenBirth = (hasGivenBirth === 1);
    }

    //
    // When the value of the "Referral Type" control changes, update the "selected referral type Id"
    //
    onReferralTypeValueChange(referralTypeId: number): void {
        this.selectReferralTypeId = referralTypeId;
    }

    //
    // When the value of the "height units" control changes, update the "current height unit Id"
    //
    onHeightUnitsValueChange(heightUnitId: number): void {
        this.selectedHeightUnitId = heightUnitId;
    }

    //
    // When the value of the "How Many Live Births" control changes, update the "live births value"
    //
    onHowManyLiveBirthsValueChanges(liveBirthsValue: number): void {
        this.preScreening.HowManyLiveBirths = liveBirthsValue;
    }

    //
    // When the births component changes, update the pre-screening entry with the arrary of children
    //
    onBirthsChanging(children: IApplicationPreScreeningChildren[]): void {
        this.preScreening.Children = children;
    }

    //
    // Expose the "form is dirty" flag
    //
    get isDirty(): boolean {
        return this.preScreeningForm.dirty;
    }

    //
    // Expose the "pre-screeing is denied" flag
    //
    get isDenied(): boolean {
        return this.preScreening && this.preScreening.Denied;
    }

    //
    // Expose the "pre-screeing is complete" flag
    //
    get isComplete(): boolean {
        return this.preScreening && this.preScreening.Complete;
    }

    //
    // Expose the "pre-screeing has been submitted" flag
    //
    get isSubmitted(): boolean {
        return this.preScreening && this.preScreening.Submitted;
    }

    //
    // Expose the "pre-screeing has been submitted AND SAVED" flag
    //
    get isSubmittedAndSaved(): boolean {
        return this.submittedAndSaved;
    }

    //
    // Indicate that the applicant has applied before
    //
    get isPreviousApplicant(): boolean {
        return this.preScreening.Denied && this.preScreening.DenialReasonId === 10;
    }

    //
    // Indicate that the Email and Confirm Email fields are the same as one another
    //
    emailsAreEqual(): boolean {

        let isValid = this.sharedFunctionsService.emailsAreEqual(this.preScreening.Email, this.preScreening.ConfirmEmail);

        if (!isValid) {
            this.preScreening.Complete = false;
            this.preScreening.Submitted = false;
            this.submittedAndSaved = false;
        }

        return isValid;
    }
}
