import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@mt-ng2/auth-module';
import breakpoints from './lib/breakpoints.lib';
import { AppStates, AppStateService } from './common/services/app-state.service';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { VerifyEmailService } from './verify-email/verify-email.service';
import { IDonor } from '@model/interfaces/donor';
import { DonorService } from './donor-account/donor.service';
import { DonorStatuses } from '@model/enums/donor-statuses.enum';
import { ApplicationService } from './donor-application/services/application.service';

declare let gtag: Function;
@Component({
    selector: 'app-root',
    styles: [``],
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    isAuthenticated: boolean;
    isMobile = false;
    mobileNavExpanded = false;
    appState: AppStates = AppStates.notLoggedIn;
    appStates = AppStates;
    subscriptions: Subscription = new Subscription();
    allowInPersonAppointment = false;
    showAppointment = false;
    Id: number;
    copyrightNotice = 'Copyright © 2024 The World Egg and Sperm Bank, All Rights Reserved.';

    constructor(
        private authService: AuthService,
        private appStateService: AppStateService,
        private applicationService: ApplicationService,
        private router: Router,
        private verifyEmailService: VerifyEmailService,
        private donorService: DonorService,
    ) { }

    ngOnInit(): void {
        this.checkIfMobile();
        forkJoin([
            this.applicationService.getAppointmentsEnabled(),
            this.verifyEmailService.verifyEmail(),
        ]).subscribe((([appointmentsEnabled, dummy]) => {
            this.showAppointment = appointmentsEnabled;
            console.log(this.showAppointment);
            this.subscriptions.add(
                this.appStateService.appState.subscribe((state) => {
                    this.appState = state;
                    if (state === AppStates.appointmentNotScheduled) {
                        this.donorService.getDonorByUserId().subscribe((donor) => {

                            this.setAllowInPersonAppointment(donor);
                         });
                    }
                }),
            );
            this.subscriptions.add(
                this.authService.isAuthenticated().subscribe((isAuthed) => {
                    this.isAuthenticated = isAuthed;
                    if (this.isAuthenticated) {
                    this.appStateService.resolveAppState();
                    } else {
                        this.appStateService.setStateToNotLoggedIn();
                    }
                }),
            );

        }));
        this.setUpAnalytics();
    }

    setAllowInPersonAppointment(donor: IDonor): void {
        this.allowInPersonAppointment = donor?.Address?.StateCode && donor.Address.StateCode.trim() === 'AZ';
    }

    setUpAnalytics(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                gtag('config', 'G-NX8FFDCQ1N',
                    {
                        page_path: event.urlAfterRedirects,
                    },
                );
            });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    // Determine if mobile
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.checkIfMobile();
    }

    checkIfMobile(): void {
        this.isMobile = window.innerWidth < breakpoints.large;
    }

    logout(): void {
        this.appStateService.setStateToNotLoggedIn();
        setTimeout(() => this.authService.logout());
    }
}
