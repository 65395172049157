import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginOverrideComponent } from './login-override.component';
import { SharedModule } from '../common/shared.module';
import { ResetBannerComponent } from './reset-password-banner.component';

@NgModule({
    declarations: [LoginOverrideComponent, ResetBannerComponent],
    exports: [LoginOverrideComponent],
    imports: [BrowserAnimationsModule, FormsModule, ReactiveFormsModule, RouterModule, SharedModule],
})
export class DonorLoginModule {
    static forRoot(): any {
        return {
            ngModule: DonorLoginModule,
            providers: [],
        };
    }
}
