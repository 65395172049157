import { Injectable, Inject, Optional } from '@angular/core';

import { IEntityComponentsDocumentsModuleConfig, EntityComponentsDocumentsModuleConfigToken } from '@mt-ng2/entity-components-documents-config';

const defaultModuleConfig: IEntityComponentsDocumentsModuleConfig = {
    max: 3,
};

@Injectable({
    providedIn: 'root',
})
export class EntityComponentsDocumentsModuleConfig implements IEntityComponentsDocumentsModuleConfig {
    max: number;
    disableSort: boolean;

    constructor(@Inject(EntityComponentsDocumentsModuleConfigToken) @Optional() config: IEntityComponentsDocumentsModuleConfig) {
        Object.assign(this, defaultModuleConfig, config);
    }
}
