import { InjectionToken } from '@angular/core';

export interface INavModuleConfig {
    /**
     * @optional
     * @property {string} override the url to send the user to when they click on the help icon
     */
    submitHelpUrl?: string;
    /**
     * @optional
     * @property {boolean} if true, just navigate to the url in submitHelpUrl instead of
     */
    isCustomSubmitHelpPage?: boolean;
    /**
     * @optional
     * @property {boolean} whether or not to open a new tab for submit help.  Ignored if isCustomSubmitHelpPage is false
     */
    shouldShowSubmitHelpInNewTab?: boolean;
    /**
     * @optional
     * @property {string} override the url for the nav bar logo
     */
    logoUrl?: string;
    /**
     * @optional
     * @property {string} override the url for the full logo
     */
    fullLogoUrl?: string;
    /**
     * @optional
     * @property {string} override the route to take when clicking on 'Edit my profile'
     */
    myProfilePath?: string;
    /**
     * @optional
     * @property {string} override the text in the footer component
     */
    footerHtml?: string;
    /**
     * @optional
     * @property {boolean} show the submit help button in the header
     */
    showSubmitHelpButton?: boolean;
    /**
     * @optional
     * @property {boolean} show the new tab button in the header
     */
    showNewTabButton?: boolean;
    /**
     * @optional
     * @property {boolean} is the keyboard shortcuts module installed
     */
    hasKeyboardShortcutsModule?: boolean;
    /**
     * @optional
     * @property {boolean} show the keyboard shortcut help button in the header
     */
    showKeyboardShortcutsButton?: boolean;
    /**
     * @optional
     * @property {string} the name of the site which will be used for indicating where
     * links came from (i.e. the footer link to miles site)
     */
    siteName?: string;
}

/**
 * Token used for providing the INavModuleConfig
 * @example
 * // in the app providers section
 * providers: [
 *     { provide: NavModuleConfigToken, useValue: navModuleConfig },
 * ],
 */
export const NavModuleConfigToken = new InjectionToken<INavModuleConfig>('nav_module_config');
