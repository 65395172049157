<div [formGroup]="childrenForm">
    <div formArray="PreScreeningChildrenArray" *ngIf="formCreated">
        <ng-container *ngFor="let item of PreScreeningChildrenFormGroup; let i = index" [formGroup]="item">
            <div style="padding: 10px; border-width: thin; border-style: solid">
                <h3>Child Details - {{ i + 1 }}</h3>
                <div class="input-scaffold">
                    <dynamic-field [field]="item.value.YearOfChildBirth" (valueChanges)="onChildYearChange($event, i)"></dynamic-field>
                </div>
                <br />
                <div class="input-scaffold">
                    <dynamic-field [field]="item.value.ChildGender"
                        (valueChanges)="onChildGenderValueChange($event, i)"></dynamic-field>
                </div>
            </div>
            <br />
        </ng-container>
    </div>
</div>