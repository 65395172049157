import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { YesNo } from '@model/enums/yes-no.enum';
import { IApplication } from '@model/interfaces/application';
import { IDonor } from '@model/interfaces/donor';
import { ApplicationDynamicControlsPartial } from '@model/partials/application-partial.form-controls';
import { AuthService } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { DynamicField } from '@mt-ng2/dynamic-form';
import { IModalOptions } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FileItem } from 'ng2-file-upload';
import { finalize } from 'rxjs/operators';
import { AppStateService } from '../../../common/services/app-state.service';
import { ApplicationService } from '../../services/application.service';
import { DeniedModalService } from '../../../../donor-portal/common/modals/denied-modal.service';
import { forkJoin, Subject } from 'rxjs';
import { DonorService } from '../../../donor-account/donor.service';

@Component({
    selector: 'application-submit',
    templateUrl: './application-submit.component.html',
})
export class ApplicationSubmitComponent implements OnInit {
    formCreated = false;
    waiverControl: DynamicField;
    socialSecurityControl: DynamicField;
    submissionForm: FormGroup;
    doubleClickDisabled = false;
    showPassed = false;
    showAppointment = false;
    showReviewModal = false;
    showFailModal = false;
    photosForUpload: FileItem[] = [];
    modalOptions: IModalOptions = {
        customClass: {
            title: 'text-center',
        },
        showCloseButton: false,
        showConfirmButton: false,
        width: '45%',
    };

    submitting = false;
    showWarning = false;
    IdsForUpload: FileItem[] = [];
    allowInPersonAppointment = false;
    applicationWhoAreYouId = 0;
    applicationIdSubject = new Subject<number>();
    userId = 0;
    donorId = 0;
    denied = false;

    constructor(
        private formBuilder: FormBuilder,
        private appStateService: AppStateService,
        private authService: AuthService,
        private applicationService: ApplicationService,
        private notificationService: NotificationsService,
        private deniedModalService: DeniedModalService,
    ) { }

    ngOnInit(): void {
        this.submissionForm = this.formBuilder.group({});
        this.waiverControl = new ApplicationDynamicControlsPartial().Form.HasAcknowledgedWaiver;
        this.socialSecurityControl = new ApplicationDynamicControlsPartial().Form.socialSecurityNumber;
        this.formCreated = true;
        forkJoin([
            this.applicationService.getPendingApplication(),
            this.applicationService.getAppointmentsEnabled(),
        ])
        .subscribe(([application, appointmentsEnabled]) => {
            this.applicationWhoAreYouId = application?.ApplicationWhoAreYouId;
            this.applicationIdSubject.next(this.applicationWhoAreYouId);
            this.userId = application.UserId;
            this.donorId = application.DonorId;
            this.denied = application.DenialReasonId > 0;
            this.setAllowInPerson(application);
            this.showAppointment = appointmentsEnabled;
        });
    }

    setAllowInPerson(application: IApplication): void {
        this.allowInPersonAppointment = application.ApplicationWhoAreYou?.StateCode && application.ApplicationWhoAreYou.StateCode.trim() === 'AZ';
    }

    setPhotosForUpload(photos: FileItem[]): void {
        this.photosForUpload = [...photos];
    }

    setIdForUpload(photos: FileItem[]): void {
        this.IdsForUpload = [...photos];
    }

    logout(): void {
        this.appStateService.setStateToNotLoggedIn();
        setTimeout(() => this.authService.logout());
    }

    get readyToSubmit(): boolean {
        return (
            this.photosForUpload.length >= 4 &&
            this.IdsForUpload?.length >= 1 &&
            this.submissionForm.get('HasAcknowledgedWaiver') &&
            this.submissionForm.get('HasAcknowledgedWaiver').value === YesNo.Yes &&
            this.submissionForm.get('socialSecurityNumber') &&
            this.submissionForm.get('socialSecurityNumber').valid
        );
    }

    onWaiverValueChange(value: number): void {
        this.showWarning = value === YesNo.No;
    }

    submit(): void {
        const partialApplication = {
            ApplicationWhoAreYouId: this.applicationWhoAreYouId,
            HasAcknowledgedWaiver: this.submissionForm.get('HasAcknowledgedWaiver').value === 1,
            UserId: this.userId,
            DonorId: this.donorId,
        } as IApplication;
        const photos = this.photosForUpload.map((p) => p._file);
        const ids = this.IdsForUpload.map((i) => i._file);
        const ssn = this.submissionForm.get('socialSecurityNumber').value;
        this.submitting = true;
        this.applicationService
            .submitApplicationPart1(partialApplication, ssn)
            .pipe(finalize(() => (this.doubleClickDisabled = false)))
            .subscribe(
                (resp) => {
                    let ok = this.applicationService.submitApplicationPart2(this.userId, resp.Id, resp.DonorId, photos, ids);

                    if (ok) {

                        this.setUserToIsDonor(resp);

                        if (resp.DonorScore <= 149) {
                            this.showPassed = true;
                        } else if (resp.DonorScore >= 251) {
                            this.showFailModal = true;
                        } else {
                            this.showReviewModal = true;
                        }

                    }
                },
                (err) => {
                    this.submitting = false;
                    markAllFormFieldsAsTouched(this.submissionForm);
                    if (err?.error?.Denied) {
                        this.deniedModalService.showModal(err?.DenialId);
                        this.denied = true;
                    }
                    this.notificationService.error('There was an error submitting please contact The World Egg Bank\n' + err.message);
                },
            );
    }

    setUserToIsDonor(donor: IDonor): void {
        const user = this.authService.currentUser.getValue();
        const customOptions = Object.assign({}, user.CustomOptions, {
            DonorScore: donor.DonorScore,
            IsDonor: true,
            MedicalReviewId: donor.MedicalReviewId,
        });
        this.authService.updateCurrentUser({ CustomOptions: customOptions });
    }
}
