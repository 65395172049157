<!-- <div class="main-layout__wrapper">
    <div class="main-layout__children">
        <div class='container'>
            <div class="miles-form padded row application-section">
                <div class="auth-layout__right">
                    <div class="auth-layout__right__children">
                        <div class="auth-layout__form-container">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->


<div class="auth-layout">
    <div class="auth-layout__left">
        <img src="../assets/logo-white.png" height="79" width="100" alt="tweb logo" />
    </div>
    <div class="auth-layout__right">
        <div class="auth-layout__right__children">
            <div class="auth-layout__form-container">
                <h2 class="auth-layout__h2">Thank You!</h2>
                <br />
                <p>
                    Thank you for your submitting your information. A donor coordinator will review your
                    answers and
                    be in touch.
                </p>
                <br />
                <p>
                    The World Egg and Sperm Bank Donor Team
                </p>
                <br />
            </div>
        </div>
        <div class="footer">
            <span>{{ copyrightNotice}} </span>
        </div>
    </div>
</div>