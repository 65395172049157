<div>
    <div class="row">
        <div class="donor-portal-tabs col-md-8 col-md-offset-2">
            <ngx-skeleton-loader count="25" *ngIf="!application"></ngx-skeleton-loader>
            <div *ngIf="application">
                <ngb-tabset [activeId]="getInitialTab()" #formTabs type="pills">
                    <ngb-tab id="tab-preScreening" [disabled]="section1Finalized"
                        [title]="section1Finalized ? 'Pre-screening (complete)' : 'Pre-screening'">
                        <ng-template ngbTabContent>
                            <app-pre-screening *ngIf="application && application.ApplicationPreScreening"
                                [preScreening]="application.ApplicationPreScreening" [donor]="donor" [hideTrim]="true"
                                [denied]="denied" [isPreScreeningComplete]="isPreScreeningComplete"
                                (validateForm)="validateForm()"></app-pre-screening>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="tab-medicalIssues" [disabled]="!section1Finalized || section2Finalized"
                        [title]="section2Finalized ? 'Medical History (complete)' : 'Medical History'">
                        <ng-template ngbTabContent>
                            <medical-issues *ngIf="application && application.ApplicationIssue"
                                [medicalIssues]="application.ApplicationIssue" [donor]="donor" [denied]="denied"
                                [isMedicalHistoryComplete]="isMedicalHistoryComplete"
                                (validateForm)="validateForm()"></medical-issues>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="tab-familyHistory"
                        [disabled]="!section1Finalized || !section2Finalized || section3Finalized"
                        [title]="section3Finalized ? 'Family History (complete)' : 'Family History'">
                        <ng-template ngbTabContent>
                            <family-history *ngIf="application && application.ApplicationFamilyHistory" [donor]="donor"
                                [denied]="denied" [isFamilyHistoryComplete]="isFamilyHistoryComplete"
                                (validateForm)="validateForm()"
                                [familyHistory]="application.ApplicationFamilyHistory"></family-history>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab id="tab-whoAreYou"
                        [disabled]="!section1Finalized || !section2Finalized || !section3Finalized || section4Finalized"
                        [title]="section4Finalized ? 'Who Are You (complete)' : 'Who Are You'">
                        <ng-template ngbTabContent>
                            <who-are-you *ngIf="application && application.ApplicationWhoAreYou" [donor]="donor"
                                [denied]="denied" [isWhoAreYouComplete]="isWhoAreYouComplete"
                                (validateForm)="validateForm()" [whoAreYou]="application.ApplicationWhoAreYou"
                                [preScreening]="application.ApplicationPreScreening"
                                (onUserChanging)="UserChanged($event)"></who-are-you>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
                <div class="top-wrapper"></div>
                <div class="status-tracker-indicator" *ngIf="currentSection && application && application.Id > 0">
                    <status-indicator [application]="application" [currentSection]="currentSection"
                        (nextTab)="selectTabs(currentSection.nextTab)" (validateForm)="validateForm()">
                    </status-indicator>
                </div>
            </div>
        </div>
    </div>
</div>