import { Component, Input, OnInit, Inject } from '@angular/core';
import { UserRoleRecommendedPageService } from '../../recommended-pages/user-role-recommended-page.service';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUserRoleRecommendedPage } from '../../model/user-role-recommended-page';
import { IDashboardModuleConfig } from '../../model/dashboard-module-config';

@Component({
    selector: 'app-user-role-recommended-page-list',
    styles: [
        `
            .miles-card {
                margin-top: 20px;
            }
            .btn-primary {
                display: block;
                margin: 0 auto;
            }
        `,
    ],
    templateUrl: './user-role-recommended-page-list.component.html',
})
export class UserRoleRecommendedPageListComponent implements OnInit {
    @Input() userRoleId: number;
    recommendedPages: IUserRoleRecommendedPage[];
    canAddRecommendedPage: boolean;

    constructor(
        private userRoleRecommendedPageService: UserRoleRecommendedPageService,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        @Inject('recommended_pages_module_config')
        private recommendedPagesModuleConfig: IDashboardModuleConfig,
    ) {}

    ngOnInit(): void {
        this.canAddRecommendedPage = this.claimsService.hasClaim(
            this.recommendedPagesModuleConfig.userRolesClaimTypeId,
            [ClaimValues.FullAccess],
        );
        this.userRoleRecommendedPageService.getPagesByUserRole(this.userRoleId).subscribe((answer) => {
            this.recommendedPages = answer;
        });
    }

    addNewPage(): void {
        if (this.recommendedPages.length < 5) {
            let newPage = this.userRoleRecommendedPageService.getEmptyUserRoleRecommendedPage();
            this.recommendedPages.push(newPage);
        } else {
            this.notificationsService.error('Cannot add page due to page limit. Please remove a page first.');
        }
    }

    removePage(idToRemove: number): void {
        let removalIndex = this.recommendedPages.indexOf(
            this.recommendedPages.find((page) => {
                return page.Id === idToRemove;
            }),
        );
        this.recommendedPages.splice(removalIndex, 1);
    }

    cancelPageAdd(pageAdded: boolean): void {
        this.recommendedPages.pop();
    }
}
