import { Injector } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { LoginModuleOverrideAuthServiceToken } from '@mt-ng2/login-module-config';

export const authServiceFactory = (injector: Injector) => {
    return injector.get(LoginModuleOverrideAuthServiceToken, AuthService);
};

export const authServiceProvider = {
    deps: [Injector],
    provide: AuthService,
    useFactory: authServiceFactory,
};
