import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { BaseService } from '@mt-ng2/base-service';

import { IDonor } from '@model/interfaces/donor';
import { Observable } from 'rxjs';
import { IDonorBasicInfo } from '@model/interfaces/donor-basic-info';
import { IAddress } from '@model/interfaces/address';
import { IUser } from '@model/interfaces/user';
import { IAddressResponse } from '@model/interfaces/custom/address-response';
import { IDonorReview } from '@model/interfaces/donor-review';

export const emptyDonor: IDonor = {
    Active: false,
    ApplicationId: 0,
    Approved: false,
    Archived: false,
    AvailableInAU: false,
    AvailableInUK: false,
    AvailableInCA: false,
    AvailableInOther: false,
    BabyBundle: false,
    DateCreated: null,
    DonorBasicInfoId: 0,
    DonorId: 0,
    DonorIssueId: 0,
    DonorPreliminaryHistoryId: 0,
    DonorStatusId: 0,
    DonorWhoAreYouId: 0,
    FamilyBundle: false,
    Id: 0,
    PremiumBankedEggs: false,
    BankedCustomChoice : false,
    Published: false,
    UserId: 0,
    OtherReviewsSummary: '',
};

@Injectable({
    providedIn: 'root',
})
export class DonorService extends BaseService<IDonor> {
    constructor(public http: HttpClient) {
        super('/donor-portal/donors', http);
    }

    getEmptyDonor(): IDonor {
        return { ...emptyDonor };
    }

    getDonorByUserId(): Observable<IDonor> {
        return this.http.get<IDonor>('/donor-portal/donors/get-donor-profile');
    }

    saveAddress(donorId: number, address: IAddress): Observable<IAddressResponse> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<IAddressResponse>(`/donor-portal/donors/address`, address);
        } else {
            return this.http.put<IAddressResponse>(`/donor-portal/donors/address/${donorId}`, address);
        }
    }

    deleteAddress(): Observable<object> {
        return this.http.delete('/donor-portal/donors/address', {
            responseType: 'text' as 'json',
        });
    }

    savePhone(donorBasicInfo: IDonorBasicInfo): any {
        return this.http.put<number>(`/donor-portal/donors/phone`, donorBasicInfo);
    }

    downloadFactsSheet(): Observable<Blob> {
        return this.http.get('/donor-portal/donors/fact-sheet', { responseType: 'blob' as 'blob' });
    }

    updateUser(donorId: number, user: IUser): any {
        return this.http.put<string>(`/donor-portal/donors/${donorId}/user`, user);
    }

    ///
    /// Calls the API to update the donor status in the database
    ///
    setDonorStatusId(donorId: number, donorStatusId: number): Observable<number> {
        return this.http.put<number>(`/donor-portal/donors/${donorId}/setStatus`, donorStatusId);
    }

    sendVerifyEmail(userId: number): Observable<void> {
        return this.http.get<void>(`/donor-portal/donors/send-verify-email/${userId}`);
    }
}
