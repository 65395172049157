import { Component, OnInit } from '@angular/core';

import { IPageVisit } from '../../model/page-visit';
import { IUserRoleRecommendedPage } from '../../model/user-role-recommended-page';
import { UserRoleRecommendedPageService } from '../../recommended-pages/user-role-recommended-page.service';
import { RecommendedPageService } from '../../recommended-pages/recommended-page.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
    recommendedRoutes: IPageVisit[];
    userRoleRecommendedRoutes: IUserRoleRecommendedPage[];
    recommendedForUserLabel = 'Recommended Pages';
    recommendedForUserRoleLabel = 'User Role Pages';

    constructor(
        private userRoleRecommendedPageService: UserRoleRecommendedPageService,
        private recommendedPageService: RecommendedPageService,
    ) {}

    ngOnInit(): void {
        this.getRecommendedPages();
        this.getUserRoleRecommendedPages();
    }

    getRecommendedPages(): void {
        this.recommendedPageService.getAll().subscribe((answer) => {
            this.recommendedRoutes = answer;
        });
    }

    getUserRoleRecommendedPages(): void {
        this.userRoleRecommendedPageService.getAll().subscribe((answer) => {
            this.userRoleRecommendedRoutes = answer;
        });
    }
}
