import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { IGender } from '@model/interfaces/gender';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { IApplicationPreScreeningChildren } from '../../../../admin-portal/model/interfaces/application-pre-screening-children';
import { IMetaItem } from '@mt-ng2/base-service/meta-item';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IPreScreening } from '../../../../admin-portal/model/interfaces/pre-screening';

@Component({
    selector: 'app-births',
    templateUrl: './births-component.html',
})
export class BirthsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() preScreening: IPreScreening;
    @Input() numberOfLiveBirths: number;
    @Input() genders: IGender[];
    @Output() onBirthsChanging: EventEmitter<IApplicationPreScreeningChildren[]> = new EventEmitter<IApplicationPreScreeningChildren[]>();

    childrenForm: FormGroup;
    doubleClickIsDisabled = false;
    pageLoadTime: Date;
    formCreated = false;
    subs = new Subscription();

    lastTwentyYearsArray: IMetaItem[] = this.getLastTwentyYearsArray();

    constructor(
        private fb: FormBuilder,
    ) { }

    //
    // Initialize the component
    //
    ngOnInit(): void {

        //
        // Initialize the pre-screening entry's Children array, if required.
        //
        if (!this.preScreening.Children) {
            this.preScreening.Children = [];
        }

        //
        // If there are more Children than the # of live births, then remove the extra ones
        //
        if (this.preScreening.Children.length > this.numberOfLiveBirths) {
            for (let i = this.numberOfLiveBirths; i < this.preScreening.Children.length; i++) {
                delete this.preScreening.Children[i];
            }
            return;
        }

        //
        // If there are fewer Children than the # of live births, then add some more
        //
        if (this.preScreening.Children.length < this.numberOfLiveBirths) {
            for (let i = this.preScreening.Children.length; i < this.numberOfLiveBirths; i++) {
                this.preScreening.Children.push(this.getEmptyPreScreeningChildren(i));
            }
        }

        this.createForm();
    }

    //
    // Create the form group arrary for birth entries
    //
    assignFormGroups(): FormGroup {
        return this.fb.group({
            PreScreeningChildrenArray: this.fb.array([]),
        });
    }

    //
    // Create a new form
    //
    createForm(): void {
        this.childrenForm = this.assignFormGroups();
        this.initializePreScreeningChildren();
        this.formCreated = true;
        this.subs.add(
            this.childrenForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
                if (this.childrenForm.dirty) {
                    this.getPreScreeningChildren();
                    this.onBirthsChanging.emit(this.preScreening.Children);
                }
            }),
        );
    }

    //
    // Destructor
    //
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    //
    // Respond to external changes - specifically changes to the number of live births
    //
    ngOnChanges(changes: SimpleChanges): void {
        //
        // If we have been notified about the # of live births changing, then update the controls arrary
        // accordingly.
        //
        if (changes && changes.numberOfLiveBirths && changes.numberOfLiveBirths.currentValue) {

            //
            // Get the current and new value of # of live births
            //
            let numberOfLiveBirths = changes.numberOfLiveBirths.currentValue;
            this.numberOfLiveBirths = numberOfLiveBirths;

            if (this.formCreated) {
                let numberOfPreScreeningChildControls = this.getPreScreeningChildControlLength();

                //
                // If we started with none or now have none, reset the list of entries and array of controls
                //
                if (numberOfPreScreeningChildControls === 0 || numberOfLiveBirths === 0) {
                    this.clearPreScreeningChildControls();
                    this.preScreening.Children = [];
                }

                //
                // If we now have fewer live births than controls, then remove the most recent entries
                // and controls.
                //
                if (numberOfLiveBirths < numberOfPreScreeningChildControls) {
                    for (let index = numberOfLiveBirths; index < numberOfPreScreeningChildControls; index++) {
                        this.clearPreScreeningChildControlPop();
                        delete this.preScreening.Children[index];
                    }
                    return;
                }

                //
                // If we now have more live births than controls, then we need to add additional entries
                // and controls.
                //
                if (numberOfLiveBirths > numberOfPreScreeningChildControls) {

                    for (let index = numberOfPreScreeningChildControls; index < numberOfLiveBirths; index++) {
                        this.addPreScreeningChildControl();
                        this.preScreening.Children[index] = this.getEmptyPreScreeningChildren(index);
                    }
                }

            }
        }
    }

    //
    // Update the current address from the form group.  Tried to use the  Object.assign() function, but it was adding a property
    // to this.address called Address - with everything inside that, instead of the individual property values.
    //
    getPreScreeningChildren(): void {

        for (let i = 0; i < this.PreScreeningChildrenArray.controls.length; i++) {
            this.preScreening.Children[i] = this.getChildDetail(i);
        }
    }

    //
    // Output the modified birth information
    //
    reportChanges(): void {
        this.getPreScreeningChildren();
        this.onBirthsChanging.emit(this.preScreening.Children);
    }

    //
    // Create the controls for editing the list of pre screening children
    //
    initializePreScreeningChildren(): void {
        if (this.preScreening.Children && this.preScreening.Children.length) {
            for (let index = 0; index < this.preScreening.Children.length; index++) {
                const childDetails = this.preScreening.Children[index];
                let sexOptionsId = null;
                let yearOfBirthOptionId = null;

                if (childDetails.Sex) {
                    sexOptionsId = this.genders.find((s) => s.Id === childDetails.Sex).Id;
                }

                if (childDetails.YearOfBirth) {
                    yearOfBirthOptionId = this.lastTwentyYearsArray.find((y) => y.Name === childDetails.YearOfBirth.toString()).Id;
                }

                this.addPreScreeningChildControl(yearOfBirthOptionId, sexOptionsId);
            }
        }
    }

    //
    // Expose the pre-screening form array to the HTML component
    //
    get PreScreeningChildrenArray(): FormArray {
        let formArray = this.childrenForm.get('PreScreeningChildrenArray') as FormArray;
        return formArray;
    }

    //
    // Expose the pre-screening form groups to the HTML component
    //
    get PreScreeningChildrenFormGroup(): FormGroup[] {
        let group = [] as FormGroup[];

        for (const childFormGroup of this.PreScreeningChildrenArray.controls) {
            group.push(childFormGroup as FormGroup);
        }
        return group;
    }

    //
    // Check the length of the controls array
    //
    getPreScreeningChildControlLength(): number {
        if (!this.PreScreeningChildrenArray) {
            this.clearPreScreeningChildControls();
        }

        return this.PreScreeningChildrenArray.controls.length;
    }

    //
    // Clear the controls array
    //
    clearPreScreeningChildControls(): void {
        this.PreScreeningChildrenArray.controls = [];
    }

    //
    // Remove the most recent birth from the controls array
    //
    clearPreScreeningChildControlPop(): void {
        this.PreScreeningChildrenArray.controls.pop();
    }

    //
    // Add a new birth to the controls array
    //
    addPreScreeningChildControl(yearOfBirthOptionId = 0, sexOptionsId = null): void {
        this.PreScreeningChildrenArray.push(
            this.getPreScreeningChildrenFormGroup(yearOfBirthOptionId, sexOptionsId),
        );
    }

    //
    // Generate controls for a birth
    //
    getPreScreeningChildrenFormGroup(yearOfBirthOptionId: number, sexOptionsId: number): FormGroup {
        return this.fb.group({
            ChildGender: new DynamicField({
                formGroup: 'PreScreeningChildrenArray',
                label: 'Sex',
                name: 'ChildGender',
                options: this.genders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: sexOptionsId !== undefined && sexOptionsId !== null ? sexOptionsId : null,
            }),
            YearOfChildBirth: new DynamicField({
                formGroup: 'PreScreeningChildrenArray',
                label: 'Year Of Birth',
                name: 'YearOfChildBirth',
                options: this.getLastTwentyYearsArray(),
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: yearOfBirthOptionId !== undefined && yearOfBirthOptionId !== null ? yearOfBirthOptionId : null,
            }),
        });
    }

    //
    // Return an entry entry for a child
    //
    getEmptyPreScreeningChildren(index: number): IApplicationPreScreeningChildren {
        return {
            Id: 0,
            ApplicationPreScreeningId: this.preScreening.Id,
            ChildId: index,
            DateCreated: new Date(),
            Sex: null,
            YearOfBirth: null,
        };
    }

    //
    // When a child's age changes, update the page and report the change
    //
    onChildYearChange(yearId: number, index: number): void {
        this.childrenForm.markAsDirty();
        let childDetail = this.getChildDetail(index);
        let year = parseInt(this.lastTwentyYearsArray.filter((i) => i.Id === yearId)[0].Name, 10);
        childDetail.YearOfBirth = year;

        this.reportChanges();
    }

    //
    // When a child's gender changes, update the page and report the change
    //
    onChildGenderValueChange(value: number, index: number): void {
        this.childrenForm.markAsDirty();
        let childDetail = this.getChildDetail(index);
        childDetail.Sex = this.genders.filter((s) => s.Id === value)[0].Id;
        this.reportChanges();
    }

    //
    // Setup the controls array with empty entries
    //
    setChildrenDetail(): void {
        if (this.numberOfLiveBirths) {
            for (let index = 0; index < this.numberOfLiveBirths; index++) {
                this.preScreening.Children[index] = this.getEmptyPreScreeningChildren(index);
            }
        }
    }

    //
    // Get the details of a specific child from the controls
    //
    getChildDetail(index: number): IApplicationPreScreeningChildren {
        return this.preScreening.Children[index];
    }

    //
    // Get a list of potential birth years - but limited to the last 20 years
    //
    getLastTwentyYearsArray(): IMetaItem[] {
        let currentYear = new Date().getFullYear();
        let result: IMetaItem[] = [];
        for (let index = 0; index <= 20; index++) {
            result.push({ Id: index, Name: (currentYear - index).toString() });
        }
        return result;
    }
}
