import { Component, Input, OnInit } from '@angular/core';
import HelloSign from 'hellosign-embedded';

import { IDonor } from '@model/interfaces/donor';
import { SignService } from './hello-sign.service';
import { forkJoin } from 'rxjs';
import { DocumentTemplateService } from '@common/services/document-template.service';
import { IDonorHelloSignDocument } from '@model/interfaces/donor-hello-sign-document';
import { IModalOptions } from '@mt-ng2/modal-module';
import { environment } from '../../environments/environment';
import { AuthService } from '@mt-ng2/auth-module';

@Component({
    selector: 'app-donor-documents',
    styleUrls: ['donor-document.component.less'],
    templateUrl: './donor-document.component.html',
})
export class DonorDocumentComponent implements OnInit {
    @Input() donor: IDonor;
    clientId: string;
    templates: IDonorHelloSignDocument[];
    isVerified = false;

    pendingDocuments = false;
    modalOptions: IModalOptions = {
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-flat btn-primary',
        confirmButtonText: 'Close',
        showConfirmButton: true,
    };

    constructor(private signService: SignService, private documentTemplateService: DocumentTemplateService, private authService: AuthService) {}

    ngOnInit(): void {
        forkJoin([this.signService.getHelloSignClientId(), this.signService.getDonorDocuments(this.donor.Id)]).subscribe(([clientId, templates]) => {
            this.clientId = clientId;
            this.templates = templates;
            this.authService.currentUser.subscribe((user) => {
                this.isVerified = user.CustomOptions.EmailVerified;
                this.alertDocuments();
            });
        });
    }

    alertDocuments(): void {
        const todo = this.templates.filter((t) => !t.Completed).length;
        if (todo > 0) {
            this.pendingDocuments = true;
        }
    }

    getDocument(templateId: number): void {
        const client = new HelloSign();

        this.signService.getDocumentToSign(this.donor.Id, templateId).subscribe((resp) => {
            client.open(resp.SignUrl, {
                clientId: this.clientId,
                testMode: environment.helloSignTestMode,
            });

            client.on('sign', () => {
                this.formCompleted(templateId);
            });
        });
    }

    formCompleted(templateId: number): void {
        this.signService.markDocumentAsSigned(this.donor.Id, templateId).subscribe(() => {
            this.signService.getDonorDocuments(this.donor.Id).subscribe((templates) => {
                this.templates = templates;
            });
        });
    }
}
