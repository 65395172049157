import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { EnvironmentService } from '@mt-ng2/environment-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService } from '@mt-ng2/auth-module';

import { finalize } from 'rxjs/operators';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { LoginConfig } from '../../libraries/login-config.library';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm: FormGroup;
    logoFull: string;
    doubleClickIsDisabled = false;
    userId: number;
    resetKey: string;

    protected authService: AuthService;
    protected router: Router;
    public route: ActivatedRoute;
    protected notificationsService: NotificationsService;
    protected environmentService: EnvironmentService;
    public config: LoginConfig;
    protected pageTitlesService: PageTitlesService;

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
        this.notificationsService = injector.get(NotificationsService);
        this.environmentService = injector.get(EnvironmentService);
        this.config = injector.get(LoginConfig);
        this.pageTitlesService = injector.get(PageTitlesService);
        this.logoFull = `${this.environmentService.config.imgPath}logo-full.png`;
    }

    ngOnInit(): void {
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the login page.  Because you aren't authed!
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        this.createForm();
        const queryParams = this.route.snapshot.queryParams;
        this.resetKey = queryParams.resetKey;
        this.userId = +queryParams.userId;
        this.setPageTitle();
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Reset Password');
    }

    createForm(): void {
        if (!this.resetPasswordForm) {
            this.resetPasswordForm = new FormGroup({});
        }
        this.resetPasswordForm.addControl('Password', new FormControl('', [Validators.required, Validators.pattern(this.config.passwordPattern)]));
        this.resetPasswordForm.addControl(
            'ConfirmPassword',
            new FormControl('', [Validators.required, Validators.pattern(this.config.passwordPattern)]),
        );
    }

    passwordHasError(): boolean {
        const control = this.resetPasswordForm.get('Password');
        return control.errors && (control.touched || control.errors.maxlength);
    }

    showPasswordRequiredError(): boolean {
        const control = this.resetPasswordForm.get('Password');
        return control.touched && control.hasError('required');
    }

    showConfirmPasswordRequiredError(): boolean {
        const control = this.resetPasswordForm.get('ConfirmPassword');
        return control.touched && control.hasError('required');
    }

    showPasswordMustMatchError(): boolean {
        const control = this.resetPasswordForm.get('Password');
        const controlValue = control.value as string;
        const confirmControl = this.resetPasswordForm.get('ConfirmPassword');
        const confirmControlValue = confirmControl.value as string;
        return control.touched && confirmControl.touched && controlValue.length && confirmControlValue.length && controlValue !== confirmControlValue;
    }

    hasRegexError(): boolean {
        let answer = false;
        let passwordControl = this.resetPasswordForm.controls.Password;
        if (passwordControl.errors?.pattern) {
            answer = true;
            passwordControl.setErrors({ pattern: true });
        }
        return answer;
    }

    onSubmit(): void {
        const form = this.resetPasswordForm;
        if (this.authService.matchPassword(form, null)) {
            if (form.valid) {
                this.authService
                    .resetPassword(this.userId, form.value.Password, form.value.ConfirmPassword, this.resetKey)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe(
                        (answer) => {
                            this.success();
                            this.router.navigate(['/home']);
                        },
                        (error) => {
                            if (error.status === 418) {
                                this.notificationsService.error(LoginConfig.signInFailure);
                            } else if (error.status === 400) {
                                // TODO: Can we just inject the error service?
                                if (error.error) {
                                    this.error(error.error.ModelState.Service);
                                } else {
                                    this.error('Something went wrong, Please generate a new reset email');
                                    console.error(`Oops something went wrong!
                                - Probably the key has been used or expired.
                                - Or you did something you weren't supposed to do.
                                - Your best bet is to just generate a new email.`);
                                }
                            }
                        },
                    );
            } else {
                markAllFormFieldsAsTouched(form);
                this.enableDoubleClick();
            }
        } else {
            this.error('Passwords do not match');
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Save Failed');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Password Updated Successfully');
    }
}
