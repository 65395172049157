import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDocumentTemplate } from '@model/interfaces/document-template';
import { StaticMetaItemService } from '@mt-ng2/base-service';

@Injectable({
    providedIn: 'root',
})
export class DocumentTemplateService extends StaticMetaItemService<IDocumentTemplate> {
    constructor(public http: HttpClient) {
        super('DocumentTemplateService', 'Template', 'TemplateIds', '/options/documentTemplates', http);
    }
}
