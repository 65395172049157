import { Component, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FileItem, FileUploader } from 'ng2-file-upload';
@Component({
    selector: 'photo-uploader',
    styles: [''],
    templateUrl: './photo-uploader.component.html',
})
export class PhotoUploaderComponent {
    @Output('onQueueChange') onQueueChange: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
    allowedMimeType: string[] = ['image/jpg', 'image/jpeg'];
    maxFileSize = 100 * 1024 * 1024;
    isHTML5 = true;
    uploader: FileUploader;
    localImageUrl: string;
    thumbnails: { file: FileItem; path: SafeUrl; ready: boolean }[] = [];

    constructor(public sanitizer: DomSanitizer, public notificationService: NotificationsService) {}

    ngOnInit(): void {
        this.uploader = new FileUploader({
            allowedMimeType: this.allowedMimeType,
            isHTML5: this.isHTML5,
            maxFileSize: this.maxFileSize,
        });
        this.uploader.onAfterAddingFile = (file) => {
            const image_file = file._file;
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.thumbnails.push({ file: file, path: this.sanitizer.bypassSecurityTrustUrl(reader.result.toString()), ready: true });
            });
            reader.readAsDataURL(image_file);
            this.onQueueChange.emit(this.uploader.queue);
        };

        this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {
            this.notificationService.error('File must be jpg or jpeg format.');
        };
    }

    removeFile(index: number): void {
        this.thumbnails = this.thumbnails.filter((f, i) => i !== index);
        this.uploader.queue = this.uploader.queue.filter((f, i) => i !== index);
        this.onQueueChange.emit(this.uploader.queue);
    }
}
