import { Component, Injector, OnDestroy } from '@angular/core';

import { NavService } from '@mt-ng2/nav-module';

import { AdminAccessComponent } from '../separated-portal/admin-access.component';

@Component({
    selector: 'app-admin-access',
    templateUrl: '../separated-portal/admin-access.component.html',
})
export class AdminAccessOldStyleComponent extends AdminAccessComponent implements OnDestroy {
    protected navService: NavService;

    constructor(injector: Injector) {
        super(injector);
        this.navService = injector.get(NavService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.navService.setShowNav(false);
    }

    ngOnDestroy(): void {
        this.navService.setShowNav(true);
    }
}
