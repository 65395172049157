import { BaseService } from '@mt-ng2/base-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IDocument } from '@model/interfaces/base';
import { IDocumentType } from '@model/interfaces/document-type';
import { IDonorDocument } from '@model/interfaces/donor-document';
import { DocumentEntityTypes } from '@model/enums/document-entity-types';

@Injectable({
    providedIn: 'root',
})
export class DonorIdsService extends BaseService<IDocument> {
    constructor(public http: HttpClient) {
        super('/donor-portal/ids', http);
    }

    getDocuments(donorId: number): Observable<IDonorDocument[]> {
        return this.http.get<IDonorDocument[]>(`/donor-portal/ids/${donorId}`, {});
    }

    getDocumentById(donorId: number, documentId: number): Observable<Blob> {
        return this.http.get(`/donor-portal/ids/${donorId}/${documentId}`, { responseType: 'blob' as 'blob' });
    }

    saveDocument(donorId: number, documentTypeId: number, file: File): any {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http
            .post(`/donor-portal/ids/${donorId}/uploads/${documentTypeId}`, formData)
            .pipe(catchError(this.handleError));
    }

    deleteDocument(donorId: number, documentId: number): Observable<any> {
        return this.http.delete<any>(`/donor-portal/ids/${donorId}/${documentId}`, {});
    }
}
