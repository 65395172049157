import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import {
    FamilyHistoryDynamicControlsPartial,
    IFamilyHistoryDynamicControlsParametersExtends,
} from '@model/partials/family-history-partial.form-controls';
import { IFamilyHistory } from '@model/interfaces/family-history';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { IFamilyHistoryDynamicControlsParameters } from '@model/form-controls/family-history.form-controls';
import { UnitsOfMeasurementEnums } from '@model/enums/units-of-measurement.enum';
import { YesNo } from '@model/enums/yes-no.enum';
import { ApplicationService } from '../../../services/application.service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'individual-family-history',
    templateUrl: './individual-family-history.component.html',
})
export class IndividualFamilyHistoryComponent implements OnInit {
    // abstract controls
    @Input('individual') individual: IFamilyHistory;
    @Input() countries: ICountryMetaItem[];
    @Output() onFormCreate = new EventEmitter<FormGroup>();
    abstractFamilyHistoryControls: any;
    familyHistoryForm: FormGroup;
    @Input('metaData') metaData: IPersonalInformationMetaData;
    formCreated = false;
    @Input() unitsOfMeasurement: IUnitsOfMeasurement[];
    @Input() heightUnits: IUnitsOfMeasurement[];
    @Input() weightUnits: IUnitsOfMeasurement[];
    @Input() siblingIndex: number;
    selectedHeightUnitId: number;
    selectedWeightUnitId: number;

    subs = new Subscription();

    constructor(private fb: FormBuilder, private applicationService: ApplicationService, private commonService: CommonService) {}

    get isSibling(): boolean {
        return this.siblingIndex !== null && this.siblingIndex !== undefined;
    }

    get isHalfSibling(): boolean {
        return this.familyHistoryForm.get('FamilyHistory.IsFullSibling').value === YesNo.No;
    }

    ngOnInit(): void {
        this.selectedWeightUnitId = this.individual.WeightUnitId;
        this.selectedHeightUnitId = this.individual.HeightUnitId;
        this.createForm();
        this.subs.add(
            this.applicationService.applicationSaved$.subscribe((saved) => {
                if (saved && this.familyHistoryForm) {
                    markAllFormFieldsAsTouched(this.familyHistoryForm);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    usingMetric(): boolean {
        return this.selectedHeightUnitId === UnitsOfMeasurementEnums.Meters;
    }

    optionIsSelected(controlName: string): boolean {
        const control = this.familyHistoryForm.get(`FamilyHistory.${controlName}`);
        return control && control.value === YesNo.Yes;
    }

    controlHasValue(controlName: string): boolean {
        const control = this.familyHistoryForm.get(`FamilyHistory.${controlName}`);
        return control && control.value;
    }

    createForm(): void {
        this.getControls();
        this.familyHistoryForm = this.assignFormGroups();
        this.formCreated = true;
        this.onFormCreate.emit(this.familyHistoryForm);
    }

    getControls(): void {
        const additionalParams: IFamilyHistoryDynamicControlsParametersExtends = {
            countries: this.countries,
            educationCompletedTypes: this.metaData.EducationCompletedTypes,
            eyeColors: this.metaData.EyeColors,
            hairColors: this.metaData.HairColors,
            hairTextures: this.metaData.HairTextures,
            heightUnits: this.heightUnits,
            races: this.metaData.Races,
            skinTypes: this.metaData.SkinTypes,
            weightUnits: this.weightUnits,
        };
        this.abstractFamilyHistoryControls = new FamilyHistoryDynamicControlsPartial(this.individual, additionalParams).Form;
    }

    assignFormGroups(): FormGroup {
        let form = this.fb.group({
            FamilyHistory: this.fb.group({}),
        });

        if (this.isSibling) {
            form.addControl('siblingIndex', new FormControl(this.siblingIndex));
        }
        return form;
    }
}
