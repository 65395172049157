let initialized = false;
export function initialize(): void {
    if (initialized) {
        return;
    }
    initialized = true;
    // inject the stylesheet request
    let stylesheetLink = document.createElement('link');
    stylesheetLink.href = 'https://cdn.quilljs.com/1.3.6/quill.snow.css';
    stylesheetLink.rel = 'stylesheet';
    let firstLink = document.getElementsByTagName('link')[0];
    firstLink.parentNode.insertBefore(stylesheetLink, firstLink);
}
