import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageTitleComponent } from './page-title-component/page-title.component';

@NgModule({
    declarations: [PageTitleComponent],
    exports: [PageTitleComponent],
    imports: [CommonModule],
})
/**
 * This module is responsible for setting page titles to the browser tab.  It allows
 * for customization of the string prior to setting the tab (i.e. prefixing all titles
 * with something like 'Breck - Customers'), and provides event hooks for page title
 * setting.  There is also a page title component, which takes care of calling the
 * PageTitlesService on any change in the title.
 */
export class PageTitlesModule {}
