import { Component, OnInit, Input, Output, EventEmitter, Injector } from '@angular/core';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ISharedEntitiesComponentConfig, IIsSharedEntities } from './interfaces/shared-entities';
import { BlankSharedEntitiesConfig, SharedEntitiesEditOptions } from './classes/shared-entities.library';
import { SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IDynamicField } from '@mt-ng2/dynamic-form';
import { ActivatedRoute, Router } from '@angular/router';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { EntityListConfig, IEntity } from '@mt-ng2/entity-list-module';
import { Observable, of, forkJoin } from 'rxjs';
import { IMetaItemListDefinition, IIsMetaItemService } from '@mt-ng2/base-service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

@Component({
    selector: 'shared-entities',
    templateUrl: './shared-entities.component.html',
})
export class SharedEntitiesComponent implements OnInit {
    _sharedEntityEnum: number;
    @Input('sharedEntityId')
    set sharedEntity(value: number) {
        this._sharedEntityEnum = value;
    }

    _sharedEntitiesConfig: ISharedEntitiesComponentConfig<IEntity> = new BlankSharedEntitiesConfig();

    private _entities: IEntity[] = [];
    get entitiesArray(): IEntity[] {
        return this._entities;
    }
    @Input('entitiesArray')
    set entitiesArray(value: IEntity[]) {
        this.entitysPassedIn = true;
        this._entities = value;
        this.selectedEntity = null;
    }
    entitysPassedIn = false;
    service: IIsSharedEntities<IEntity>;

    _total: number;
    @Input('total')
    set total(value: number) {
        this._total = value;
    }

    _canEdit: boolean;
    @Input('canEdit')
    set canEdit(value: boolean) {
        this._canEdit = value;
    }

    _canAdd: boolean;
    @Input('canAdd')
    set canAdd(value: boolean) {
        this._canAdd = value;
    }

    _seeAllUrl: string;
    @Input('seeAllUrl')
    set seeAllUrl(value: string) {
        this._seeAllUrl = value;
    }

    _max = 3;
    @Input('max')
    set max(value: number) {
        this._max = value;
    }
    order = '';
    orderDirection = '';
    entityListConfig: EntityListConfig = new EntityListConfig({
        columns: null,
    });

    @Input('additionalInfoButtonOverride') additionalInfoButtonOverride: string;

    @Output('onSave') onSave: EventEmitter<IEntity> = new EventEmitter<IEntity>();
    @Output('onDelete') onDelete: EventEmitter<IEntity> = new EventEmitter<IEntity>();

    private _selectedEntity: IEntity = null;
    get selectedEntity(): IEntity {
        return this._selectedEntity;
    }
    set selectedEntity(value: IEntity) {
        this._selectedEntity = value;
        if (value) {
            this.formObject = this._sharedEntitiesConfig.getDynamicFormConfig(value).formObject;
        }
    }

    parentId: number;
    formObject: IDynamicField[];
    states: any[] = [[]];

    get showAdditionalInfoButton(): boolean {
        return (
            this._sharedEntitiesConfig.SharedEntitiesEditOption === SharedEntitiesEditOptions.InPlaceWithAdditionalInfoButton &&
            this.selectedEntity.Id > 0
        );
    }

    get isEditing(): boolean {
        return this.selectedEntity && this._canEdit;
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private injector: Injector,
        private notificationsService: NotificationsService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.setVariables();
    }

    /**
     * Assigns local variables to defaults or config properties.
     */
    setVariables(): void {
        const config: IEntityRouteConfig = <IEntityRouteConfig>this.route.parent.snapshot.data;
        if (config?.entityIdParam) {
            this.parentId = +this.route.parent.snapshot.paramMap.get(config.entityIdParam);
        }
        if (config && (<any>config).sharedEntities) {
            let sharedEntityConfig = <any>config;
            let sharedEntity = sharedEntityConfig.sharedEntities.find((item) => item.sharedEntity === this._sharedEntityEnum);
            if (sharedEntity) {
                this.service = this.injector.get(sharedEntity.service);
                this._sharedEntitiesConfig = new sharedEntity.config();
                this.entityListConfig = this._sharedEntitiesConfig.SharedEntityListConfig.EntityListConfig;
                this.order = this.entityListConfig.getDefaultSortProperty();
                this.orderDirection = this.entityListConfig.getDefaultSortDirection();
            }
        }
        if (this._canEdit === undefined) {
            this._canEdit = config?.claimType ? this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]) : false;
        }
        if (this._canAdd === undefined) {
            this._canAdd = this._canEdit;
        }
        if (this._seeAllUrl === undefined) {
            this._seeAllUrl = this._sharedEntitiesConfig.EntityNamePluralized.toLowerCase().replace(/\s/g, '');
        }
        this.getInitilizationProcesses().subscribe((answer) => {
            this.getEntities();
            this._sharedEntitiesConfig.setMetaItemLists(answer);
        });
    }

    getInitilizationProcesses(): Observable<IMetaItemListDefinition[]> {
        let observables = [];
        let metaItemServices = this._sharedEntitiesConfig.MetaItemServices;
        for (let mis of metaItemServices) {
            let metaItemService = <IIsMetaItemService>this.injector.get(mis);
            observables.push(metaItemService.MetaItemListServiceInitilizer.getItems());
        }
        if (observables.length === 0) {
            return of([]);
        }
        return forkJoin<IMetaItemListDefinition>(observables);
    }

    getEntities(): void {
        if (!this.entitysPassedIn && this.hasService()) {
            const searchEntity: IEntitySearchParams = {
                order: this.order,
                orderDirection: this.orderDirection,
                query: '',
                skip: 0,
                take: this._max,
            };
            const searchparams = new SearchParams(searchEntity);
            this.service.getEntities(this.parentId, searchparams).subscribe((response) => {
                this._entities = response.body;
                this._total = +response.headers.get('X-List-Count');
            });
        }
    }

    entitySelected(entity: IEntity): void {
        switch (+this._sharedEntitiesConfig.SharedEntitiesEditOption) {
            case +SharedEntitiesEditOptions.InSeparatePage:
                this.router.navigate([`${this._seeAllUrl}/${entity.Id}`], {
                    relativeTo: this.route,
                });
                break;
            default:
                this.selectedEntity = entity;
                break;
        }
    }

    hasService(): boolean {
        return this.service && this.parentId && this.parentId > 0;
    }

    seeAll(): void {
        this.router.navigate([`${this._seeAllUrl}`], {
            relativeTo: this.route,
        });
    }

    additionalInfo(): void {
        this.router.navigate([`${this._seeAllUrl}/${this.selectedEntity.Id}`], {
            relativeTo: this.route,
        });
    }

    addEntity(): void {
        switch (+this._sharedEntitiesConfig.SharedEntitiesEditOption) {
            case +SharedEntitiesEditOptions.InSeparatePage:
                this.router.navigate([`${this._seeAllUrl}/0`], {
                    relativeTo: this.route,
                });
                break;
            default:
                this.selectedEntity = {
                    ...this._sharedEntitiesConfig.EmptyEntity,
                };
                break;
        }
    }

    save(entityForm): void {
        if (entityForm.valid) {
            // TODO: FIX THIS
            //   entityForm.value.Address = {Address1: entityForm.value.Address1,
            //     Address2: entityForm.value.Addresse2, City: entityForm.value.City, StateCode: entityForm.value.StateCode, Zip: entityForm.value.Zip};
            let entity = this._sharedEntitiesConfig.getFormValues(this.selectedEntity, entityForm.value);
            if (this.entitysPassedIn) {
                this.onSave.emit(entity);
                this.selectedEntity = null;
            } else {
                this.saveEntity(entity);
            }
        } else {
            this.notificationsService.error('Save Failed');
            markAllFormFieldsAsTouched(entityForm);
        }
    }

    private saveEntity(entity: IEntity): void {
        this.service.saveEntity(this.parentId, entity).subscribe(
            (success) => {
                this.notificationsService.success(`${this._sharedEntitiesConfig.EntityName} Saved Successfully`);
                if (this.selectedEntity.Id === 0) {
                    // this.selectedEntity.Id = success;
                }
                this.onSave.emit(this.selectedEntity);
                this.selectedEntity = null;
                this.getEntities();
            },
            (error) => this.notificationsService.error('Save Failed'),
        );
    }

    cancel(): void {
        this.selectedEntity = null;
    }

    delete(): void {
        if (this.entitysPassedIn) {
            this.onDelete.emit(this.selectedEntity);
            this.selectedEntity = null;
        } else {
            this.deleteEntity();
        }
    }

    private deleteEntity(): void {
        this.service.deleteEntity(this.parentId, this.selectedEntity.Id).subscribe(
            (success) => {
                this.notificationsService.success(`${this._sharedEntitiesConfig.EntityName} Deleted`);
                this.onDelete.emit(this.selectedEntity);
                this.selectedEntity = null;
                this.getEntities();
            },
            (error) => this.notificationsService.error('Delete Failed'),
        );
    }
}
