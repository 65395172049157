import { IAuthUser } from '../interfaces/auth-user';
import { AuthUserDynamicControlsExtended } from './auth-user.form-controls';
import { IUserRole } from '../interfaces/user-role';
import { DynamicField } from '@mt-ng2/dynamic-form';

export class DonorAuthUserDynamicControlsPartial extends AuthUserDynamicControlsExtended {
    constructor(authUserPartial?: IAuthUser, userRoles?: IUserRole[], configControls?: string[]) {
        super(authUserPartial, userRoles, configControls);

        (<DynamicField>this.Form.Password).failedPatternMessage = 'Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter, and a special character.';
    }
}
