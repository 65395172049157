import { Component, HostListener } from '@angular/core';

@Component({
    selector: 'app-nav-sidebar',
    styles: [
        `
            .nav-sidebar-section {
                overflow: auto;
                flex-grow: 1;
                display: flex;
                height: 0px;
            }
            .main-sidebar {
                overflow: hidden;
            }
            .nav-panel-section {
                overflow: auto;
            }
            .nav-panel-section::-webkit-scrollbar {
                width: 5px;
                background-color: #f5f5f5;
            }
            .nav-panel-section::-webkit-scrollbar-thumb {
                background-color: #aaa;
            }
        `,
    ],
    template: `
        <div class="nav-sidebar-section">
            <div class="main-sidebar sidebar">
                <sidebar-current-user-details></sidebar-current-user-details>
                <div class="nav-panel-section" [style.max-height]="navTreeMaxHeight ? navTreeMaxHeight : null">
                    <sidebar-content-section></sidebar-content-section>
                </div>
            </div>
        </div>
    `,
})
export class NavSidebarComponent {
    navTreeMaxHeight: string;

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this.setMaxHeight(event.target.innerHeight);
    }

    constructor() {
        this.setMaxHeight(window.innerHeight);
    }

    setMaxHeight(innerHeight: number): void {
        this.navTreeMaxHeight = innerHeight - 115 + 'px';
    }
}
