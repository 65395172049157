import { InjectionToken } from '@angular/core';

export interface IGoogleAuthConfig {
    googleApiKey: string;
}

export class GoogleAuthConfig implements IGoogleAuthConfig {
    public googleApiKey: string;

    constructor(options: IGoogleAuthConfig) {
        Object.assign(this, options);
    }
}

export class LoginComponentLink {
    constructor(public linkHtml: string, public routerLinkPath: string) {}
}

/**
 * @property failedPattern: Message that displays when the password complexity doesn't meet the requirements
 * @property forgotPasswordFailure: Error displayed when the forgot password endpoint fails
 * @property forgotPasswordLabel: Text that displays to the user above the forgot password form
 * @property forgotPasswordSuccess: Success displayed when the forgot password endpoint succeeds
 * @property googleSignInFailure: Error displayed when the Google login endpoint fails
 * @property googleSignInFailureEmail: Error displayed when the Google email doesn't exist in our system.
 * @property googleSignInSuccess: Success displayed when the Google login endpoint succeeds
 * @property loginLabel: Label text displayed to the user on the login screen
 * @property signInButtonText: Text displayed inside the sign-in button
 * @property userNamePasswordFailure: Error displayed when login endpoint returns a failure
 */
export interface IMessageOverrides {
    failedPattern?: string;
    forgotPasswordFailure?: string;
    forgotPasswordLabel?: string;
    forgotPasswordSuccess?: string;
    googleSignInFailure?: string;
    googleSignInFailureEmail?: string;
    loginLabel?: string;
    resetPasswordLabel?: string;
    signInButtonText?: string;
    userNamePasswordFailure?: string;
}

export type RememberOptions = 'userName' | 'rememberMe';

export interface IRememberFeature {
    rememberOptions: RememberOptions;
}

/**
 * Interface representing the customizable properties
 * of the login module.
 * @example
 * const loginModuleConfig: ILoginModuleConfig = {
 *     signInButtonText: 'Let Me In!',
 * };
 * @property message - Deprecated: Message that displays on the login screen
 * @property signInButtonText - Deprecated: Text to display in the login button
 * @property allowRememberMe: Deprecated: Toggles the remember me check box
 * @property forgotPasswordMessage - Deprecated
 * @property googleAuthConfig: API Key for google oauth account
 * @property hideRegularSignIn: Hides the username/password ui and just displays 3rd party oauth
 * @property passwordPatter: Regex patter for validating the password
 * @property failedPatternMessage - Deprecated: Message displayed when the password doesn't meet complexity requirements
 * @property loginComponentLinks: An array of links to display below the sign-in form
 * @property messageOverrides: An object for overriding all the notification messages in the login module.
 * @property rememberFeature: An object for configuring remember feature.
 */
export interface ILoginConfig {
    message?: string;
    signInButtonText?: string;
    allowRememberMe?: boolean;
    forgotPasswordMessage?: string;
    googleAuthConfig?: GoogleAuthConfig;
    hideRegularSignIn?: boolean;
    passwordPattern?: string;
    failedPatternMessage?: string;
    loginComponentLinks?: LoginComponentLink[];
    messageOverrides?: IMessageOverrides;
    rememberFeature?: IRememberFeature;
}

/**
 * Token used for providing the ILoginModuleConfig
 * @example
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: LoginModuleConfigToken, useValue: loginModuleConfig },
 * ],
 */
export const LoginModuleConfigToken = new InjectionToken<ILoginConfig>('login_module_config');
