import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AppStates, AppStateService } from '../common/services/app-state.service';

@Injectable()
export class ApplicationGuard implements CanActivate {
    constructor(private appStateService: AppStateService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const appState = this.appStateService.appState.getValue();
        return appState === AppStates.applying;
    }
}
