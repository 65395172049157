import { Component, Injector, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ISelectionChangedEvent, ITypeAheadAPI } from '@mt-ng2/type-ahead-control';
import { MatchingStrategy } from '@mt-ng2/type-ahead-control-config';

import { CustomFormControlComponentBase } from '../custom-form-component.base';
import { Subscription } from 'rxjs';

@Component({
    styles: [
        `
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
        `,
    ],
    templateUrl: './form-type-ahead.component.html',
})
export class FormTypeAheadComponent extends CustomFormControlComponentBase implements OnInit, AfterViewInit, OnDestroy {
    items: any[];
    selectedItem: any;
    typeAheadApi: ITypeAheadAPI;
    subscriptions = new Subscription();
    matchingStrategy: MatchingStrategy;
    placeholder: string;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.items = this.config?.options ?? [];
        this.placeholder = this.config?.placeholder ?? '';
        if (this.config?.type?.typeAheadOptions?.matchingStrategy) {
            this.matchingStrategy = this.config.type.typeAheadOptions.matchingStrategy;
        }
    }

    ngAfterViewInit(): void {
        this.subscriptions.add(
            this.getControl().valueChanges.subscribe((value) => {
                if (!value) {
                    this.typeAheadApi.clearValue();
                }
            }),
        );
        super.ngAfterViewInit();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    selectionChanged(event: ISelectionChangedEvent): void {
        if (event?.selection) {
            this.getControl().setValue(event.selection.Id);
        } else {
            let control = this.getControl();
            if (control.value) {
                this.getControl().setValue(null);
            }
        }
    }

    typeAheadControlReady(event: ITypeAheadAPI): void {
        let selectedOption = this.config.options.find((option) => {
            return option.Id === this.config.value;
        });
        event.setValue(selectedOption);
        this.getControl().setValue(this.config.value);
        this.typeAheadApi = event;
    }

    focusMe(): void {
        this.typeAheadApi.focus();
    }
}
