import { Component, Injector, OnDestroy } from '@angular/core';

import { NavService } from '@mt-ng2/nav-module';

import { LoginComponent } from '../separated-portal/login.component';

@Component({
    selector: 'app-login',
    templateUrl: '../separated-portal/login.component.html',
})
export class LoginOldStyleComponent extends LoginComponent implements OnDestroy {
    protected navService: NavService;

    constructor(injector: Injector) {
        super(injector);
        this.navService = injector.get(NavService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.navService.setShowNav(false);
    }

    ngOnDestroy(): void {
        this.navService.setShowNav(true);
    }
}
