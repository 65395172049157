import { Component, OnInit, Input } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApplicationIntroduceYourselfService } from '../../../../admin-portal/common/services/application-introduce-yourself.service';
import { IntroduceYourselfDynamicConfig } from './introduce-yourself.dynamic-config';
import { IApplicationIntroduceYourself } from '@model/interfaces/application-introduce-yourself';
import { IApplicationIntroduceYourselfDynamicControlsParameters } from '@model/form-controls/application-introduce-yourself.form-controls';
import { ApplicationIntroduceYourselfDynamicControlsPartial } from '@model/partials/application-introduce-yourself-partial.form-controls';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ICurrentSectionAndStatus } from '../../../../donor-portal/donor-application/services/application.service';
import { FormSections } from '@model/enums/form-sections.enum';
import { appPaths } from '../../../../donor-portal/default-routes/app-paths.library';
import { ILoggedIn } from '@mt-ng2/auth-module';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { ApplicationService } from '../../services//application.service';

@Component({
    selector: 'introduce-yourself',
    styles: [
        `
        .input-scaffold {
            .input-rounded {
                border-radius:0px!important;
            }
        }

        p {
            padding-bottom:15px;
            line-height: 22px;
        }
        `,
    ],
    templateUrl: './introduce-yourself.component.html',
})
export class IntroduceYourselfComponent implements OnInit {

    private configControls = [
        'TalentsAbilities',
        'Favorites',
        'CharacterDescription',
        'WhyInterestedInBecomingEggDonor',
        'Goals',
        'ExerciseRoutine',
        'LanguagesSpoken',
        'Complete',
        'Submitted',
    ];
    config: any = { formObject: [], viewOnly: [] };
    @Input() isIntroduceYourselfComplete: Subject<boolean>;
    @Input() introduceYourself: IApplicationIntroduceYourself;
    hideTrim = true;

    formFactory: IntroduceYourselfDynamicConfig<IApplicationIntroduceYourself>;
    doubleClickIsDisabled = false;
    formReady = false;

    introduceYourselfSuccess = false;
    formCreated = false;
    introduceYourselfControls: any;
    introduceYourselfForm: FormGroup;
    subs = new Subscription();
    currentStatus: ICurrentSectionAndStatus = {
        formGroup: null,
        nextTab: null,
        nextTabLabel: null,
        routerLink: null,
        sectionId: FormSections.IntroduceYourself,
        status: 'In Progress',
    };
    subscription: Subscription = new Subscription();
    submittedAndSaved = false;

    additionalParameters: IApplicationIntroduceYourselfDynamicControlsParameters;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private notificationService: NotificationsService,
        private introduceYourselfService: ApplicationIntroduceYourselfService,
        private pageTitleService: PageTitlesService,
        private applicationService: ApplicationService,
    ) { }

    ngOnInit(): void {
        this.pageTitleService.setPageTitle('Donor Application - Introduce Yourself');

        this.applicationService.getPendingApplication().subscribe((application) => {
            this.introduceYourself = application.ApplicationIntroduceYourself;
            this.createForm();
            this.currentStatus.formGroup = this.introduceYourselfForm;
        });
    }

    //
    // Create the primary form
    //
    createForm(): void {
        this.introduceYourselfForm = this.assignFormGroups();
        this.getControls();
        this.formCreated = true;

        this.subs.add(
            this.introduceYourselfForm.valueChanges.pipe(debounceTime(50)).subscribe(() => {
                if (this.introduceYourselfForm.dirty) {
                    this.assignFormValues(this.introduceYourselfForm.value.IntroduceYourself);
                }
                this.checkFormStatus();
            }),
        );
    }

    checkFormStatus(): void {
        if (this.introduceYourselfForm.valid && this.introduceYourself.Id > 0) {
            const control = this.introduceYourselfForm.get('IntroduceYourself.Submitted');

            if (control && control.value) {
                this.currentStatus.status = 'Submitted';
                this.introduceYourself.Submitted = true;
            } else {
                this.currentStatus.status = 'Complete';
                this.introduceYourself.Complete = true;
                this.introduceYourself.Submitted = false;
            }
        } else {

            this.currentStatus.status = 'In Progress';
            this.introduceYourself.Complete = false;
        }
    }

    //
    // Create the form group
    //
    assignFormGroups(): FormGroup {
        return this.fb.group({
            IntroduceYourself: this.fb.group({}),
        });
    }

    //
    // Generate the controls
    //
    getControls(): void {

        this.additionalParameters = {
            formGroup: 'IntroduceYourself',
        } as IApplicationIntroduceYourselfDynamicControlsParameters;

        this.formFactory = new IntroduceYourselfDynamicConfig<IApplicationIntroduceYourself>(this.introduceYourself, this.configControls, this.additionalParameters);
        this.config = this.formFactory.getForUpdate();

        this.introduceYourselfControls = new ApplicationIntroduceYourselfDynamicControlsPartial(this.introduceYourself, this.additionalParameters).Form;
    }

    //
    // Gather data from the form
    //
    assignFormValues(info: any): void {
        if (this.introduceYourselfForm.valid) {
            Object.assign(this.introduceYourself, { ...this.introduceYourselfForm.value.IntroduceYourself });
        }
    }

    //
    // Submit the prescreening for validation.
    //
    validateForm(): void {
        this.assignFormValues(this.introduceYourselfForm.value.IntroduceYourself);
        this.introduceYourselfService.updateIntroduceYourself(this.introduceYourself).subscribe((answer) => {
            this.submittedAndSaved = false;
            this.checkFormStatus();

            if (this.introduceYourself.Submitted) {
                this.submittedAndSaved = true;
            }
            this.success();

            if (this.submittedAndSaved) {
                this.router.navigateByUrl(appPaths.register);
            }
        });
    }

    //
    // Report that the oepration was succesful
    //
    success(): void {
        this.currentStatus.formGroup.markAsPristine();
        this.notificationService.success('Information saved successfully!');
    }

    //
    // Expose the "form is dirty" flag
    //
    get isDirty(): boolean {
        return this.introduceYourselfForm.dirty;
    }

    //
    // Expose the "pre-screeing is complete" flag
    //
    get isComplete(): boolean {
        return this.introduceYourself && this.introduceYourself.Complete;
    }

    //
    // Expose the "pre-screeing has been submitted" flag
    //
    get isSubmitted(): boolean {
        return this.introduceYourself && this.introduceYourself.Submitted;
    }

    //
    // Expose the "pre-screeing has been submitted AND SAVED" flag
    //
    get isSubmittedAndSaved(): boolean {
        return this.submittedAndSaved;
    }
}
