import { Component, Input, Injector, ViewContainerRef, Renderer2, ComponentFactoryResolver, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';

import { TypeAheadBaseComponent } from './type-ahead.base-component';

import { debounceTime } from 'rxjs/operators';

export type VirtualTypeAheadGetItemsFunction = (searchText: string) => Observable<any[]>;

@Component({
    selector: '<mt-virtual-type-ahead>',
    template: `
        <ng-container *componentErrorHandler="errorHandler">
            <span>
                <input
                    #inputElement
                    type="text"
                    class="form-control"
                    [placeholder]="emptyText"
                    autocorrect="off"
                    autocomplete="off"
                    autocapitalize="off"
                    role="combobox"
                    [formControl]="inputControl"
                    (blur)="onBlur()"
                    (keydown)="handleKeyDown($event)"
                />
            </span>
        </ng-container>
    `,
})
export class VirtualTypeAheadComponent extends TypeAheadBaseComponent {
    @Input() getItems: VirtualTypeAheadGetItemsFunction;

    constructor(injector: Injector, vcr: ViewContainerRef, renderer: Renderer2, resolver: ComponentFactoryResolver, ngZone: NgZone) {
        super(injector, vcr, renderer, resolver, ngZone);
        this.errorHandler = new ComponentErrorHandler('VirtualTypeAheadComponent', '@mt-ng2/type-ahead-control');

        if (this.minimumCharactersToShow === undefined) {
            this.minimumCharactersToShow = 2;
        }

        this.subscriptions.add(
            this.inputControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
                if (value && value.trim().length >= this.minimumCharactersToShow) {
                    if (!this.isPopupOpen() && value && !(this.selected && this.getValue(this.selected) === value)) {
                        this.openPopup();
                    } else if (this._windowRef) {
                        this.getItems(value).subscribe((items) => {
                            this._windowRef.instance.items = items;
                            this._windowRef.instance.searchText = value;
                            this._windowRef.instance.cdr.detectChanges();
                        });
                    }
                } else if (this.isPopupOpen()) {
                    this.closePopup();
                }
            }),
        );
    }

    protected openPopup(): void {
        if (this.isPopupOpen()) {
            return;
        }
        this.getItems(this.inputControl.value).subscribe((items) => {
            this._windowRef = this._popupService.open();
            this._windowRef.instance.items = items;
            this._windowRef.instance.searchText = this.inputControl.value;
            this._windowRef.instance.nameProperty = this.nameProperty;
            this._windowRef.instance.maxToShow = this.maxToShow;
            this._windowRef.instance.errorHandler = this.errorHandler;
            this.subscriptions.add(
                this._windowRef.instance.onSelection.subscribe((result: any) => {
                    this.handleSelection(result);
                    this.closePopup();
                }),
            );
            window.document.querySelector('body').appendChild(this._windowRef.location.nativeElement);
        });
    }
}
