import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApplicationFamilyHistory } from '@model/interfaces/application-family-history';
import { BaseService } from '@mt-ng2/base-service';

export const emptyFamilyHistory: IApplicationFamilyHistory = {
    Complete: null,
    Submitted: null,
    DateCreated: new Date(),
    FatherHistoryId: null,
    Id: 0,
    MaternalGrandfatherHistoryId: null,
    MaternalGrandmotherHistoryId: null,
    MotherHistoryId: null,
    PaternalGrandfatherHistoryId: null,
    PaternalGrandmotherHistoryId: null,
};

@Injectable({
    providedIn: 'root',
})
export class ApplicationFamilyHistoryService extends BaseService<IApplicationFamilyHistory> {
    constructor(public http: HttpClient) {
        super('/donor-portal/application-family-history', http);
    }

    getEmptyApplicationFamilyHistory(): IApplicationFamilyHistory {
        return { ...emptyFamilyHistory };
    }
}
