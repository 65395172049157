import { Component, OnInit, Input } from '@angular/core';
import { markAllFormFieldsAsTouched, pluralize } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { MetaItemService, IEntity } from '@mt-ng2/base-service';

@Component({
    selector: 'meta-item-managed-list',
    template: `
        <mt-managed-list
            [componentTitle]="title"
            [items]="items"
            [sortable]="sortable"
            [sortPropertyName]="sortPropertyName"
            [duplicateCheck]="duplicateCheck"
            [duplicateCheckFields]="duplicateCheckFields"
            [dynamicForm]="dynamicForm"
            [fields]="fields"
            [canAdd]="canAdd"
            [canRemove]="canRemove"
            [canEdit]="canEdit"
            (onSave)="saveItems($event)"
        ></mt-managed-list>
    `,
})
export class MetaItemManagedListComponent implements OnInit {
    @Input('dynamicForm') dynamicForm: any;
    @Input('service') service: MetaItemService<IEntity>;
    @Input('sortPropertyName') sortPropertyName = 'Sort';
    @Input('fields') fields: string[];
    @Input('duplicateCheck') duplicateCheck = true;
    @Input('duplicateCheckFields') duplicateCheckFields: string[];
    @Input('componentTitle') title: string;
    @Input('sortable') sortable;
    @Input('canAdd') canAdd = true;
    @Input('canRemove') canRemove = true;
    @Input('canEdit') canEdit = true;

    items: any[] = [];

    constructor(private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        if (!this.title) {
            this.title = pluralize(this.service.objectName);
        }
        this.getItems();
    }

    get cleanTitle(): string {
        return this.title.replace(/ +/g, '');
    }

    getItems(): void {
        this.service.getAll().subscribe((answer) => {
            this.items = answer;
        });
    }

    saveItems(form: any): void {
        if (form.valid) {
            this.service
                .updateItems(form.value[this.cleanTitle])
                .pipe(finalize(() => this.getItems()))
                .subscribe(() => {
                    this.notificationsService.success(`${this.title} saved successfully`);
                    this.getItems();
                });
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }
}
