import { Validators, ValidationErrors, FormControl, FormBuilder, ValidatorFn } from '@angular/forms';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { CustomErrorMessageHandler } from '@mt-ng2/dynamic-form-config';

import { CustomFormControlComponentBase } from '../form-elements/custom-form-component.base';
import { NumericInputTypes } from '../form-elements/form-numeric/form-numeric.component';
import { IAddressContainer } from '../mt-address/mt-address.component';
import { noZeroRequiredValidator } from '../validators/no-zero-required.validator';
import { DatepickerDateOptionItem } from '../libraries/datepicker-date-option-item.library';
import { InputTypes, SelectInputTypes } from '../form-elements/form-elements.library';
import { IWysiwygComponentConfig } from '@mt-ng2/wysiwyg-control';
import { IDynamicField } from './interfaces/dynamic-field';
import { LabelPosition, LabelPositions } from './interfaces/label-position';
import { IDynamicValidation } from './interfaces/dynamic-validation';
import { IDynamicFieldType, DynamicFieldTypes, IDynamicFieldTypeNumericFunctions } from './interfaces/dynamic-field-type';
import { INumericAddOn } from './interfaces/numeric-add-on';
import { ITimepickerOptions } from './interfaces/time-picker-options';
import { IDatepickerOptions } from './interfaces/date-picker-options';
import { IColorpickerOptions } from './interfaces/color-picker-options';
import { ITypeAheadOptions } from './interfaces/type-ahead-options';

/**
 * This class constructs the form control object
 * @property label
 * @property value
 * @property name
 * @property type
 * @property options
 * @property validation
 * @property validators
 * @property placeholder
 * @property disabled: when set to number or number array, will be used to check them as claim type ids, and if those claims are not present, control will be disabled
 * @property insideBoxValidation
 * @property labelHtml
 * @property labelPosition
 * @property {CustomFormControlComponentBase} component component to use for this field
 */
export class DynamicField implements IDynamicField {
    public get labelAtTop(): boolean {
        return this.labelPosition.position === LabelPositions.Top;
    }
    public get labelLeft(): boolean {
        return this.labelPosition.position === LabelPositions.Left;
    }
    public get labelHidden(): boolean {
        return this.labelPosition.position === LabelPositions.Hidden;
    }
    public get labelCols(): number {
        return this.labelPosition.colsForLabel || 0;
    }
    public get controlCols(): number {
        return 12 - this.labelCols;
    }

    formGroup: string;
    label: string;
    value: string | number | number[] | Date | boolean | IAddressContainer;
    name: string;
    type: DynamicFieldType;
    options: any[];
    validation: Validators[];
    validators: IDynamicValidation;
    placeholder: string;
    disabled: boolean | number | number[];
    insideBoxValidation: boolean;
    labelHtml: string;
    autoFocus: boolean;
    labelPosition: LabelPosition;
    failedPatternMessage: string;
    doNotCreateControl: boolean | number | number[];
    component: typeof CustomFormControlComponentBase;
    autoCompleteEnabled: boolean;
    errorMessageHandler: CustomErrorMessageHandler;
    customOptions: any;

    constructor(constructorObject: IDynamicField) {
        this.constructClass(constructorObject);
    }

    private constructClass(constructorObject: IDynamicField): void {
        // set some defaults to the contructorObject
        if (typeof constructorObject.validators === 'undefined') {
            constructorObject.validators = {};
        }
        if (typeof constructorObject.disabled === 'undefined') {
            constructorObject.disabled = false;
        }
        if (typeof constructorObject.insideBoxValidation === 'undefined') {
            constructorObject.insideBoxValidation = false;
        }
        if (typeof constructorObject.labelHtml === 'undefined') {
            constructorObject.labelHtml = `<label>${constructorObject.label}</label>`;
        }
        if (typeof constructorObject.autoFocus === 'undefined') {
            constructorObject.autoFocus = false;
        }
        if (typeof constructorObject.failedPatternMessage === 'undefined') {
            constructorObject.failedPatternMessage = '';
        }
        if (typeof constructorObject.doNotCreateControl === 'undefined') {
            constructorObject.doNotCreateControl = false;
        }
        if (typeof constructorObject.type.datepickerOptions === 'undefined') {
            constructorObject.type.datepickerOptions = {};
        }

        if (typeof constructorObject.autoCompleteEnabled === 'undefined') {
            constructorObject.autoCompleteEnabled = true;
        }

        const defaultValidators: IDynamicValidation = {
            showOptional: false,
            showRequired: true,
        };
        constructorObject.validators = Object.assign({}, defaultValidators, constructorObject.validators);

        if (typeof constructorObject.type.inputType === 'undefined' || constructorObject.type.inputType === null) {
            if (constructorObject.type.fieldType === DynamicFieldTypes.Input) {
                const maxLengthOverTextboxDefault = constructorObject.validators?.maxlength > 100 ? true : false;

                constructorObject.type.inputType = maxLengthOverTextboxDefault ? InputTypes.Textarea : InputTypes.Textbox;
            }
        }

        // assign contructorObject values to the class
        this.formGroup = constructorObject.formGroup;
        this.label = constructorObject.label;
        this.value = constructorObject.value;
        this.name = constructorObject.name;
        this.type = constructorObject.type;
        this.options = constructorObject.options;
        this.validation = constructorObject.validation;
        this.validators = constructorObject.validators;
        this.placeholder = constructorObject.placeholder;
        this.disabled = constructorObject.disabled;
        this.insideBoxValidation = constructorObject.insideBoxValidation;
        this.labelHtml = constructorObject.labelHtml;
        this.autoFocus = constructorObject.autoFocus;
        this.autoCompleteEnabled = constructorObject.autoCompleteEnabled;
        this.failedPatternMessage = constructorObject.failedPatternMessage;
        this.doNotCreateControl = constructorObject.doNotCreateControl;
        this.labelPosition = constructorObject.labelPosition || new LabelPosition({ position: LabelPositions.Top });
        this.component = constructorObject.component;
        this.errorMessageHandler = constructorObject.errorMessageHandler;
        this.customOptions = constructorObject.customOptions;
    }

    setMinLength(value: number): void {
        this.validators.minlength = value;
        if (!this.validation) {
            this.validation = [];
        }
        const index = this.validation.indexOf(Validators.minLength);
        if (index > -1) {
            this.validation.splice(index, 1);
        }
        if (value) {
            this.validation.push(Validators.minLength(value));
        }
    }

    setMaxLength(value: number): void {
        this.validators.maxlength = value;
        if (!this.validation) {
            this.validation = [];
        }
        const index = this.validation.indexOf(Validators.maxLength);
        if (index > -1) {
            this.validation.splice(index, 1);
        }
        if (value) {
            this.validation.push(Validators.maxLength(value));
        }
    }

    setRequired(value: boolean): void {
        this.validators.required = value;
        if (!this.validation) {
            this.validation = [];
        }
        const requiredValidator = this.getRequiredValidator();
        const index = this.validation.indexOf(requiredValidator);
        if (value) {
            if (index === -1) {
                this.validation.push(requiredValidator);
            }
        } else {
            if (index > -1) {
                this.validation.splice(index, 1);
            }
        }
    }

    private getRequiredValidator(): ValidationErrors {
        if (this.type.fieldType === DynamicFieldTypes.Select) {
            if (this.type.doNotAllowZero === false) {
                return Validators.required;
            }
            return noZeroRequiredValidator;
        }
        return Validators.required;
    }

    setPattern(pattern: RegExp): void {
        this.validators.pattern = pattern;
        this.validation.push(Validators.pattern(pattern));
    }

    shouldCreateControl(claimsService: ClaimsService): boolean {
        this.doNotCreateControl = this.claimCheck(this.doNotCreateControl, claimsService);
        return !this.doNotCreateControl as boolean;
    }

    createControl(fb: FormBuilder, claimsService: ClaimsService): FormControl {
        this.disabled = this.claimCheck(this.disabled, claimsService);
        return fb.control({ value: this.value, disabled: this.disabled as boolean }, this.validation as ValidatorFn[]);
    }

    setOptionsForDateOfBirth(): void {
        let dateForDob = new Date();
        this.type.datepickerOptions.maxDate = new DatepickerDateOptionItem(dateForDob);
        dateForDob.setFullYear(dateForDob.getFullYear() - 110);
        this.type.datepickerOptions.minDate = new DatepickerDateOptionItem(dateForDob);
    }

    private claimCheck(claimsToCheck: boolean | number | number[], claimsService: ClaimsService): boolean {
        const hasClaimsBasedValues = typeof claimsToCheck === 'number' || Array.isArray(claimsToCheck) ? true : false;
        if (!hasClaimsBasedValues) {
            return claimsToCheck as boolean;
        }
        // get the claimTypeIds from the propety
        const claimTypeIds = Array.isArray(claimsToCheck) ? (claimsToCheck as number[]) : [claimsToCheck as number];
        // get answers for each hasClaim check
        const hasClaimAnswers = [];
        claimTypeIds.forEach((claimTypeId) => {
            hasClaimAnswers.push(claimsService.hasClaim(claimTypeId, [ClaimValues.FullAccess]));
        });
        // if any of the hasClaimAnswers are not true, then returns false
        return hasClaimAnswers.some((answer) => !answer);
    }
}

export class DynamicFieldType implements IDynamicFieldType {
    fieldType: DynamicFieldTypes;
    inputType: InputTypes | NumericInputTypes | SelectInputTypes;
    scale: number;
    numericAddOn: INumericAddOn;
    doNotAllowZero: boolean;
    allowInternationalAddresses: boolean;
    maxToShowInSelectedText: number;
    allowNullableOption: boolean;
    showSelectAllButtons: boolean;
    multiselectAutoClose: boolean | 'outside' | 'inside';
    numericFunctions?: Partial<IDynamicFieldTypeNumericFunctions>;
    timepickerOptions?: ITimepickerOptions;
    datepickerOptions?: IDatepickerOptions;
    colorpickerOptions?: IColorpickerOptions;
    typeAheadOptions?: ITypeAheadOptions;
    wysiwygOptions?: IWysiwygComponentConfig;

    constructor(constructorObject: IDynamicFieldType) {
        Object.assign(this, constructorObject);
    }
}
