import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { AuthService } from '@mt-ng2/auth-module';
import { filter, finalize } from 'rxjs/operators';
import { RegistrationService } from './registration.service';
import { AuthUserDynamicConfig } from '../auth-entity/auth-user/auth-user.dynamic-config';
import { IDonorRegistrationPayload } from '@model/interfaces/custom/donor-registration-payload';
import { DonorRegistrationDynamicConfig } from './donor-registration.dynamic-config';
import { AppStateService } from '../common/services/app-state.service';
import { IAuthUser } from '@model/interfaces/auth-user';
import { IUser } from '@model/interfaces/user';
import { IPreScreening } from '../../admin-portal/model/interfaces/pre-screening';
import { PreScreeningService } from '../../admin-portal/common/services/pre-screening.service';
import { appPaths } from '../../donor-portal/default-routes/app-paths.library';

declare let gtag: Function;
@Component({
    selector: 'app-register',
    styles: [
        `
            .fa-google {
                padding-right: 10px;
            }
        `,
    ],
    templateUrl: './registration.component.html',
})
export class RegistrationComponent implements OnInit {
    preScreening?: IPreScreening;
    authUser: IAuthUser;
    isEditing = true;
    config: any = {};
    userForm: any;
    formFactory: DonorRegistrationDynamicConfig<IUser>;
    doubleClickIsDisabled = false;
    user: IUser;
    formReady = false;
    @ViewChild('honeypot') honeypot: ElementRef;
    pageLoadTime: Date;

    registering = true;
    registerSuccess = false;

    constructor(
        private preScreeningService: PreScreeningService,
        private registrationService: RegistrationService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        protected appStateService: AppStateService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.preScreening = this.preScreeningService.preScreening;

        if (this.preScreening && this.preScreening.Id !== 0) {

            this.pageLoadTime = new Date();
            this.user = this.registrationService.getEmptyUser();
            this.user.Email = this.preScreening.Email;
            this.config = { formObject: [], viewOnly: [] };
            this.setConfig();
        } else {
            this.router.navigateByUrl(appPaths.login);
        }
    }

    getAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        let configControls: string[] = ['Username', 'Password', 'ConfirmPassword'];
        const authUser = null;
        const config = new AuthUserDynamicConfig<IAuthUser>(authUser, null, configControls);
        return [config];
    }

    setConfig(): void {
        this.formFactory = new DonorRegistrationDynamicConfig<IUser>(this.user);
        this.config = this.formFactory.getForCreate(this.getAdditionalConfigs());
    }

    formSubmitted(form): void {
        // Check if hidden form element is checked or if form is submitted in under 3 seconds to detect bot spam
        if (this.honeypot.nativeElement.checked || new Date().getTime() - this.pageLoadTime.getTime() < 3000) {
            return;
        }
        let passwordmatch = true;
        if (!this.authService.matchPassword(form)) {
            passwordmatch = false;
        }

        if (form.valid && passwordmatch) {
            this.formFactory.assignFormValues(this.user, form.value.User);
            this.user.FirstName = '-';
            this.user.LastName = '-';

            const data: IDonorRegistrationPayload = {
                Password: form.value.AuthUser.Password,
                SendEmail: form.value.AuthUser.SendResetEmail || false,
                User: this.user,
                Username: form.value.AuthUser.Username,
                ApplicationId: this.preScreening.ApplicationId,
            };
            // handle new user save
            this.registrationService
                .registerDonor(data)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.success();
                    this.authService.login(data.Username, data.Password, false).subscribe(
                        (successResponse) => {
                            this.registering = false;
                            this.registerSuccess = true;
                        },
                        (errorResponse) => {
                            this.notificationsService.error('We were unable to log you in. Please try again later.');
                        },
                    );
                });
        } else {
            if (!passwordmatch) {
                this.error('Passwords do not match');
                this.enableDoubleClick();
            } else {
                markAllFormFieldsAsTouched(form);
                this.error();
                this.enableDoubleClick();
            }
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Save failed.  Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Registered Successfully');
    }

    setUpAnalytics(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            gtag('config', 'GTM-KKJK3HT', {
                page_path: event.urlAfterRedirects,
            });
        });
    }
}
