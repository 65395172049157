import { Component, ContentChild, TemplateRef, Injector } from '@angular/core';

import { NavService } from '../nav.service';
import { Router } from '@angular/router';

@Component({
    selector: `header-submit-help`,
    styles: [
        `
            .submit-help {
                cursor: pointer;
                padding-left: 8px;
                padding-right: 8px;
            }
            .submit-help .help-text {
                font-size: 11px;
                vertical-align: text-bottom;
            }
        `,
    ],
    template: `
        <ng-container *ngTemplateOutlet="templateRef; context: { submitHelp: this.submitHelp.bind(this) }"> </ng-container>
        <div *ngIf="showSubmitHelpButton && !templateRef" class="nav navbar-nav submit-help" title="Submit Help Request" (click)="submitHelp()">
            <i class="fa fa-question-circle" aria-hidden="true"></i>
            <span class="hidden-xs hidden-sm help-text"> Help </span>
        </div>
    `,
})
export class HeaderSubmitHelpComponent {
    @ContentChild(TemplateRef) templateRef;

    get showSubmitHelpButton(): boolean {
        return this.navService.showSubmitHelpButton;
    }

    protected navService: NavService;
    protected router: Router;

    constructor(injector: Injector) {
        this.navService = injector.get(NavService);
        this.router = injector.get(Router);
    }

    submitHelp(): void {
        const submitHelpUrl = this.navService.submitHelpUrl;
        let submitHelpWindow: Window;
        if (!this.navService.isCustomSubmitHelpPage) {
            submitHelpWindow = window.open();
            submitHelpWindow.document.write('loading...');
            this.navService.submitHelpRequest().subscribe(
                (requestId) => {
                    submitHelpWindow.location.href = `${submitHelpUrl}/#/submit-request/${requestId}`;
                },
                () => {
                    submitHelpWindow.location.href = `${submitHelpUrl}/#/submit-request`;
                },
            );
        } else {
            if (this.navService.shouldShowSubmitHelpInNewTab) {
                submitHelpWindow = window.open();
                submitHelpWindow.location.href = `${submitHelpUrl}`;
            } else {
                this.router.navigateByUrl(submitHelpUrl);
            }
        }
    }
}
