import { Component, Injector, OnInit } from '@angular/core';
import { CustomFormControlComponentBase } from '../custom-form-component.base';

import { IWysiwygComponentConfig, IWysiwygComponentApi } from '@mt-ng2/wysiwyg-control';

const defaultOptions: IWysiwygComponentConfig = {
    height: 100,
    imagePasteHandler: null,
    toolbar: [['bold', 'italic', 'underline', 'strike'], ['link'], ['bullet-list', 'ordered-list', 'align']],
};

@Component({
    styles: [
        `
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
        `,
    ],
    templateUrl: './form-wysiwyg.component.html',
})
export class FormWYSIWYGComponent extends CustomFormControlComponentBase implements OnInit {
    WYSIWYG_OPTIONS = defaultOptions;

    wysiwygApi: IWysiwygComponentApi;
    insertImageOnPaste = true;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (this.config.type.wysiwygOptions) {
            this.WYSIWYG_OPTIONS = Object.assign({}, defaultOptions, this.config.type.wysiwygOptions);
            console.log('WYSIWYG_OPTIONS', this.WYSIWYG_OPTIONS);
            console.log(this.config.type.wysiwygOptions);
            this.insertImageOnPaste =
                this.config.type.wysiwygOptions.imagePasteHandler === undefined || this.config.type.wysiwygOptions.imagePasteHandler === null;
        }
    }

    focusMe(): void {
        if (this.wysiwygApi) {
            this.wysiwygApi.focus();
        }
    }
}
