import { ChangeDetectorRef, Injector } from '@angular/core';
import { FormGroup, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

import { CustomErrorMessageHandler } from '@mt-ng2/dynamic-form-config';

import { DynamicField } from '../libraries/dynamic-field.library';
import { DynamicFormModuleConfig } from '../libraries/dynamic-form-module.config';
import { CustomFormValidation } from './custom-form-validation.library';

export class FormElementsBase {
    config: DynamicField;
    parentGroup: FormGroup;

    public moduleErrorMessageHandler: CustomErrorMessageHandler;

    constructor(changeDetectorRefOrInjector: ChangeDetectorRef | Injector) {
        if ((changeDetectorRefOrInjector as Injector).get) {
            const moduleConfig = (changeDetectorRefOrInjector as Injector).get(DynamicFormModuleConfig);
            this.moduleErrorMessageHandler = (moduleConfig as any).errorMessageHandler;
        }
    }

    getControl(): AbstractControl {
        return this.getControlFromFormGroup(this.parentGroup, this.config);
    }

    private getControlFromFormGroup(group: FormGroup, config: DynamicField): AbstractControl {
        let control = group.get([config.formGroup, config.name]);
        if (!control) {
            control = group.get(config.name);
        }
        return control;
    }

    getGroup(): FormGroup {
        return this.getGroupFromConfig(this.parentGroup, this.config);
    }

    private getGroupFromConfig(parentGroup: FormGroup, config: DynamicField): FormGroup {
        let group: FormGroup = config.formGroup ? <FormGroup>parentGroup.get([config.formGroup]) : parentGroup;
        return group;
    }

    setRequired(required: boolean): void {
        this.config.setRequired(required);
        this.getControl().setValidators(this.config.validation.map((v) => <ValidatorFn>v));
        this.getControl().updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }

    getMaxLength(): string {
        return this.config?.validators?.maxlength?.toString() ?? '';
    }

    getMaxLengthValue(): number {
        return this.config?.validators?.maxlength ?? 0;
    }

    getMaxLengthText(): string {
        const control = this.getControl();
        return `${control.value && control.value.length ? control.value.length : 0}/${this.config.validators.maxlength}`;
    }

    hasMaxLength(): boolean {
        return this.config?.validators?.maxlength ? true : false;
    }

    showRequired(): boolean {
        return this.config?.validators?.required && this.config?.validators?.showRequired;
    }

    showOptional(): boolean {
        return !this.config?.validators?.required && this.config?.validators?.showOptional;
    }

    hasError(error?: string): boolean {
        const control = this.getControl();
        if (error) {
            return control.hasError(error) && (error === 'maxlength' || control.touched);
        } else {
            return control.errors && (control.touched || control.errors.maxlength);
        }
    }

    get errorMessage(): string {
        const control = this.getControl();
        const errors = control?.errors;
        if (!(errors && control?.touched)) {
            return '';
        }
        const message = Object.keys(errors)
            .map((key) => this.getErrorMessageFromKey(key, errors))
            .filter((message) => message)
            .join(', ');

        return message;
    }

    protected getErrorMessageFromKey(key: string, errors: ValidationErrors): string {
        const customErrorMessageHandler = this.config.errorMessageHandler ?? this.moduleErrorMessageHandler;
        return CustomFormValidation.getErrorMessageFromKey(key, errors, this.config, customErrorMessageHandler);
    }
}
