import {
    PreScreeningDynamicControls,
    IPreScreeningDynamicControlsParameters,
} from '@model/form-controls/pre-screening.form-controls';
import { IPreScreening } from '@model/interfaces/pre-screening';
import { DynamicField, DynamicFieldTypes, DynamicFieldType, NumericInputTypes, SelectInputTypes, InputTypes, LabelPosition, LabelPositions } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';

export class PreScreeningDynamicControlsPartial extends PreScreeningDynamicControls {

    constructor(
        preScreeningPartial?: IPreScreening,
        additionalParameters?: IPreScreeningDynamicControlsParameters,
    ) {
        super(preScreeningPartial, additionalParameters);

        (<DynamicField>this.Form.Feet) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Feet',
            name: 'Feet',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(10)],
            validators: { required: true, max: 10 },
            value: preScreeningPartial.Height ? Math.floor(preScreeningPartial.Height / 12) : null,
        });

        (<DynamicField>this.Form.Inches) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Inches',
            name: 'Inches',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(12)],
            validators: { required: true, max: 12 },
            value: preScreeningPartial.Height ? preScreeningPartial.Height % 12 : null,
        });

        (<DynamicField>this.Form.Meters) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Meters',
            name: 'Meters',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Float,
                scale: 2,
            }),
            validation: [Validators.required, Validators.max(10)],
            validators: { required: true, max: 10 },
            value: preScreeningPartial.Height ? preScreeningPartial.Height : null,
        });

    }
}
