import { NgModule } from '@angular/core';
import { SharedModule } from '@common/shared.module';
import { VerifyEmailRoutingModule } from './verify-email-routing.module';

import { VerifyEmailComponent } from './verify-email.component';

@NgModule({
    declarations: [VerifyEmailComponent],
    exports: [],
    imports: [VerifyEmailRoutingModule, SharedModule],
    providers: [],
})
export class VerifyEmailModule { }
