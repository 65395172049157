import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IMedicalIssueType } from '@model/interfaces/medical-issue-type';

@Injectable()
export class MedicalIssueTypesService extends StaticMetaItemService<IMedicalIssueType> {
    constructor(public http: HttpClient) {
        super('MedicalIssueTypesService', 'Medical Issue Type', 'MedicalIssueTypeIds', '/options/medicalIssueTypes', http);
    }
}
