<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="My Account"></mt-page-title>
        </h2>
    </div>
</div>
<div *ngIf="donor && donor.Id > 0">
    <div class="row">
        <div class="col-md-6">
            <app-donor-basic-info [donor]="donor" [canEdit]="canEdit"></app-donor-basic-info>
            <br />
            <div *ngIf="canEdit && donor.DonorDocuments.length" class="miles-form padded">
                <h4>My Fact Sheet</h4>
                <button
                    type="button"
                    mt-doubleClickDisabled
                    [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                    (click)="downloadFactSheet()"
                    Class="btn btn-flat btn-success"
                >
                    Download
                </button>
            </div>
            <br />
            <app-common-addresses
                [canEdit]="canEdit"
                [address]="donorAddress"
                (onSave)="saveAddress($event)"
                (showDelete)="(false)"
            ></app-common-addresses>
            <br />
            <app-donor-phone [canEdit]="canEdit" [donor]="donor"></app-donor-phone>
            <br />
            <app-donor-documents *ngIf="donor.DonorHelloSignDocuments && donor.DonorHelloSignDocuments.length" [donor]="donor"></app-donor-documents>
            <br />
        </div>
        <div class="col-md-6">
            <app-donor-photos [donor]="donor" [canEdit]="canEdit" [canDelete]="canEditPhotos"></app-donor-photos>
            <br />
            <app-donor-ids [donorId]="donor.Id"></app-donor-ids>
            <br />
        </div>
    </div>
</div>
