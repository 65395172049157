import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '@mt-ng2/environment-module';

@Injectable()
export class BaseApiUrlInterceptor implements HttpInterceptor {
    constructor(private environmentService: EnvironmentService) {}

    /**
     * Intercept all requests and pre-pend the base url from the configuration file
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if the request starts with a slash, then it needs the base api url
        // prepended to the request's url
        if (request.url.startsWith('/')) {
            const dupReq = request.clone({
                url: this.buildUrl(request.url),
            });

            return next.handle(dupReq);
        }

        // else we can just skip this
        return next.handle(request);
    }

    buildUrl(url): string {
        if (url.includes('/api/')) {
            return `${this.environmentService.config.baseApiUrl}${url}`;
        } else {
            return `${this.environmentService.config.baseApiUrl}/api/v${this.environmentService.config.apiVersion}${url}`;
        }
    }
}
