import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPreScreening } from '@model/interfaces/pre-screening';
import { UnitsOfMeasurementEnums } from '@model/enums/units-of-measurement.enum';
import { IApplicationValidationDTO } from '@model/interfaces/custom/application-validation-dto';

@Injectable({
    providedIn: 'root',
})
export class PreScreeningService {
    private emptyApplicationPreScreening: IPreScreening = {
        Id: 0,
        BirthYear: null,
        Email: null,
        ConfirmEmail: null,
        Height: null,
        HeightUnitId: UnitsOfMeasurementEnums.FeetAndInches,
        Weight: null,
        WeightUnitId: UnitsOfMeasurementEnums.Pounds,
        GenderId: null,
        EducationCompletedTypeId: null,
        IsUsCitizenOrGreenCardHolder: null,
        CountryCode: null,
        StateCode: null,
        Province: null,
        HasStd: null,
        HasHiv: null,
        HasFamilyMedicalHistory: null,
        IsAdopted: null,
        HasBothOvaries: null,
        HasGivenBirth: null,
        HowManyLiveBirths: null,
        IsPregnantOrBreastFeeding: null,
        HasPreviouslyDonatedEggs: null,
        HasRegularPeriodsWithoutBirthControl: null,
        ReferralTypeId: null,
        OtherReferral: null,
        Complete: false,
        Submitted: false,
        Denied: false,
        DateCreated: new Date(),
        DateModified: null,
        Children: [],
    };

    preScreening: IPreScreening;

    constructor(public http: HttpClient) { }

    getEmptyApplicationPreScreening(): IPreScreening {
        return { ...this.emptyApplicationPreScreening };
    }

    savePreScreening(data: IPreScreening): Observable<IPreScreening> {
        return this.http.post<IPreScreening>(`/application-pre-screening/save`, data);
    }

    updatePreScreening(data: IPreScreening): Observable<IApplicationValidationDTO> {
        return this.http.post<IApplicationValidationDTO>(`/application-pre-screening/update`, data);
    }
}
