<p>
    Hello,
</p>
<p>We truly appreciate your interest in our Egg Donor Program.
</p>
<p>
    Unfortunately, based on the information you provided, you are ineligible to become an egg donor with The
    World Egg and Sperm
    Bank at this time.
</p>
<p>
    We are unable to disclose the specific reason as to why your application was
    denied but, generally the reasons are due to regulations we must follow.
</p>
<p>
    Please note that this brief pre-screening application does not indicate that you are unhealthy
    or should have any health concerns.
</p>
<p>
    Thank you for your interest in our Egg Donor Program.
</p>
<p>
    Sincerely,
</p>
<p>
    The World Egg and Sperm Bank Donor Team
    <br />
    TWESB Phone/Email:<br />
    <a href='+1-877-331-2427'>+1-877-331-2427</a><br />
    <a href='+1-602-678-1906'>Arizona +1-602-678-1906</a><br />
    <a href='Donor@twesb.com'>Donor@twesb.com</a><br />
</p>