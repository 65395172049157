<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && donor.Id > 0 && controls"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Phone
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <app-dynamic-view [config]="[controls.View.ApplicantPhoneNumber]"></app-dynamic-view>
</div>
<div *ngIf="isEditing && controls" class="miles-form padded">
    <h4>Phone</h4>
    <dynamic-field [field]="controls.Form.ApplicantPhoneNumber" (controlCreated)="phoneControl = $event"></dynamic-field>
    <button
        type="button"
        mt-doubleClickDisabled
        [(doubleClickIsDisabled)]="doubleClickIsDisabled"
        Class="btn btn-flat btn-success"
        (click)="formSubmitted()"
    >
        Save
    </button>
    <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
        Cancel
    </button>
</div>
