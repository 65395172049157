import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeService } from './unsubscribe.service';
import { IAuthUser } from '@model/interfaces/auth-user';
import { getIdFromRoute } from '../../admin-portal/common/libraries/get-id-from-route.library';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-unsubscribe',
    styles: [
        `
            .fa-google {
                padding-right: 10px;
            }
        `,
    ],
    templateUrl: './unsubscribe.component.html',
})
export class UnsubscribeComponent implements OnInit {
    doubleClickIsDisabled = false;
    donorId: number;
    authUser: IAuthUser;
    @ViewChild('honeypot') honeypot: ElementRef;
    pageLoadTime: Date;

    unsubscribing = true;
    badInfo = false;
    unsubscribeSuccess = false;
    unsubscribeError = false;
    emailAddress = 'error: email address not found';
    emailAddressParam: string;

    constructor(
        private unsubscribeService: UnsubscribeService,
        private notificationsService: NotificationsService,
        private router: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.pageLoadTime = new Date();
        this.unsubscribing = true;
        ///
        /// Fetch the donor Id #
        ///
        this.donorId = getIdFromRoute(this.router, 'donorId');

        ///
        /// Fetch the e-mail address of the donor from the Url - this allows us to perform limited validation.  A malicious actor would need to have both the
        /// donor Id # AND their e-mail address to unsubscribe the donor.
        ///
        this.emailAddressParam = decodeURI(this.router.snapshot.paramMap.get('email'));
        ///
        /// Fetch the authorized user associated with this donor Id
        ///
        this.unsubscribeService.getAuthUser(this.donorId, this.emailAddressParam).subscribe(
            ((result) => {
                this.authUser = result;

                if (this.authUser && this.authUser.Users && this.authUser.Users.length > 0) {
                    this.emailAddress = this.authUser.Users[0].Email;

                    if (this.emailAddress.toLowerCase() !== this.emailAddressParam.toLowerCase()) {
                        this.badInfo = true;
                        this.notificationsService.error('This e-mail address provided does not match the address we have on file.  Cannot unsubscribe.');
                    }
                } else {
                    this.badInfo = true;
                }
            }),
            ((error) => {
                this.badInfo = true;
                this.notificationsService.error('We were unable to find a donor record matching the donor # provided.  Cannot unsubscribe.');
            }),
        );
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    unsubscribeUser(): void {
        this.unsubscribing = true;
        this.unsubscribeService.ubsubscribeDonor(this.authUser).subscribe(
            (ok) => {
                if (ok) {
                    this.unsubscribing = false;
                    this.unsubscribeSuccess = true;
                    this.notificationsService.success('Unsubscribed Successfully');
                } else {
                    this.unsubscribing = false;
                    this.unsubscribeSuccess = false;
                    this.unsubscribeError = true;
                    this.notificationsService.error('We were unable to unsubscribe you. Please try again later.');
                }
            },
            (error) => {
                this.unsubscribing = false;
                this.unsubscribeError = true;
                this.notificationsService.error('We were unable to unsubscribe you. Please try again later.');
            },
        );
    }
}
