import { Injectable } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { BehaviorSubject } from 'rxjs';

import { CookieService } from 'ngx-cookie';

import { IGoogleProfile } from '../libraries/google-sign-in.library';

@Injectable()
export class LoginService {
    private _googleUserCookieKey = 'breck-google-user-cookie';

    public signedInGoogleUser: BehaviorSubject<IGoogleProfile> = new BehaviorSubject(null);

    public constructor(private cookieService: CookieService, private authService: AuthService) {
        this.initialize();
    }

    private initialize(): void {
        // set signedInGoogleUser from cookie
        const cookieValue = this.cookieService.get(this._googleUserCookieKey);
        if (cookieValue) {
            this.signedInGoogleUser.next(JSON.parse(cookieValue));
        }

        // watch authService for currentUser
        this.authService.currentUser.subscribe((user) => {
            // if user is empty or has user Id of 0, then that is a logout
            if (!user?.Id) {
                // so if signedInGoogleUser is set, we need to clear it
                if (this.signedInGoogleUser.getValue()) {
                    this.signedInGoogleUser.next(null);
                }
            }
        });

        this.signedInGoogleUser.subscribe((googleUser) => {
            this.handleGoogleUserCookie(googleUser);
        });
    }

    private handleGoogleUserCookie(googleUser: IGoogleProfile): void {
        if (googleUser) {
            this.cookieService.put(this._googleUserCookieKey, JSON.stringify(googleUser));
        } else {
            this.cookieService.remove(this._googleUserCookieKey);
        }
    }
}
