import { Component } from '@angular/core';

@Component({
    selector: 'application-denied-message',
    styles: [
        `
            p {
                padding-top:8px!important;
                padding-bottom:8px!important;
                font-size:16px!important;
            }
        `,
    ],
    templateUrl: './application-denied-message.component.html',
})
export class ApplicationDeniedMessage {
}
