import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IMedicalIssueRelationshipType } from '@model/interfaces/medical-issue-relationship-type';

@Injectable()
export class MedicalIssueRelationshipTypesService extends StaticMetaItemService<IMedicalIssueRelationshipType> {
    constructor(public http: HttpClient) {
        super(
            'MedicalIssueRelationshipTypesService',
            'Medical Issue Relationship Type',
            'MedicalIssueRelationshipTypeIds',
            '/options/medicalIssueRelationshipTypes',
            http,
        );
    }
}
