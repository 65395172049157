import { BehaviorSubject, PartialObserver, Subscribable, Unsubscribable } from 'rxjs';

/**
 * used to replace a behavior subject with something that does not have the ability
 * to change the internal value, since it is missing the next function.
 */
export class BehaviorSubjectWithoutNext<T> implements Subscribable<T> {
    private _originalSubject: BehaviorSubject<T>;

    get value(): T {
        return this.getValue();
    }

    constructor(behaviorSubject: BehaviorSubject<T>) {
        this._originalSubject = behaviorSubject;
    }

    subscribe(observer?: PartialObserver<T>): Unsubscribable;
    subscribe(next: null, error: null, complete: () => void): Unsubscribable;
    subscribe(next: null, error: (error: any) => void, complete?: () => void): Unsubscribable;
    subscribe(next: (value: T) => void, error: null, complete: () => void): Unsubscribable;
    subscribe(next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): Unsubscribable;
    subscribe(next?: any, error?: any, complete?: any): Unsubscribable {
        return this._originalSubject.subscribe(next, error, complete);
    }

    unsubscribe(): void {
        this._originalSubject.unsubscribe();
    }

    get closed(): boolean {
        return this._originalSubject.closed;
    }

    getValue(): T {
        let value = this._originalSubject.getValue();
        // break reference if has value
        if (value) {
            value = JSON.parse(JSON.stringify(value));
        }
        return value;
    }
}
