import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IApplicationIssue } from '@model/interfaces/application-issue';

export const emptyApplicationIssue: IApplicationIssue = {
    Complete: null,
    Submitted: null,
    CurrentMedications: null,
    DateCreated: new Date(),
    HasSurgeries: null,
    Id: 0,
    IsTakingMedication: null,
    OtherIssues: null,
    Surgeries: null,
    BirthControlTypeId: null,
    OtherBirthControlMethod: null,
};

@Injectable({
    providedIn: 'root',
})
export class ApplicationIssueService extends BaseService<IApplicationIssue> {
    constructor(public http: HttpClient) {
        super('/donor-portal/application-issues', http);
    }

    getEmptyApplicationIssue(): IApplicationIssue {
        return { ...emptyApplicationIssue };
    }
}
