import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthConfig } from '../libraries/auth-config.library';
import { CacheBusterIgnoreHandler } from '../models/auth-module-config';

@Injectable()
export class CacheBusterInterceptor implements HttpInterceptor {
    constructor(private authConfig: AuthConfig) {}

    /**
     * Interceptor adds headers to keep IE 11 from cacheing get requests.
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

        if (!isIEOrEdge) {
            return next.handle(request);
        }

        const cacheBusterIgnoreHandler: CacheBusterIgnoreHandler = this.authConfig.eventHandlers.cacheBusterIgnoreHandler;
        if (cacheBusterIgnoreHandler) {
            const ignoreCacheBuster = cacheBusterIgnoreHandler(request);
            if (ignoreCacheBuster) {
                return next.handle(request);
            }
        }

        const httpRequest = request.clone({
            setHeaders: {
                'Cache-Control': 'no-cache',
                Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
                Pragma: 'no-cache',
            },
        });

        return next.handle(httpRequest);
    }
}
