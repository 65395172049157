<div class="miles-editable miles-card padded" *ngIf="!isEditing && donor.Id > 0" [style.cursor]="canEdit ? 'pointer' : 'default'">
    <h4 class="heading-with-button">
        Photos
        <button class="btn btn-sm" *ngIf="canEdit" type="button" (click)="isEditing = true">
            <i class="fa fa-upload fa-lg" style="cursor: pointer" aria-hidden="true"></i>
        </button>
    </h4>
    <div *ngIf="!donor.DonorImages.length">
        <p><em>No Photos Uploaded</em></p>
    </div>
    <br />
    <div class="img-container">
        <div *ngFor="let photo of donor.DonorImages; let i = index" class="img-box">
            <div class="img">
                <img *ngIf="useLocalImages" src="{{ localimagesPath }}thumb-{{ photo.ImagePath }}" alt="Donor image" />
                <img
                    *ngIf="!useLocalImages"
                    src="{{ imagesPath }}/static/photos/{{ donor.DonorId }}/large-thumbs/{{ photo.Image.FileName }}"
                    fallback="{{ imagesPath }}/static/photos/{{ donor.DonorId }}/large-thumbs/{{ photo.Image.FileName | fileextension:'jpg'}}"
                    alt="Donor Image Not Found"
                />
                <button *ngIf="canDelete" type="button" class="trash-button btn btn-sm btn-danger fa fa-times" (click)="deletePhoto(photo, $event)"></button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isEditing" class="miles-card padded">
    <h4 class="heading-with-button">
        Photos
        <button class="btn btn-sm btn-primary" *ngIf="canEdit" type="button" (click)="cancelUpload()">
            <i class="fa fa-times fa-lg" style="cursor: pointer" aria-hidden="true"></i>
        </button>
    </h4>
    <br />
    <photo-uploader (onQueueChange)="setPhotosForUpload($event)"></photo-uploader>
    <div class="text-center">
        <button type="button" class="btn button--secondary" (click)="cancelUpload()">Cancel</button>
        <button
            type="button"
            class="btn button--primary"
            (click)="tryUploadDocuments()"
            mt-doubleClickDisabled
            (doubleClickIsDisabled)="(doubleClickIsDisabled)"
            [disabled]="!photosForUpload.length"
        >
            Upload
        </button>
    </div>
</div>
