import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IApplicationIntroduceYourself } from '@model/interfaces/application-introduce-yourself';
import { IApplicationIntroduceYourselfDynamicControlsParameters } from '@model/form-controls/application-introduce-yourself.form-controls';
import { ApplicationIntroduceYourselfDynamicControlsPartial } from '@model/partials/application-introduce-yourself-partial.form-controls';

export class IntroduceYourselfDynamicConfig<T extends IApplicationIntroduceYourself> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private introduceYourself: IApplicationIntroduceYourself, private configControls?: string[], private additionalParameters?: IApplicationIntroduceYourselfDynamicControlsParameters) {
        super();
        const dynamicControls = new ApplicationIntroduceYourselfDynamicControlsPartial(this.introduceYourself, this.additionalParameters);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'TalentsAbilities',
                'Favorites',
                'CharacterDescription',
                'WhyInterestedInBecomingEggDonor',
                'Goals',
                'ExerciseRoutine',
                'LanguagesSpoken',
                'Complete',
                'Submitted',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
