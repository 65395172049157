import { DonorBasicInfoDynamicControls, IDonorBasicInfoDynamicControlsParameters } from '@model/form-controls/donor-basic-info.form-controls';
import { IDonorBasicInfo } from '@model/interfaces/donor-basic-info';
import { DynamicField, InputTypes, LabelPositions, DynamicLabel } from '@mt-ng2/dynamic-form';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { IReferralType } from '@model/interfaces/referral-type';

export class DonorBasicInfoDynamicControlsPartial extends DonorBasicInfoDynamicControls {
    constructor(
        weightUnits?: IUnitsOfMeasurement[],
        referralTypes?: IReferralType[],
        donorbasicinfoPartial?: IDonorBasicInfo,
        additionalParameters?: IDonorBasicInfoDynamicControlsParameters,
    ) {
        super(donorbasicinfoPartial, additionalParameters);

        (<DynamicLabel>this.View.ApplicantPhoneNumber).label = 'Phone';
        (<DynamicLabel>this.View.ApplicantPhoneNumber).type.inputType = InputTypes.Phone;
        (<DynamicField>this.Form.ApplicantPhoneNumber).labelPosition = { position: LabelPositions.Hidden, colsForLabel: null };
        (<DynamicField>this.Form.ApplicantPhoneNumber).type.inputType = InputTypes.Phone;
    }
}
