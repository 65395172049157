import { NgModule } from '@angular/core';

import { DonorRoutingModule } from './donor-routing.module';

import { DonorDetailComponent } from './donor-detail/donor-detail.component';

import { SharedModule } from '@common/shared.module';
import { AuthEntityModule } from '../auth-entity/auth-entity.module';
import { DonorBasicInfoComponent } from './donor-basic-info/donor-basic-info.component';
import { DonorPhotosComponent } from './donor-photos/donor-photos.component';
import { DonorPhoneComponent } from './donor-phone/donor-phone.component';
import { DonorDocumentComponent } from './donor-documents/donor-document.component';
import { ModalModule } from '@mt-ng2/modal-module';
import { DonorIdsService } from './donor-ids/donor-ids.service';
import { DonorIdsComponent } from './donor-ids/donor-ids.component';

@NgModule({
    declarations: [DonorPhotosComponent, DonorPhoneComponent, DonorDetailComponent, DonorBasicInfoComponent, DonorDocumentComponent, DonorIdsComponent],
    imports: [SharedModule, DonorRoutingModule, AuthEntityModule, ModalModule],
})
export class DonorModule {}
