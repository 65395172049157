<div *ngIf="formCreated">
    <form class="miles-form padded row application-section" [formGroup]="familyHistoryForm">
        <div class="input-scaffold__group" [style.width]="'50%'" *ngIf="isSibling">
            <div class="input-scaffold">
                <label class="input-label">
                    <span class="input-scaffold__label">Full Sibling? <span style="color: red"> *</span></span>
                </label>
                <dynamic-field [field]="abstractFamilyHistoryControls.IsFullSibling" [form]="familyHistoryForm"> </dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="isHalfSibling">
                <label class="input-label">
                    <span class="input-scaffold__label">Mother or Father's Side? <span style="color: red"> *</span></span>
                </label>
                <dynamic-field [field]="abstractFamilyHistoryControls.HalfSiblingType" [form]="familyHistoryForm"> </dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <label class="input-label">
                    <span class="input-scaffold__label">Deceased?<span style="color: red"> *</span></span>
                </label>
                <dynamic-field [field]="abstractFamilyHistoryControls.Deceased" [form]="familyHistoryForm"> </dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="controlHasValue('Deceased')">
                <div class="input-scaffold" *ngIf="optionIsSelected('Deceased')">
                    <label class="input-label">
                        <span class="input-scaffold__label">Age At Death<span style="color: red"> *</span></span>
                    </label>
                    <dynamic-field [field]="abstractFamilyHistoryControls.AgeAtDeath" [form]="familyHistoryForm"> </dynamic-field>
                </div>
                <div class="input-scaffold" *ngIf="optionIsSelected('Deceased')">
                    <label class="input-label">
                        <span class="input-scaffold__label">Cause Of Death<span style="color: red"> *</span></span>
                    </label>
                    <dynamic-field [field]="abstractFamilyHistoryControls.CauseOfDeath" [form]="familyHistoryForm"> </dynamic-field>
                </div>
                <div class="input-scaffold" *ngIf="!optionIsSelected('Deceased')">
                    <dynamic-field [field]="abstractFamilyHistoryControls.Age" [form]="familyHistoryForm"> </dynamic-field>
                </div>
            </div>
        </div>
        <div class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label">Height</span>
            </label>
            <dynamic-field
                [field]="abstractFamilyHistoryControls.SelectedHeightUnit"
                [form]="familyHistoryForm"
                (valueChanges)="selectedHeightUnitId = $event"
            ></dynamic-field>
        </div>
        <div *ngIf="!usingMetric()" class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractFamilyHistoryControls.Feet" [form]="familyHistoryForm"> </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractFamilyHistoryControls.Inches" [form]="familyHistoryForm"> </dynamic-field>
            </div>
        </div>
        <div *ngIf="usingMetric()" class="input-scaffold" [style.width]="'50%'">
            <dynamic-field [field]="abstractFamilyHistoryControls.Meters" [form]="familyHistoryForm"> </dynamic-field>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <label class="input-label">
                    <span class="input-scaffold__label">Weight </span>
                </label>
                <dynamic-field
                    [field]="abstractFamilyHistoryControls.SelectedWeightUnit"
                    [form]="familyHistoryForm"
                    (valueChanges)="selectedWeightUnitId = $event"
                ></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractFamilyHistoryControls.Weight" [form]="familyHistoryForm"> </dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractFamilyHistoryControls.EyeColorId" [form]="familyHistoryForm"> </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractFamilyHistoryControls.HairColorId" [form]="familyHistoryForm"> </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractFamilyHistoryControls.HairTextureId" [form]="familyHistoryForm"> </dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractFamilyHistoryControls.SkinTypeId" [form]="familyHistoryForm"> </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractFamilyHistoryControls.RaceId" [form]="familyHistoryForm"> </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractFamilyHistoryControls.EducationCompletedTypeId" [form]="familyHistoryForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractFamilyHistoryControls.Occupation" [form]="familyHistoryForm"> </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractFamilyHistoryControls.Skills" [form]="familyHistoryForm"> </dynamic-field>
            </div>
        </div>

        <div class="input-scaffold">
            <dynamic-field [field]="abstractFamilyHistoryControls.PlaceOfBirth" [form]="familyHistoryForm"> </dynamic-field>
        </div>
    </form>
</div>
