<ng-container>
    <div class="miles-card padded" *ngIf="!showUploadArea">
        <h4>Photo Ids</h4>
        <div *ngIf="documentArray != null && documentArray.length">
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table scrollable-table">
                            <thead>
                                <tr class="no-pointer">
                                    <th>
                                        <b>Name</b>
                                    </th>
                                    <th>
                                        <b>Date Uploaded</b>
                                    </th>
                                    <th>
                                        <b>Download</b>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let doc of documentArray; let i = index" class="no-pointer">
                                    <td *ngIf="doc && doc.Document.Name">
                                        <span> {{ doc.Document.Name }}</span>
                                    </td>
                                    <td *ngIf="doc && doc.Document.DateUpload">
                                        <span> {{ doc.Document.DateUpload | date: 'short' }}</span>
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            (click)="downloadDocument(i, doc.DocumentId)"
                                            class="btn btn-sm fa fa-download fa-lg pointer"
                                        ></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <em *ngIf="!documentArray.length">No Documents</em>

        <div class="fab-wrap">
            <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="showUploadArea = true">
                <span class="fa fa-plus"></span>
            </button>
        </div>
    </div>
    <div [hidden]="!showUploadArea" class="miles-form padded">
        <mt-document
            [allowedMimeType]="allowedDocumentTypes"
            [maxFileSize]="maxFileSize"
            (onAfterAddingFile)="save($event)"
            (onAddingFileFailed)="error($event)"
        ></mt-document>
        <br />
        <button type="button" class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
    </div>
</ng-container>
