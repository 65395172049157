import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IReferralType } from '@model/interfaces/referral-type';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ReferralTypeService extends StaticMetaItemService<IReferralType> {
    constructor(public http: HttpClient) {
        super('ReferralTypeService', 'Referral Type', 'ReferralTypeIds', '/options/referralTypes', http);
    }

    getActive(): Observable<IReferralType[]> {
        return this.http.get<IReferralType[]>(`/options/referralTypes/active`).pipe(
            tap((referralTypes) => {
                this.items = referralTypes;
            }),
        );
    }
}
