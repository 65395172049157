import { Component, OnInit, Injector, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IEntity, MetaItemListDefinition, IIsMetaItemService } from '@mt-ng2/base-service';

import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IIsSharedEntities, ISharedEntitiesComponentConfig } from '../interfaces/shared-entities';
import { BlankSharedEntitiesConfig, AdditionalSharedEntityTypes } from '../classes/shared-entities.library';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { IAdditionalSharedEntityConfig } from '../interfaces/additional-shared-entities';
import { Observable, of, forkJoin } from 'rxjs';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

@Component({
    selector: 'shared-entities-info',
    template: `
        <div class="row">
            <div class="col-md-6">
                <div
                    class="miles-editable miles-card padded"
                    *ngIf="!isEditing && entity.Id > 0"
                    (mouseenter)="isHovered = true"
                    (mouseleave)="isHovered = false"
                    (click)="edit()"
                    [style.cursor]="_canEdit ? 'pointer' : 'default'"
                >
                    <h4>
                        {{ _sharedEntitiesConfig.EntityName }} Info
                        <span class="pull-right" *ngIf="_canEdit" [hidden]="!isHovered">
                            <i class="fa fa-lg fa-edit"></i>
                        </span>
                    </h4>
                    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
                </div>
                <div *ngIf="isEditing" class="miles-form padded">
                    <h4>{{ _sharedEntitiesConfig.EntityName }} Info</h4>
                    <app-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="save($event)">
                        <button type="submit" Class="btn btn-flat btn-success">Save</button>
                        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
                    </app-dynamic-form>
                </div>
            </div>
            <div class="col-md-6">
                <ng-container *ngFor="let sharedEntity of additionalSharedEntities">
                    <app-common-phone
                        *ngIf="checkType(sharedEntity.type, 'Phone')"
                        [PhoneArray]="getAdditionalSharedEntities(sharedEntity)"
                        (onSave)="saveAdditionalSharedEntity($event, sharedEntity)"
                        [canEdit]="_canEdit"
                    ></app-common-phone>
                    <app-common-addresses
                        *ngIf="checkType(sharedEntity.type, 'Address')"
                        [addressContainerArray]="getAdditionalSharedEntities(sharedEntity)"
                        (onSave)="saveAdditionalSharedEntity($event, sharedEntity)"
                        (onDelete)="deleteAdditionalSharedEntity($event, sharedEntity)"
                        [canEdit]="_canEdit"
                        [canAdd]="_canEdit"
                        [max]="sharedEntity.max"
                        [useMaxAsLimit]="sharedEntity.useMaxAsLimit"
                        [entityName]="sharedEntity.componentName"
                    ></app-common-addresses>
                    <br />
                </ng-container>
            </div>
        </div>
        <br />
        <button *ngIf="!isEditing && entity.Id > 0" type="button" Class="btn btn-flat btn-default" (click)="closeClick()">Close</button>
    `,
})
export class SharedEntitiesInfoComponent implements OnInit {
    entityId: number;
    parentId: number;
    isEditing: boolean;
    isHovered: boolean;

    private _entity: IEntity = { Id: 0 };
    get entity(): IEntity {
        return this._entity;
    }
    set entity(value: IEntity) {
        this._entity = value;
        this.config = this._sharedEntitiesConfig.getDynamicFormConfig(value);
        this.additionalSharedEntities.forEach((item) => (item.value = null));
    }

    _canEdit: boolean;
    @Input('canEdit')
    set canEdit(value: boolean) {
        this._canEdit = value;
    }

    private _service: IIsSharedEntities<IEntity>;
    public get service(): IIsSharedEntities<IEntity> {
        if (this._service) {
            return this._service;
        }
        return null;
    }
    public set service(value: IIsSharedEntities<IEntity>) {
        this._service = value;
    }

    _sharedEntitiesConfig: ISharedEntitiesComponentConfig<IEntity> = new BlankSharedEntitiesConfig();
    get additionalSharedEntities(): IAdditionalSharedEntityConfig[] {
        return this._sharedEntitiesConfig.AdditionalSharedEntities;
    }
    config: IDynamicFormConfig = { formObject: [], viewOnly: [] };

    constructor(
        private route: ActivatedRoute,
        private injector: Injector,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.setVariables();
    }

    setVariables(): void {
        const config: IEntityRouteConfig = <IEntityRouteConfig>this.route.parent.snapshot.data;
        this.parentId = +this.route.parent.snapshot.paramMap.get(config.entityIdParam);
        this.canEdit = this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]);
        let sharedEntityConfig = <any>config;
        let componentPath = this.router.url.split('/')[this.router.url.split('/').length - 2];
        let sharedEntity = sharedEntityConfig.sharedEntities.find((item) => item.path === componentPath);
        this.service = this.injector.get(sharedEntity.service);
        this._sharedEntitiesConfig = new sharedEntity.config();
        this.entityId = +this.route.snapshot.paramMap.get(sharedEntity.entityIdParam);
        this._canEdit = config?.claimType ? this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]) : false;
        this.getInitilizationProcesses().subscribe((answer) => {
            this._sharedEntitiesConfig.setMetaItemLists(answer);
            if (this.entityId > 0) {
                this.getEntity();
            } else {
                this.entity = { ...this._sharedEntitiesConfig.EmptyEntity };
                this.isEditing = true;
            }
        });
    }

    getInitilizationProcesses(): Observable<MetaItemListDefinition[]> {
        let observables = [];
        let metaItemServices = this._sharedEntitiesConfig.MetaItemServices;
        for (let mis of metaItemServices) {
            let metaItemService = <IIsMetaItemService>this.injector.get(mis);
            observables.push(metaItemService.MetaItemListServiceInitilizer.getItems());
        }
        if (observables.length === 0) {
            return of([]);
        }
        return forkJoin<MetaItemListDefinition>(observables);
    }

    getEntity(): void {
        this.service.getEntity(this.parentId, this.entityId).subscribe((entity) => {
            this.entity = entity;
        });
    }

    edit(): void {
        if (this._canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.entity.Id === 0) {
            this.router.navigate(['../'], { relativeTo: this.route });
        } else {
            this.isEditing = false;
        }
    }

    closeClick(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    save(entityForm): void {
        if (entityForm.valid) {
            let entity = this._sharedEntitiesConfig.getFormValues(this.entity, entityForm.value);
            this.saveEntity(entity);
        } else {
            this.notificationsService.error('Save Failed');
            markAllFormFieldsAsTouched(entityForm);
        }
    }

    private saveEntity(entity: IEntity): void {
        this.service.saveEntity(this.parentId, entity).subscribe(
            (success) => {
                this.notificationsService.success(`${this._sharedEntitiesConfig.EntityName} Saved Successfully`);
                if (this.entityId === 0) {
                    this.entityId = success;
                }
                this.getEntity();
                this.isEditing = false;
            },
            (error) => this.notificationsService.error('Save Failed'),
        );
    }

    checkType(type: AdditionalSharedEntityTypes, typeNameToCheck: string): boolean {
        return type === AdditionalSharedEntityTypes[typeNameToCheck];
    }

    getAdditionalSharedEntities(sharedentity: IAdditionalSharedEntityConfig): any[] {
        if (!sharedentity.value) {
            sharedentity.value = sharedentity.accessorFunction(this.entity);
        }
        return sharedentity.value;
    }

    saveAdditionalSharedEntity(additionalEntity: any, sharedentity: IAdditionalSharedEntityConfig): void {
        (<any>this.service)[sharedentity.saveMethodName](this.parentId, this.entityId, additionalEntity).subscribe(
            (success) => {
                this.notificationsService.success(`${sharedentity.componentName} Saved Successfully`);
                this.getEntity();
                this.isEditing = false;
            },
            (error) => this.notificationsService.error('Save Failed'),
        );
    }

    deleteAdditionalSharedEntity(additionalEntity: any, sharedentity: IAdditionalSharedEntityConfig): void {
        (<any>this.service)[sharedentity.deleteMethodName](this.parentId, this.entityId, additionalEntity).subscribe(
            (success) => {
                this.notificationsService.success(`${sharedentity.componentName} Deleted Successfully`);
                this.getEntity();
                this.isEditing = false;
            },
            (error) => this.notificationsService.error('Save Failed'),
        );
    }
}
