import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDonor } from '@model/interfaces/donor';
import { DonorService } from '../donor.service';
import { DonorBasicInfoDynamicControlsPartial } from './donor-phone-basic-info-partial.form-controls';

@Component({
    selector: 'app-donor-phone',
    templateUrl: './donor-phone.component.html',
})
export class DonorPhoneComponent implements OnInit {
    @Input() donor: IDonor;
    @Input() canEdit: boolean;

    isEditing = false;
    controls: DonorBasicInfoDynamicControlsPartial;
    doubleClickIsDisabled = false;
    phoneControl: AbstractControl;

    constructor(private donorService: DonorService, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.controls = new DonorBasicInfoDynamicControlsPartial(null, null, this.donor.DonorBasicInfo, null);
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(): void {
        if (this.phoneControl.valid) {
            this.donor.DonorBasicInfo.ApplicantPhoneNumber = this.phoneControl.value;
            this.saveDonor();
        } else {
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private saveDonor(): void {
        this.donorService
            .savePhone(this.donor.DonorBasicInfo)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe(() => {
                this.success();
                this.donor.DonorBasicInfo.ApplicantPhoneNumber = this.phoneControl.value;
                this.controls.View.ApplicantPhoneNumber.value = this.phoneControl.value;
            });
    }

    private success(): void {
        this.isEditing = false;
        this.donorService.emitChange(this.donor);
        this.notificationsService.success('Phone number updated successfully.');
    }
}
