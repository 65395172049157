import { INavModuleConfig } from '@mt-ng2/nav-module-config';
import { environment } from '../../environments/environment';
import { appPaths } from '../../default-routes/app-paths.library';

export const NavConfigOverride: INavModuleConfig = {
    hasKeyboardShortcutsModule: true,
    myProfilePath: appPaths.myProfile,
    showKeyboardShortcutsButton: true,
    siteName: environment.siteName || null,
    submitHelpUrl: environment.submitHelpUrl || null,
};
