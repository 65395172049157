import { Component, Input, Injector, ViewContainerRef, Renderer2, ComponentFactoryResolver, NgZone } from '@angular/core';

import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';

import { TypeAheadBaseComponent } from './type-ahead.base-component';

import { debounceTime } from 'rxjs/operators';
import { MatchingStrategy } from '@mt-ng2/type-ahead-control-config';

@Component({
    selector: '<mt-type-ahead>',
    template: `
        <ng-container *componentErrorHandler="errorHandler">
            <span *ngIf="!items">
                <input #inputElement type="text" [placeholder]="emptyText" class="form-control" />
            </span>
            <span *ngIf="items">
                <input
                    #inputElement
                    type="text"
                    [placeholder]="emptyText"
                    class="form-control"
                    autocorrect="off"
                    autocomplete="off"
                    autocapitalize="off"
                    role="combobox"
                    [formControl]="inputControl"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    (click)="onFocus()"
                    (keydown)="handleKeyDown($event)"
                />
            </span>
        </ng-container>
    `,
})
export class TypeAheadComponent extends TypeAheadBaseComponent {
    @Input() items: any[];
    /**
     * Defines the type of matching we will use to highlight text
     */
    @Input() matchingStrategy: MatchingStrategy;

    constructor(injector: Injector, vcr: ViewContainerRef, renderer: Renderer2, resolver: ComponentFactoryResolver, ngZone: NgZone) {
        super(injector, vcr, renderer, resolver, ngZone);
        this.errorHandler = new ComponentErrorHandler('TypeAheadComponent', '@mt-ng2/type-ahead-control');

        if (this.minimumCharactersToShow === undefined) {
            this.minimumCharactersToShow = 0;
        }

        this.subscriptions.add(
            this.inputControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
                if (value && value.trim().length >= this.minimumCharactersToShow) {
                    if (!this.isPopupOpen() && value && !(this.selected && this.getValue(this.selected) === value)) {
                        this.openPopup();
                    } else if (this._windowRef) {
                        this._windowRef.instance.searchText = value;
                        this._windowRef.instance.cdr.detectChanges();
                    }
                } else if (this.isPopupOpen()) {
                    this.closePopup();
                }
            }),
        );
    }

    onFocus(): void {
        super.focusMe();
        if (!this.selected && !this.minimumCharactersToShow) {
            this.openPopup();
        }
    }

    protected openPopup(): void {
        if (!this.isPopupOpen()) {
            const componentInputs = [{ Name: 'matchingStrategy', Value: this.matchingStrategy }];
            this._windowRef = this._popupService.open(null, null, componentInputs);
            this._windowRef.instance.items = [...this.items];
            this._windowRef.instance.searchText = this.inputControl.value;
            this._windowRef.instance.nameProperty = this.nameProperty;
            this._windowRef.instance.maxToShow = this.maxToShow;
            this._windowRef.instance.errorHandler = this.errorHandler;
            this._windowRef.instance.onSelection.subscribe((result: any) => {
                this.handleSelection(result);
                this.closePopup();
            });
            if (!this.isModal) {
                window.document.querySelector('body').appendChild(this._windowRef.location.nativeElement);
            } else {
                this.inputElement.nativeElement.after(this._windowRef.location.nativeElement);
            }
        }
    }
}
