import { ILoginResponse } from '../models/auth-service.models';
import { stitchCustomOptions } from './auth-service.library';

export interface ILoginToken {
    authUserId: number;
    claimValues: any;
    email: string;
    expires: Date;
    img: string;
    issued: Date;
    name: string;
    refreshId: string;
    remember: boolean;
    token: string;
    userId: number;
    customOptions: any;
}

export function mapToken(data: ILoginResponse, remember: boolean): ILoginToken {
    const image = data.UserDetails.ProfileImagePath || '';
    const claims = data.LoginResult.ClaimFlags || {};
    remember = remember || false;
    const token = {
        authUserId: data.LoginResult.AuthId,
        claimValues: claims,
        customOptions: stitchCustomOptions(data),
        email: data.UserDetails.Email,
        expires: data.LoginResult.ExpiresUtc,
        img: image,
        issued: data.LoginResult.IssuedUtc,
        name: data.UserDetails.Name,
        refreshId: data.LoginResult.RefreshTokenIdentifier,
        remember: remember,
        token: data.LoginResult.Jwt,
        userId: data.UserDetails.Id,
    };
    return token;
}
