export enum DonorDocumentTypes {
    FactSheet = 1,
    VitrificationReport = 2,
    Other = 3,
    Sema4GeneticReportRedacted = 11,
    DriversLicense = 12,
    Contract = 13,
    Accounting = 14,
    Clinical = 15,
    Sema4GeneticReportNotRedacted = 17,
    ScreeningReport = 18,
    ScreeningResult = 19,
    ScreeningScreening = 20,
    ScreeningContracts = 21,
    ScreeningOther = 22,
    PlanningStimReport = 23,
    PlanningStimResult = 24,
    PlanningStimScreening = 25,
    PlanningStimContracts = 26,
    PlanningStimOther = 27,
    RetrievalReport = 28,
    RetrievalResult = 29,
    RetrievalScreening = 30,
    RetrievalContracts = 31,
    RetrievalOther = 32,
    InventoryReport = 33,
    InventoryResult = 34,
    InventoryScreening = 35,
    InventoryContracts = 36,
    InventoryOther = 37,
}

export enum ClinicDocumentTypes {
    General = 4,
}

export enum UserDocumentTypes {
    General = 10,
}

export enum RecipientDocumentTypes {
    Accounting = 5,
    Certificate = 6,
    Clinical = 7,
    Contract = 7,
    Other = 9,
}
