import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { IDonor } from '@model/interfaces/donor';
import { DonorService } from '../donor.service';
import { DonorDynamicConfig } from '../donor.dynamic-config';
import { IUser } from '@model/interfaces/user';

@Component({
    selector: 'app-donor-basic-info',
    templateUrl: './donor-basic-info.component.html',
})
export class DonorBasicInfoComponent implements OnInit {
    @Input() donor: IDonor;
    @Input() canEdit: boolean;

    isEditing = false;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: DonorDynamicConfig<IUser>;
    doubleClickIsDisabled = false;

    get isNewDonor(): boolean {
        return this.donor && this.donor.Id ? false : true;
    }

    constructor(private donorService: DonorService, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new DonorDynamicConfig<IUser>(this.donor.User, null);

        this.config = this.formFactory.getForUpdate();
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.donor.User, form.value.User);
            this.saveDonor();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private saveDonor(): void {
        this.donorService
            .updateUser(this.donor.Id, this.donor.User)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe((answer) => {
                this.donor.User.Version = answer;
                this.success();
                this.isEditing = false;
            });
    }

    private success(): void {
        this.setConfig();
        this.isEditing = false;
        this.donorService.emitChange(this.donor);
        this.notificationsService.success('Donor saved successfully.');
    }
}
