export const appPaths = {
    account: 'account',
    adminAccess: 'adminaccess',
    application: 'application',
    forgotPassword: 'forgotpassword',
    forgotUsername: 'forgotusername',
    login: 'login',
    myProfile: 'users/my-profile',
    preScreening: 'prescreening',
    register: 'register',
    resetPassword: 'resetpassword',
    sign: 'sign',
    verifyEmail: 'verifyemail',
    verifyUser: 'verifyuser',
    unsubscribe: 'unsubscribe/:donorId',
};
