import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { IUserRoleRecommendedPage } from '../model/user-role-recommended-page';

@Injectable()
export class UserRoleRecommendedPageService extends BaseService<
    IUserRoleRecommendedPage
> {
    private emptyUserRoleRecommendedPage: IUserRoleRecommendedPage = {
        Id: 0,
        Route: '',
        Title: '',
        UserRoleId: null,
    };

    constructor(public http: HttpClient) {
        super('/userRoleRecommendedPages', http);
    }

    getEmptyUserRoleRecommendedPage(): IUserRoleRecommendedPage {
        return { ...this.emptyUserRoleRecommendedPage };
    }

    getPagesByUserRole(
        userRoleId: number,
    ): Observable<IUserRoleRecommendedPage[]> {
        return this.http.get<IUserRoleRecommendedPage[]>(
            `/userRoleRecommendedPages/role/${userRoleId}`,
        );
    }
}
