export enum ApplicationStatuses {
    PostRegistration = 1,
    Basics,
    PreliminaryHistory,
    MedicalIssues,
    WhoAreYou,
    FamilyHistory,
    WaiverFactsPhoto,
    Denied,
    Complete,
}
