import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AppointmentTypes } from '@model/enums/appointment-types.enum';
import { IDonor } from '@model/interfaces/donor';
import { AppStateService } from '../common/services/app-state.service';
import { DonorService } from '../donor-account/donor.service';
import { environment } from '../environments/environment';
import { DonorStatuses } from '../model/enums/donor-statuses.enum';

declare var Calendly;

@Component({
    selector: 'appointment-scheduling',
    styles: [``],
    template: `
    <div *ngIf="donor">
         <button type="button" class="btn button--primary text-center" (click)="showVirtualAppointmentScheduler()">
            {{ allowInPerson ? "Schedule Virtual Appointment" : "Schedule Appointment" }}
        </button>
        <button *ngIf="allowInPerson" type="button" class="btn button--primary text-center" (click)="showInPersonAppointmentScheduler()">
            Schedule In-Person Appointment
        </button>
    </div>
    `,
})
export class AppoinmentSchedulingComponent implements OnInit {
    @Input() allowInPerson = false;
    donor: IDonor;

    selectedAppointment: Date;

    constructor(private donorService: DonorService, private appStateService: AppStateService) {}

    // Listen for calendly events
    @HostListener('window:message', ['$event'])
    handleEvent(event: MessageEvent): void {
        if (this.isCalendlyEvent(event)) {
            if (event.data.event === 'calendly.event_scheduled') {
                this.appStateService.setStateToAppointmentScheduled();
                this.donorService.setDonorStatusId(this.donor.Id, DonorStatuses.InterviewScheduled).subscribe();
            }
        }
    }

    isCalendlyEvent(e): boolean {

         return e.data.event && e.data.event.indexOf('calendly') === 0;
    }

    showVirtualAppointmentScheduler(): void {
        this.showAppointmentScheduler(AppointmentTypes.Virtual);
    }

    showInPersonAppointmentScheduler(): void {
        this.showAppointmentScheduler(AppointmentTypes.InPerson);
    }

    showAppointmentScheduler(type: AppointmentTypes): void {
        const url = type === AppointmentTypes.Virtual ? environment.calendlyVirtualUrl : environment.calendlyInPersonUrl;
        Calendly.initPopupWidget({
            prefill: {
                customAnswers: {
                    a1: this.donor.DonorId.toString(),
                    a2: +this.donor.DonorBasicInfo.ApplicantPhoneNumber,
                },
                email: this.donor.User.Email,
                name: `${this.donor.User.FirstName} ${this.donor.User.LastName}`,
            },
            url: url,
        });
    }

    ngOnInit(): void {
        this.donorService.getDonorByUserId().subscribe((donor) => (this.donor = donor));
    }
}
