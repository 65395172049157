import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, HostListener, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, forkJoin, Subject } from 'rxjs';
import { ILoggedIn } from '@mt-ng2/auth-module';
import { debounceTime } from 'rxjs/operators';
import { IApplicationIssue } from '@model/interfaces/application-issue';
import { ApplicationIssueDynamicControlsPartial } from '@model/partials/application-issue-partial.form-controls';
import { IMedicalIssueType } from '@model/interfaces/medical-issue-type';
import { MedicalIssueOptionsService } from '@common/services/medical-issue-options.service';
import { ICurrentSectionAndStatus, ApplicationService } from '../../services/application.service';
import { MedicalIssueRelationshipTypesService } from '@common/services/medical-issue-relationship-types.service';
import { IApplicationIssuesMedicalIssue } from '@model/interfaces/application-issues-medical-issue';
import { IMedicalIssueRelationshipType } from '@model/interfaces/medical-issue-relationship-type';
import { MedicalIssueTypesService } from '@common/services/medical-issue-types.service';
import { FormSections } from '@model/enums/form-sections.enum';
import { IMetaItem } from '@model/interfaces/base';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import assignFormValues from '@common/assign-form-values.library';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { BirthControlTypes } from '@model/enums/birth-control-types.enum';
import { DonorService } from '../../../../admin-portal/donors/services/donor.service';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IssueSelectorComponent } from './issue-selector/issue-selector.component';

@Component({
    selector: 'medical-issues',
    templateUrl: './medical-issues.component.html',
})
export class MedicalIssuesComponent implements OnInit, OnDestroy {
    @Input() medicalIssues: IApplicationIssue;
    @Input() donor: ILoggedIn;
    @Input() denied: boolean;
    @Input() isMedicalHistoryComplete: Subject<boolean>;
    @Output('validateForm') validateForm: EventEmitter<any> = new EventEmitter<any>();

    // abstract controls
    abstractApplicationIssueControls: any;

    medicalIssueTypes: IMedicalIssueType[];
    applicationIssueMedicalIssues: IApplicationIssuesMedicalIssue[];
    medicalIssueRelationshipTypes: IMedicalIssueRelationshipType[];
    selectedHasSurgeries: number;
    selectedIsTakingMedication: number;

    medicalIssuesForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    medicalIssueOptions: IMedicalIssueType[];
    showOtherBirthControlMethod: boolean;
    medicalIssueMetaItems: IPersonalInformationMetaData;

    currentStatus: ICurrentSectionAndStatus = {
        formGroup: null,
        nextTab: 'tab-familyHistory',
        nextTabLabel: 'Family History?',
        routerLink: null,
        sectionId: FormSections.MedicalIssues,
        status: '',
    };

    subs = new Subscription();
    @ViewChildren(IssueSelectorComponent) issueSelectors: QueryList<IssueSelectorComponent>;

    constructor(
        private fb: FormBuilder,
        private applicationService: ApplicationService,
        private medicalIssueOptionsService: MedicalIssueOptionsService,
        private medicalIssueTypesService: MedicalIssueTypesService,
        private medicalIssueRelationshipTypesService: MedicalIssueRelationshipTypesService,
        private notificationsService: NotificationsService,
        private elementRef: ElementRef,
        private donorService: DonorService,
    ) { }

    @HostListener('window:beforeunload', ['$event'])
    warnOfUnsavedChanges(e): any {
        if (this.medicalIssuesForm.dirty && !this.denied) {
            e.returnValue = true;
            return false;
        }
    }

    ngOnInit(): void {
        forkJoin([
            this.donorService.getPersonalInformationMetaData(),
            this.medicalIssueRelationshipTypesService.getAll(),
            this.medicalIssueOptionsService.getItems(),
            this.medicalIssueTypesService.getItems(),
        ]).subscribe((forkJoinResponses) => {
            const [metaData, relationshipTypes] = forkJoinResponses;
            this.medicalIssueMetaItems = metaData as IPersonalInformationMetaData;
            this.medicalIssueRelationshipTypes = relationshipTypes as IMedicalIssueRelationshipType[];
            this.medicalIssueOptions = this.medicalIssueOptionsService.items;
            this.medicalIssueTypes = this.medicalIssueTypesService.items;
            this.applicationIssueMedicalIssues = this.medicalIssues.ApplicationIssuesMedicalIssues;
            this.selectedHasSurgeries = this.medicalIssues.HasSurgeries ? 1 : 2;
            this.selectedIsTakingMedication = this.medicalIssues.IsTakingMedication ? 1 : 2;
            this.currentStatus.status = this.medicalIssues.Complete ? 'Complete' : 'In Progress';
            this.createForm();
            this.showOtherBirthControlMethod = (this.medicalIssues.BirthControlTypeId === BirthControlTypes.Other);

            this.selectedIsTakingMedication = (this.medicalIssues.IsTakingMedication ? 1 : 0);

            this.currentStatus.status = this.medicalIssues.Submitted ? 'Submitted' : (this.medicalIssues.Complete ? 'Complete' : 'In Progress');
            if (this.medicalIssues.Complete) {
                this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
            }
        });
        this.subs.add(
            this.applicationService.applicationSaved$.subscribe((save) => {
                if (save !== null && this.medicalIssuesForm) {
                    markAllFormFieldsAsTouched(this.medicalIssuesForm);
                }
            }),
        );
        this.isMedicalHistoryComplete.subscribe((isComplete) => {
            if (!isComplete) {
                setTimeout(() => {
                    this.applicationService.scrollToFirstInvalidControl(this.elementRef);
                    this.notificationsService.error('You must fill out all required sections. Please scroll down to see what was missed.');
                }, 1000);
            } else {
                setTimeout(() => {
                    if (!this.medicalIssues.Submitted) {
                        this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
                        this.notificationsService.success('The form is complete. Please review and confirm your entries.');
                    }
                }, 1000);
            }
        });
    }

    noneApply(checkBox: any, medicalIssueType: IMedicalIssueType): void {
        const checked = checkBox.currentTarget.checked;
        const groupIssues = this.medicalIssueOptions.find((t) => t.Id === medicalIssueType.Id);
        const selectors = this.issueSelectors.toArray();
        if (checked) {
            if (groupIssues) {
                groupIssues.MedicalIssues.forEach((medicalIssue) => {
                    const selector = selectors.find((s) => s.applicationIssueMedicalIssue.MedicalIssueId === medicalIssue.Id);
                    if (selector) {
                        const applicationIssue = this.applicationIssueMedicalIssues.find((i) => i.MedicalIssueId === medicalIssue.Id);
                        applicationIssue.You = false;
                        applicationIssue.Mother = false;
                        applicationIssue.Father = false;
                        applicationIssue.Grandparent = false;
                        applicationIssue.Sibling = false;
                        applicationIssue.Children = false;
                        applicationIssue.None = true;
                        selector.SetNoneApply();
                        this.medicalIssueChanged();
                    }
                });
            }
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    createForm(): void {
        this.getControls();
        this.medicalIssuesForm = this.assignFormGroups();
        this.formCreated = true;
        this.currentStatus.formGroup = this.medicalIssuesForm;
        if (this.denied) {
            setTimeout(() => this.medicalIssuesForm.disable());
        } else {
            this.subs.add(
                this.medicalIssuesForm.valueChanges.pipe(debounceTime(50)).subscribe(() => {
                    if (this.medicalIssuesForm.dirty) {
                        this.assignFormValues();
                    }
                    this.setFormStatus();
                }),
            );
        }
    }

    setFormStatus(): void {
        if (this.medicalIssuesForm.valid && this.medicalIssuesComplete()) {

            const control = this.medicalIssuesForm.get('ApplicationIssue.Submitted');

            if (control && control.value) {
                this.currentStatus.status = 'Submitted';
                this.medicalIssues.Submitted = true;
            } else {
                this.currentStatus.status = 'Complete';
                this.medicalIssues.Complete = true;
                this.medicalIssues.Submitted = false;
            }
        } else {
            this.currentStatus.status = 'In Progress';
            this.medicalIssues.Complete = false;
        }
        this.applicationService.currentSectionAndStatus.next(this.currentStatus);
    }

    getControls(): void {
        this.abstractApplicationIssueControls = new ApplicationIssueDynamicControlsPartial(this.medicalIssues, {
            formGroup: 'ApplicationIssue',
            birthControlTypes: this.medicalIssueMetaItems.BirthControlTypes,
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ApplicationIssue: this.fb.group({}),
        });
    }

    assignFormValues(): void {
        assignFormValues(this.medicalIssues, this.medicalIssuesForm.get('ApplicationIssue').value);
        this.medicalIssues.IsTakingMedication = this.medicalIssuesForm.get('ApplicationIssue.SelectedIsTakingMedication').value === 1;
        this.medicalIssues.CurrentMedications = this.medicalIssues.IsTakingMedication
            ? this.medicalIssuesForm.get('ApplicationIssue.CurrentMedications').value
            : null;
        this.medicalIssues.OtherIssues = this.medicalIssuesForm.get('ApplicationIssue.OtherIssues').value || null;
        this.medicalIssues.HasSurgeries = this.medicalIssuesForm.get('ApplicationIssue.SelectedHasSurgeries').value === 1;
        this.medicalIssues.Surgeries = this.medicalIssues.HasSurgeries ? this.medicalIssuesForm.get('ApplicationIssue.Surgeries').value : null;
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    applicatantHasSurgeries(): boolean {
        return this.selectedHasSurgeries === 1;
    }

    applicatantIsTakingMedications(): boolean {
        return this.selectedIsTakingMedication === 1;
    }

    medicalIssueChanged(): void {
        this.medicalIssuesForm.markAsDirty();
        this.setFormStatus();
    }

    setSubissues(e: { selectedItems: IMetaItem[]; parentIssueId: number; issueId: number }): void {
        // Set issue
        let issue = this.applicationIssueMedicalIssues.find((i) => i.MedicalIssueId === e.issueId);
        issue.Mother = e.selectedItems.some((si) => si.Name === 'Mother');
        issue.You = e.selectedItems.some((si) => si.Name === 'You');
        issue.Father = e.selectedItems.some((si) => si.Name === 'Father');
        issue.Sibling = e.selectedItems.some((si) => si.Name === 'Sibling');
        issue.Grandparent = e.selectedItems.some((si) => si.Name === 'Grandparent');
        issue.Children = e.selectedItems.some((si) => si.Name === 'Children');
        issue.None = e.selectedItems.some((si) => si.Name === 'None');
        this.medicalIssueChanged();
    }

    getIssuesByTypeId(typeId: number): IApplicationIssuesMedicalIssue[] {
        return this.applicationIssueMedicalIssues.filter(
            (issue) => issue.MedicalIssue.MedicalIssueTypeId === typeId && !issue.MedicalIssue.ParentMedicalIssueId,
        );
    }

    medicalIssuesComplete(): boolean {
        return this.applicationIssueMedicalIssues.every((issue) => {
            if (issue.MedicalIssue.ParentMedicalIssueId) {
                const parentIssue = this.applicationIssueMedicalIssues.find((mi) => mi.MedicalIssueId === issue.MedicalIssue.ParentMedicalIssueId);
                if (this.medicalIssueRelationshipTypes.some((type) => parentIssue[type.Name] === true && type.Name !== 'None')) {
                    return this.medicalIssueRelationshipTypes.some((type) => issue[type.Name]);
                } else {
                    return true;
                }
            } else {
                return this.medicalIssueRelationshipTypes.some((type) => {
                    return issue[type.Name];
                });
            }
        });
    }

    get isComplete(): boolean {
        return this.medicalIssues.Complete;
    }

    onBirthControlTypeIdChanging(value: number): void {
        this.showOtherBirthControlMethod = (value === BirthControlTypes.Other);
    }
}
