import { Component, ContentChild, Injector, TemplateRef } from '@angular/core';

import { NavService } from '../nav.service';

@Component({
    selector: `header-new-tab`,
    styleUrls: [`./nav-header.styles.less`],
    template: `
        <ng-container
            *ngTemplateOutlet="templateRef; context: { openInNewTab: this.openInNewTab.bind(this), showNewTabButton: this.showNewTabButton }"
        >
        </ng-container>
        <div *ngIf="showNewTabButton && !templateRef" class="nav navbar-nav nav-header-button" title="new tab" (click)="openInNewTab()">
            <a>
                <i class="fa fa-external-link" aria-hidden="true"></i>
            </a>
        </div>
    `,
})
export class HeaderNewTabComponent {
    @ContentChild(TemplateRef) templateRef;

    get showNewTabButton(): boolean {
        return this.navService.showNewTabButton;
    }

    protected navService: NavService;

    constructor(injector: Injector) {
        this.navService = injector.get(NavService);
    }

    openInNewTab(): void {
        window.open(window.location.href);
    }
}
