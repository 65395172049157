import {
    ApplicationIntroduceYourselfDynamicControls,
    IApplicationIntroduceYourselfDynamicControlsParameters,
} from '../form-controls/application-introduce-yourself.form-controls';
import { IApplicationIntroduceYourself } from '../interfaces/application-introduce-yourself';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    InputTypes,
} from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';

export class ApplicationIntroduceYourselfDynamicControlsPartial extends ApplicationIntroduceYourselfDynamicControls {
    constructor(
        applicationwhoareyouPartial?: IApplicationIntroduceYourself,
        additionalParameters?: IApplicationIntroduceYourselfDynamicControlsParameters,
    ) {
        super(applicationwhoareyouPartial, additionalParameters);

        (<DynamicField>this.Form.CharacterDescription) = new DynamicField({
            formGroup: this.formGroup,
            label: 'How would a family member or friend describe your personality and temperament?',
            name: 'CharacterDescription',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: {maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('CharacterDescription') &&
                applicationwhoareyouPartial.CharacterDescription !== null
                    ? applicationwhoareyouPartial.CharacterDescription.toString()
                    : '',
        });
        (<DynamicField>this.Form.ExerciseRoutine) = new DynamicField({
            formGroup: this.formGroup,
            label: 'How do you like to stay active?',
            name: 'ExerciseRoutine',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: {maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('ExerciseRoutine') &&
                applicationwhoareyouPartial.ExerciseRoutine !== null
                    ? applicationwhoareyouPartial.ExerciseRoutine.toString()
                    : '',
        });
        (<DynamicField>this.Form.Favorites) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Please list some of your favorite books, food, music, etc.',
            name: 'Favorites',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: {maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('Favorites') &&
                applicationwhoareyouPartial.Favorites !== null
                    ? applicationwhoareyouPartial.Favorites.toString()
                    : '',
        });
        (<DynamicField>this.Form.Goals) = new DynamicField({
            formGroup: this.formGroup,
            label: 'What would some of your personal and/or career goals be?',
            name: 'Goals',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: {maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial && applicationwhoareyouPartial.hasOwnProperty('Goals') && applicationwhoareyouPartial.Goals !== null
                    ? applicationwhoareyouPartial.Goals.toString()
                    : '',
        });
        (<DynamicField>this.Form.LanguagesSpoken) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Do you speak or understand languages other than English?',
            name: 'LanguagesSpoken',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: {maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial && applicationwhoareyouPartial.hasOwnProperty('LanguagesSpoken') && applicationwhoareyouPartial.LanguagesSpoken !== null
                    ? applicationwhoareyouPartial.LanguagesSpoken.toString()
                    : '',
        });
        (<DynamicField>this.Form.TalentsAbilities) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Please list some of your hobbies, talents, and abilities',
            name: 'TalentsAbilities',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: {maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('TalentsAbilities') &&
                applicationwhoareyouPartial.TalentsAbilities !== null
                    ? applicationwhoareyouPartial.TalentsAbilities.toString()
                    : '',
        });
        (<DynamicField>this.Form.WhyInterestedInBecomingEggDonor) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Why are you interested in becoming an egg donor?',
            name: 'WhyInterestedInBecomingEggDonor',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: {maxlength: 3000, required: true },
            value:
                applicationwhoareyouPartial &&
                applicationwhoareyouPartial.hasOwnProperty('WhyInterestedInBecomingEggDonor') &&
                applicationwhoareyouPartial.WhyInterestedInBecomingEggDonor !== null
                    ? applicationwhoareyouPartial.WhyInterestedInBecomingEggDonor.toString()
                    : '',
        });
    }
}
