import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable()
export class ForgotUsernameService {
    constructor(public http: HttpClient) {}

    requestUsernameEmail(email: string): any {
        const data: any = {
            email: email,
        };

        return this.http.post<any>(`/donor-portal/forgot-username`, data, {
            headers: { BypassAuth: 'true' },
        });
    }
}
