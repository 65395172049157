import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, LabelPositions } from '@mt-ng2/dynamic-form';
import { ApplicationDynamicControls, IApplicationDynamicControlsParameters } from '../form-controls/application.form-controls';
import { IApplication } from '../interfaces/application';

export class ApplicationDynamicControlsPartial extends ApplicationDynamicControls {
    constructor(applicationPartial?: IApplication, additionalParameters?: IApplicationDynamicControlsParameters) {
        super(applicationPartial, additionalParameters);

        (<DynamicField>this.Form.HasAcknowledgedWaiver) = new DynamicField({
            formGroup: this.formGroup,
            label: '',
            labelPosition: { position: LabelPositions.Hidden, colsForLabel: null },
            name: 'HasAcknowledgedWaiver',
            options: [
                { Id: 1, Name: 'Yes', True: true },
                { Id: 2, Name: 'No', True: false },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationPartial?.HasAcknowledgedWaiver ? 1 : 2,
        });

        (<DynamicField>this.Form.socialSecurityNumber) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Social Security Number',
            name: 'socialSecurityNumber',
            placeholder: '###-##-####',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            }),
            validation: [Validators.minLength(9), Validators.maxLength(9), Validators.required, Validators.pattern('[0-9]*')],
            validators: { minlength: 9, maxlength: 9, required: true, pattern: new RegExp('[0-9]*'), showRequired: true },
            value: '',
        });
    }
}
