import { SelectInputTypes } from '../form-elements.library';
import { CustomFormControlComponentBase } from '../custom-form-component.base';
import { Injector } from '@angular/core';

export class FormSelectBaseWithNullableOption extends CustomFormControlComponentBase {
    constructor(injector: Injector) {
        super(injector);
    }
    ngOnInit(): void {
        super.ngOnInit();

        if (this.showNullableOption()) {
            let nullableOption: any = {
                Id: null,
            };
            switch (this.config.type.inputType) {
                case SelectInputTypes.Dropdown:
                case SelectInputTypes.MultiselectDropdown:
                case SelectInputTypes.OldStyleMultiselectDropdown:
                    nullableOption.Name = '';
                    this.addNullableOption(nullableOption);
                    break;
                case SelectInputTypes.RadioButtonList:
                case SelectInputTypes.OldStyleRadioButtonList:
                    nullableOption.Name = 'No Selection';
                    this.addNullableOption(nullableOption);
                    break;
                case SelectInputTypes.TypeAhead:
                default:
                    break;
            }
        }
    }

    private addNullableOption(nullableOption): void {
        this.config.options.unshift(nullableOption);
    }

    private showNullableOption(): boolean {
        return this.config.options && !this.config.validators.required && this.config.type.allowNullableOption;
    }
}
