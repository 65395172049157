<div [ngClass]="hideTrim ? '' : 'auth-layout'">
    <div *ngIf="!hideTrim" class="auth-layout__left">
        <img src="../assets/logo-white.png" height="79" width="100" alt="tweb logo" />
    </div>
    <div class="miles-form padded row application-section" [ngClass]="hideTrim ? '' : 'auth-layout__right'"
        *ngIf="(formCreated && !isSubmittedAndSaved) && !isDenied" [formGroup]="preScreeningForm">
        <div *ngIf="!hideTrim">
            <a class="button--text button--has-icon" href="https://theworldeggandspermbank.com"
                rel="noopener noreferrer">
                <i class="fa fa-arrow-left"></i> Back to Main Website
            </a>
        </div>
        <div [ngClass]="hideTrim ? '' : 'auth-layout__right__children'">
            <input *ngIf="!hideTrim" #honeypot type="checkbox" name="opt_in_to_emails" value="1"
                style="display: none !important" tabindex="-1" autocomplete="off" />
            <div class="auth-layout__form-container" *ngIf="formCreated ">
                <form class="miles-form padded row application-section">
                    <div *ngIf="!hideTrim">
                        <h2 class="auth-layout__h2">Check Your Eligibility!</h2>
                        <div class="input-scaffold__group">
                            <div class="input-scaffold">
                                <p>
                                    This is a short pre-screening questionnaire that provides a quick and easy way to
                                    see if you meet our general requirements to become an egg donor here at The World
                                    Egg
                                    and Sperm Bank!
                                    After completing the information below, if you qualify, you will be able to proceed with the
                                    main
                                    application!
                                </p>
                                <p>
                                    Let’s see if you meet our general requirements.
                                </p>
                                <p>
                                    Estimated completion time: only <u>3-4 minutes!</u>
                                </p>
                                <p>
                                    Please read the following requirements before starting the Pre-Screening
                                    Application:
                                </p>
                                <div>
                                    <ul>
                                        <li>You are between the ages of 18-31.</li>
                                        <li>Must hold a Permanent Residency in either the United States or Canada along
                                            with
                                            corresponding official Government issued ID.</li>
                                        <li>You have a healthy lifestyle with no alcohol addiction or drug abuse.</li>
                                        <li>You can provide your health history information on your biological siblings,
                                            parents, and grandparents.</li>
                                        <li>You will need to have a flexible schedule to be able to attend <strong><u>3
                                                    to 4
                                                    time sensitive
                                                    appointments</u></strong> and be willing to self-administer small
                                            injections to the abdomen.</li>
                                        <li>We depend on your reliability!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 class="auth-layout__h2">Basic Info.</h2>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="preScreeningControls.BirthYear"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="preScreeningControls.Email"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="preScreeningControls.ConfirmEmail"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>

                    <div *ngIf="!emailsAreEqual()">
                        <div style="color: red">
                            E-mail address and Confirm e-mail address do not match.
                        </div>
                        <br />
                    </div>

                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="preScreeningControls.HeightUnitId"
                                (valueChanges)="onHeightUnitsValueChange($event)"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group" *ngIf="!usingMeters()">
                        <div class="input-scaffold">
                            <dynamic-field [field]="preScreeningControls.Feet"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                        <div class="input-scaffold">
                            <dynamic-field [field]="preScreeningControls.Inches"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group" *ngIf="usingMeters()">
                        <div class="input-scaffold">
                            <dynamic-field [field]="preScreeningControls.Meters"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="preScreeningControls.WeightUnitId"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="preScreeningControls.Weight"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>

                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <label class="input-label">
                                <span class="input-scaffold__label">Gender
                                    <span style="color: red"> *</span>
                                </span>
                                <div class="input-scaffold">
                                    <dynamic-field [field]="preScreeningControls.GenderId"
                                        [form]="preScreeningForm"></dynamic-field>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div>
                            <div class="input-scaffold">
                                <dynamic-field [field]="preScreeningControls.EducationCompletedTypeId"
                                    [form]="preScreeningForm"></dynamic-field>
                            </div>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <label class="input-label">
                                <span class="input-scaffold__label">Are you a US Citizen or Green Card Holder?
                                    <span style="color: red"> *</span>
                                </span>
                            </label>
                            <dynamic-field [field]="preScreeningControls.IsUsCitizenOrGreenCardHolder"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div>
                            <div class="input-scaffold">
                                <dynamic-field [field]="preScreeningControls.CountryCode"
                                    (valueChanges)="setStatesForCountry($event)"
                                    (controlCreated)="setShowStatesCurrentAddress($event)"
                                    [form]="preScreeningForm"></dynamic-field>
                            </div>
                        </div>
                    </div>
                    <div class="input-scaffold__group" *ngIf="(showStatesCurrentAddress$ | async)">
                        <div class="input-scaffold">
                            <dynamic-field *ngIf="(showStatesCurrentAddress$ | async)"
                                (valueChanges)="saveState($event)" [field]="preScreeningControls.StateCode"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group" *ngIf="!(showStatesCurrentAddress$ | async)">
                        <div class="input-scaffold">
                            <dynamic-field [field]="preScreeningControls.Province"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <label class="input-label">
                                <span class="input-scaffold__label">
                                    Have you tested positive for any STDs in the past year?
                                    <span style="color: red"> *</span>
                                </span>
                            </label>
                            <div class="input-scaffold">
                                <dynamic-field [field]="preScreeningControls.HasStd"
                                    [form]="preScreeningForm"></dynamic-field>
                            </div>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <label class="input-label">
                                <span class="input-scaffold__label">Have you ever tested positive for HIV?
                                    <span style="color: red"> *</span>
                                </span>
                            </label>
                            <div class="input-scaffold">
                                <dynamic-field [field]="preScreeningControls.HasHiv"
                                    [form]="preScreeningForm"></dynamic-field>
                            </div>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <label class="input-label">
                                <span class="input-scaffold__label">Are you adopted?
                                    <span style="color: red"> *</span>
                                </span>
                            </label>
                            <div class="input-scaffold">
                                <dynamic-field [field]="preScreeningControls.IsAdopted"
                                    (valueChanges)="IsAdopted = $event" [form]="preScreeningForm"></dynamic-field>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="IsAdopted">
                        <div>
                            <span style="color: red">You must know your biological family history including your
                                siblings,
                                parents, and grandparents on both your maternal and paternal sides of the family.</span>
                        </div>
                        <br />
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <label class="input-label">
                                <span class="input-scaffold__label">Will you be able to obtain medical information
                                    about your biological siblings, parents, and grandparents on both your maternal and
                                    paternal sides of the family?
                                    <span style="color: red"> *</span>
                                </span>
                            </label>
                            <div class="input-scaffold">
                                <dynamic-field [field]="preScreeningControls.HasFamilyMedicalHistory"
                                    [form]="preScreeningForm"></dynamic-field>
                            </div>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <label class="input-label">
                                <span class="input-scaffold__label">Do you have both ovaries?
                                    <span style="color: red"> *</span>
                                </span>
                            </label>
                            <div class="input-scaffold">
                                <dynamic-field [field]="preScreeningControls.HasBothOvaries"
                                    [form]="preScreeningForm"></dynamic-field>
                            </div>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <label class="input-label">
                                <span class="input-scaffold__label">Have you ever given birth?
                                    <span style="color: red"> *</span>
                                </span>
                            </label>
                            <div class="input-scaffold">
                                <dynamic-field [field]="preScreeningControls.HasGivenBirth"
                                    (valueChanges)="onHasGivenBirthValueChange($event)"
                                    [form]="preScreeningForm"></dynamic-field>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="hasGivenBirth">
                        <div class="input-scaffold__group">
                            <div class="input-scaffold">
                                <dynamic-field [field]="preScreeningControls.HowManyLiveBirths"
                                    (valueChanges)="onHowManyLiveBirthsValueChanges($event)"
                                    [form]="preScreeningForm"></dynamic-field>
                            </div>
                        </div>
                    </div>
                    <app-births *ngIf="preScreening.HowManyLiveBirths > 0" [preScreening]="preScreening"
                        [genders]="genders" [numberOfLiveBirths]="preScreening.HowManyLiveBirths"
                        (onBirthsChanging)="onBirthsChanging($event)"></app-births>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <label class="input-label">
                                <span class="input-scaffold__label">Are you currently pregnant or breast feeding?
                                    <span style="color: red"> *</span>
                                </span>
                            </label>
                            <div class="input-scaffold">
                                <dynamic-field [field]="preScreeningControls.IsPregnantOrBreastFeeding"
                                    [form]="preScreeningForm"></dynamic-field>
                            </div>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <label class="input-label">
                                <span class="input-scaffold__label">Have you ever donated your eggs before?
                                    <span style="color: red"> *</span>
                                </span>
                            </label>
                            <div class="input-scaffold">
                                <dynamic-field [field]="preScreeningControls.HasPreviouslyDonatedEggs"
                                    [form]="preScreeningForm"></dynamic-field>
                            </div>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <label class="input-label">
                                <span class="input-scaffold__label">Do you have regular periods when not using birth
                                    control?
                                    <span style="color: red"> *</span>
                                </span>
                            </label>
                            <div class="input-scaffold">
                                <dynamic-field [field]="preScreeningControls.HasRegularPeriodsWithoutBirthControl"
                                    [form]="preScreeningForm"></dynamic-field>
                            </div>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="preScreeningControls.ReferralTypeId"
                                (valueChanges)="onReferralTypeValueChange($event)"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group" *ngIf="otherReferralType()">
                        <div class="input-scaffold">
                            <dynamic-field [field]="preScreeningControls.OtherReferral"
                                [form]="preScreeningForm"></dynamic-field>
                        </div>
                    </div>
                    <div *ngIf="isComplete">
                        <div class="form-section-border">
                            <h3>Review</h3>
                        </div>
                        <div>
                            <div class="input-scaffold__label" style="font-style: italic;">
                                Please review your answers, above, then confirm that the information is
                                accurate to the best of your knowledge.
                            </div>
                            <br />
                            <dynamic-field id="Submitted" [field]="preScreeningControls.Submitted" #Submitted
                                [form]="preScreeningForm"> </dynamic-field>
                            <br />
                        </div>
                    </div>
                </form>
                <div *ngIf="!hideTrim" class="status-tracker-indicator">
                    <status-indicator [currentSection]="currentStatus" (nextTab)="selectTabs(currentSection.nextTab)"
                        (validateForm)="validateForm()">
                    </status-indicator>
                </div>
            </div>
            <div *ngIf="!hideTrim" class="auth-layout__footer">
                <div class="auth-layout__footer">
                    <a href="https://www.theworldeggandspermbank.com/wp-content/uploads/2018/05/terms-of-use.pdf"
                        rel="noopener noreferrer" target="_blank">
                        Terms of Use
                    </a>
                    | <a href="https://www.theworldeggandspermbank.com/contact-us/" target="_blank">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!hideTrim">
        <div class="auth-layout__right" *ngIf="isDenied">
            <div class="auth-layout__right__children" *ngIf="!isPreviousApplicant">
                <div class="container">
                    <h2 class="auth-layout__h2">Thank you for your interest</h2>
                    <application-denied-message></application-denied-message>
                    <br />
                </div>
            </div>
            <div class="auth-layout__right__children" *ngIf="isPreviousApplicant">
                <div class="container">
                    <h2 class="auth-layout__h2">Thank you for your interest</h2>
                    <p>
                        Hello,
                    </p>
                    <br />
                    <p>We truly appreciate your interest in our Egg Donor Program.
                    </p>
                    <br />
                    <p>
                        It looks as though you have previously applied to be an Egg Donor. If you were accepted at that
                        time, please login with your existing user account information.
                    </p>
                    <br />
                    <p>
                        Thank you for your interest in our Egg Donor Program.
                    </p>
                    <br />
                    <p>
                        Sincerely,
                    </p>
                    <p>
                        The World Egg and Sperm Bank Donor Team
                    </p>
                    <br />
                </div>
            </div>
        </div>
    </div>
</div>