import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IApplicationIntroduceYourself } from '@model/interfaces/application-introduce-yourself';
import { Observable } from 'rxjs';
import { BlobOptions } from 'buffer';

export const emptyApplicationIntroduceYourself: IApplicationIntroduceYourself = {
    Complete: null,
    Submitted: null,
    Approved: null,
    DateCreated: null,
    Id: 0,
};

@Injectable({
    providedIn: 'root',
})
export class ApplicationIntroduceYourselfService extends BaseService<IApplicationIntroduceYourself> {
    constructor(public http: HttpClient) {
        super('/application-introduce-yourself', http);
    }

    updateIntroduceYourself(introduceYourself: IApplicationIntroduceYourself): Observable<IApplicationIntroduceYourself> {
        return this.http.post<IApplicationIntroduceYourself>(`/application-introduce-yourself/update`, introduceYourself);
    }

    approveIntroduceYourself(donorId: number, introduceYourself: IApplicationIntroduceYourself): Observable<boolean> {
        return this.http.post<boolean>(`/application-introduce-yourself/${donorId}/approve`, introduceYourself);
    }

    getEmptyApplicationIntroduceYourself(): IApplicationIntroduceYourself {
        return { ...emptyApplicationIntroduceYourself };
    }
}
