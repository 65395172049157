import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDonorHelloSignDocument } from '@model/interfaces/donor-hello-sign-document';

import { Observable } from 'rxjs';

interface IHelloSignResponse {
    SignUrl: string;
    ExpiresAt: number;
}

@Injectable({
    providedIn: 'root',
})
export class SignService {
    constructor(public http: HttpClient) {}

    getHelloSignClientId(): Observable<string> {
        return this.http.get<string>(`/donor-portal/sign`);
    }

    getDonorDocuments(donorid: number): Observable<IDonorHelloSignDocument[]> {
        return this.http.get<IDonorHelloSignDocument[]>(`/donor-portal/sign/${donorid}`);
    }

    getDocumentToSign(donorId: number, templateId: number): Observable<IHelloSignResponse> {
        return this.http.get<IHelloSignResponse>(`/donor-portal/sign/${donorId}/${templateId}`);
    }

    markDocumentAsSigned(donorId: number, templateId: number): Observable<void> {
        return this.http.put<void>(`/donor-portal/sign/${donorId}/${templateId}`, {});
    }
}
