import { Component, ChangeDetectorRef } from '@angular/core';
import { CustomFormControlComponentBase, FormPasswordComponent } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'tweb-password-dynamic-field-component',
    template: `
        <div class="input-scaffold" [class.has-error]="hasError()" [formGroup]="getGroup()">
            <label class="input-label">
                <span class="input-scaffold__label">{{ config.label }}</span>
                <input
                    #inputElement
                    class="input-scaffold__input input-rounded"
                    [attr.type]="'password'"
                    [attr.placeholder]="config.placeholder"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    [formControlName]="config.name"
                    [maxlength]="getMaxLength()"
                />
            </label>
        </div>
        <div *ngIf="config.validators">
            <div *ngIf="showRequired() && hasError('required')" class="small inside-box-validation">{{ config.label }} is required</div>
            <div
                *ngIf="hasRegexError() && hasError('pattern')"
                class="small inside-box-validation"
                [class.inside-box-validation]="config.insideBoxValidation"
            >
                {{ config.failedPatternMessage }}
            </div>
        </div>
    `,
})
export class TwebPasswordComponent extends FormPasswordComponent {
    constructor(changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }

    ngOnInit(): void {
        this.config.insideBoxValidation = true;
    }
}
