import { Component, Input, ContentChild, TemplateRef } from '@angular/core';
import { IRecommendedRoute } from '../../model/recommended-route';

@Component({
    selector: 'app-recommended-page-list',
    templateUrl: './recommended-page-list.component.html',
})
export class RecommendedPageListComponent {
    @ContentChild(TemplateRef) templateRef;

    @Input() recommendedRoutes: IRecommendedRoute[];
    @Input() label: string;

    constructor() {}
}
