import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthUser } from '@model/interfaces/auth-user';

@Injectable({
    providedIn: 'root',
})
export class UnsubscribeService {
    constructor(public http: HttpClient) { }

    getAuthUser(donorId: number, email: string): Observable<IAuthUser> {
        return this.http.get<IAuthUser>(`/donor-portal/unsubscribe/get-auth-user/${donorId}/${email}`, {
            headers: { BypassAuth: 'true' }});

    }

    ubsubscribeDonor(data: IAuthUser): Observable<boolean> {
        return this.http.post<boolean>(`/donor-portal/unsubscribe/unsubscribe`, data, {
            headers: { BypassAuth: 'true' }});
    }
}
