import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { NotFoundComponent } from './default-routes/not-found/not-found.component';
import { PathNotFoundResolveService } from './default-routes/path-not-found-resolve.service';
import { appPaths } from './default-routes/app-paths.library';
import { LoginOverrideComponent } from './login/login-override.component';
import { RegistrationComponent } from './registration/registration.component';
import { PreScreeningComponent } from './donor-application/sections/pre-screening/pre-screening.component';
import { ClaimTypes } from '@model/ClaimTypes';
import { ForgotPasswordComponent } from './login/forgot-password.component';
import { DonorResetPasswordComponent } from './login/reset-password.component';
import { ForgotUsernameComponent } from './login/forgot-username/forgot-username.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

const homeRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.DonorApplication,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    isHomePage: true,
};

const appRoutes: Routes = [
    { path: appPaths.login, component: LoginOverrideComponent },
    { path: appPaths.register, component: RegistrationComponent },
    { path: appPaths.preScreening, component: PreScreeningComponent },
    { path: appPaths.forgotPassword, component: ForgotPasswordComponent },
    { path: appPaths.forgotUsername, component: ForgotUsernameComponent },
    { path: appPaths.resetPassword, component: DonorResetPasswordComponent },
    { path: appPaths.unsubscribe, children: [{path: ':email', component: UnsubscribeComponent }] },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: appPaths.login,
    },
    {
        canActivate: [AuthGuard],
        component: NotFoundComponent,
        data: homeRoleGuard,
        path: '**',
        resolve: {
            path: PathNotFoundResolveService,
        },
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
