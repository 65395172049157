import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { KeyboardShortcutService } from 'ngx-keyboard-shortcuts';

import { MtAlertsModule } from '@mt-ng2/alerts-module';
import { MtPreventDoubleClickButtonModule } from '@mt-ng2/disable-double-click';
import { BackButtonModule } from '@mt-ng2/back-button-module';

import { CommonService } from './services/common.service';
import { PhotoUploaderComponent } from './photo-uploader/photo-uploader.component';
import { KeyboardShortcutModule } from '@mt-ng2/keyboard-shortcuts-module';

import { DashboardModule } from '@mt-ng2/dashboard';
import { recommendedPagesModuleConfig } from './configs/recommended-pages.config';
import { PageTitlesModule } from '@mt-ng2/page-titles';
import { IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module-config';
import { DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form-config';
import { ContactStatusService } from '@model/shared-entities/contacts/contact-status.service';
import { PrintModeService } from '@mt-ng2/entity-list-module';
import { FeatureModule } from './feature.module';
import { TwebAuthInputComponent } from './form-controls/tweb-auth-input.component';
import { TwebPasswordComponent } from './form-controls/tweb-password.component';
import { TwebInputComponent } from './form-controls/tweb-input.component';
import { TwebSelectComponent } from './form-controls/tweb-select.component';
import { ReferralTypeService } from '@common/services/referral-type.service';
import { UnitsOfMeasurementService } from '@common/services/units-of-measurement.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MedicalIssueTypesService } from './services/medical-issue-types.service';
import { MedicalIssueRelationshipTypesService } from './services/medical-issue-relationship-types.service';
import { MedicalIssueOptionsService } from './services/medical-issue-options.service';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { TwebPhoneInputComponent } from './form-controls/tweb-phone-input.component';
import { MultiselectControlModule } from '@mt-ng2/multiselect-control';
import { TwebInputNumericComponent } from './form-controls/tweb-input-numeric.component';
import { TwebTextAreaComponent } from './form-controls/tweb-textarea.component';
import { ModalModule } from '@mt-ng2/modal-module';
import { DeniedModalComponent } from './modals/denied-modal.component';
import { FileUploadModule } from 'ng2-file-upload';
import { AppoinmentSchedulingComponent } from '../appointment-scheduling/appointment-scheduling.component';
import { FallbackImgDirective } from './directives/fallback-image.directive';
import { FileExtensionChangePipe } from './pipes/file-extension-change.pipe';
import { ApplicationPhotoUploaderComponent } from './application-photo-uploader/application-photo-uploader.component';
import { ApplicationIdentityPhotoUploaderComponent } from './application-identity-photo-uploader/application-indentity-photo-uploader.component';
import { ApplicationDeniedMessage } from '../donor-application/application-denied-message.component';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

@NgModule({
    declarations: [
        TwebAuthInputComponent,
        TwebInputComponent,
        DeniedModalComponent,
        TwebTextAreaComponent,
        TwebInputNumericComponent,
        AppoinmentSchedulingComponent,
        TwebPasswordComponent,
        PhoneMaskDirective,
        TwebPhoneInputComponent,
        TwebSelectComponent,
        PhotoUploaderComponent,
        FallbackImgDirective,
        FileExtensionChangePipe,
        ApplicationPhotoUploaderComponent,
        ApplicationIdentityPhotoUploaderComponent,
        ApplicationDeniedMessage,
    ],
    entryComponents: [
        TwebAuthInputComponent,
        TwebPasswordComponent,
        DeniedModalComponent,
        TwebInputComponent,
        TwebSelectComponent,
        TwebInputNumericComponent,
        TwebTextAreaComponent,
        TwebPhoneInputComponent, ,
    ],
    exports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PhotoUploaderComponent,
        NgxMaskModule,
        MultiselectControlModule,
        MtAlertsModule,
        MtPreventDoubleClickButtonModule,
        BackButtonModule,
        KeyboardShortcutModule,
        DashboardModule,
        PageTitlesModule,
        FeatureModule,
        NgxSkeletonLoaderModule,
        PhoneMaskDirective,
        AppoinmentSchedulingComponent,
        FallbackImgDirective,
        FileExtensionChangePipe,
        ApplicationPhotoUploaderComponent,
        ApplicationIdentityPhotoUploaderComponent,
        ApplicationDeniedMessage,
    ],
    imports: [
        CommonModule,
        NgbModule,
        ModalModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        ModalModule,
        FileUploadModule,
        MtAlertsModule,
        MultiselectControlModule,
        MtPreventDoubleClickButtonModule,
        BackButtonModule,
        KeyboardShortcutModule,
        DashboardModule.forRoot(recommendedPagesModuleConfig),
        PageTitlesModule,
        FeatureModule,
        NgxSkeletonLoaderModule,
    ],
})
export class SharedModule {
    static forRoot(): any {
        return {
            ngModule: SharedModule,
            providers: [
                CommonService,
                ReferralTypeService,
                MedicalIssueTypesService,
                MedicalIssueRelationshipTypesService,
                MedicalIssueOptionsService,
                UnitsOfMeasurementService,
                ContactStatusService,
                KeyboardShortcutService,
                PrintModeService,
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: DynamicFormModuleConfigToken, useValue: { commonService: CommonService } },
            ],
        };
    }
}
