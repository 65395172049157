import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { NavService } from '../nav.service';

export class Navbar {
    sideBarCollapsed: boolean;
    showNav: boolean;
}

@Component({
    selector: 'app-nav-header',
    styles: [
        `
            .main-header {
                position: fixed;
                top: 0;
                width: 100vw;
            }
            .navbar-custom-menu {
                margin-right: 20px;
            }
            .custom-header-area {
                width: 345px;
                padding-top: 15px;
                color: #fff;
            }
            .mobile-app-name {
                width: auto;
                float: unset;
                display: inherit;
                padding: 0;
            }
            .main-header > .navbar {
                margin-left: 500px;
            }
            .main-header > .navbar.mobile-navbar {
                margin-left: 0;
            }
            .main-header > .navbar.mobile-navbar > a {
                text-decoration-line: none;
            }
            .main-header .logo {
                width: 500px;
            }
        `,
    ],
    styleUrls: [`./nav-header.styles.less`],
    template: `
        <!-- header for medium size and up -->
        <header class="main-header hidden-sm hidden-xs" [hidden]="!showNav">
            <!-- Logo -->
            <a [routerLink]="['/']" class="logo pointer">
                <!-- mini logo for sidebar mini 50x50 pixels -->
                <span class="logo-mini">
                    <img [src]="logoUrl" alt="minilogo" class="logo-img" />
                </span>
                <!-- logo for regular state and mobile devices -->
                <div class="logo-lg">
                    <img class="visible-md-block visible-lg-block" [src]="fullLogoUrl" alt="logo" />
                    <b>{{ appName }}</b>
                </div>
            </a>

            <!-- Header Navbar: style can be found in header.less -->
            <nav class="navbar navbar-static-top" role="navigation">
                <!-- Navbar Right Menu -->
                <div class="navbar-custom-menu">
                    <div class="nav navbar-nav custom-header-area visible-lg-block" [innerHtml]="customHeaderHtml"></div>
                    <header-current-user-details></header-current-user-details>
                    <header-new-tab></header-new-tab>
                    <header-shortcut-help></header-shortcut-help>
                    <header-submit-help></header-submit-help>
                </div>
            </nav>
        </header>
        <!-- header for small size and down -->
        <header class="main-header hidden-md hidden-lg hidden-xl" [hidden]="!showNav">
            <nav class="navbar navbar-static-top mobile-navbar" role="navigation">
                <div data-toggle="offcanvas" class="sidebar-toggle" role="button" (click)="toggleSidebarCollapse()">
                    <span class="sr-only">Toggle navigation</span>
                </div>
                <a [routerLink]="['/']" class="pointer">
                    <span class="logo mobile-app-name">
                        <b>{{ appName }}</b>
                    </span>
                </a>

                <!-- Navbar Right Menu -->
                <div class="navbar-custom-menu">
                    <div class="nav navbar-nav custom-header-area visible-lg-block" [innerHtml]="customHeaderHtml"></div>
                    <header-current-user-details></header-current-user-details>
                    <header-new-tab></header-new-tab>
                    <header-shortcut-help></header-shortcut-help>
                    <header-submit-help></header-submit-help>
                </div>
            </nav>
        </header>
    `,
})
export class NavHeaderComponent implements OnInit, OnDestroy {
    logoUrl: string;
    fullLogoUrl: string;
    appName: string;
    showNav: boolean;
    customHeaderHtml: string;

    navBar: Navbar;
    subscriptions: Subscription = new Subscription();

    protected navService: NavService;

    constructor(injector: Injector) {
        this.navService = injector.get(NavService);
    }

    ngOnInit(): void {
        this.logoUrl = this.navService.logoUrl;
        this.fullLogoUrl = this.navService.fullLogoUrl;
        this.appName = this.navService.appName;

        this.showNav = this.navService.showNav.getValue();
        this.subscriptions.add(
            this.navService.showNav.subscribe((showNav: boolean) => {
                this.showNav = showNav;
            }),
        );

        this.customHeaderHtml = this.navService.customHeaderHtml.getValue();
        this.subscriptions.add(
            this.navService.customHeaderHtml.subscribe((headerHtml: string) => {
                this.customHeaderHtml = headerHtml;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    toggleSidebarCollapse(): void {
        this.navService.updateNav(this.navBar);
        this.navService.toggleSidebarCollapsed();
    }
}
