import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    InputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IApplicationIntroduceYourself } from '../interfaces/application-introduce-yourself';

export interface IApplicationIntroduceYourselfDynamicControlsParameters {
    formGroup?: string;
}

export class ApplicationIntroduceYourselfDynamicControls {
    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private applicationIntroduceYourself?: IApplicationIntroduceYourself, additionalParameters?: IApplicationIntroduceYourselfDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ApplicationIntroduceYourself';

        this.Form = {
            CharacterDescription: new DynamicField({
                formGroup: this.formGroup,
                label: 'Character Description',
                name: 'CharacterDescription',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(3000)],
                validators: { 'maxlength': 3000 },
                value: this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('CharacterDescription') && this.applicationIntroduceYourself.CharacterDescription !== null ? this.applicationIntroduceYourself.CharacterDescription.toString() : '',
            }),
            Complete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complete',
                name: 'Complete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('Complete') && this.applicationIntroduceYourself.Complete !== null ? this.applicationIntroduceYourself.Complete : false,
            }),
            Submitted: new DynamicField({
                formGroup: this.formGroup,
                label: 'The information above is correct.',
                name: 'Submitted',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: false,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.applicationIntroduceYourself && this.applicationIntroduceYourself.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.applicationIntroduceYourself && this.applicationIntroduceYourself.DateModified || null,
            }),
            ExerciseRoutine: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exercise Routine',
                name: 'ExerciseRoutine',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(3000)],
                validators: { 'maxlength': 3000 },
                value: this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('ExerciseRoutine') && this.applicationIntroduceYourself.ExerciseRoutine !== null ? this.applicationIntroduceYourself.ExerciseRoutine.toString() : '',
            }),
            Favorites: new DynamicField({
                formGroup: this.formGroup,
                label: 'Favorites',
                name: 'Favorites',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(3000)],
                validators: { 'maxlength': 3000 },
                value: this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('Favorites') && this.applicationIntroduceYourself.Favorites !== null ? this.applicationIntroduceYourself.Favorites.toString() : '',
            }),
            Goals: new DynamicField({
                formGroup: this.formGroup,
                label: 'Goals',
                name: 'Goals',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(3000)],
                validators: { 'maxlength': 3000 },
                value: this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('Goals') && this.applicationIntroduceYourself.Goals !== null ? this.applicationIntroduceYourself.Goals.toString() : '',
            }),
            LanguagesSpoken: new DynamicField({
                formGroup: this.formGroup,
                label: 'LanguagesSpoken',
                name: 'LanguagesSpoken',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(3000)],
                validators: { 'maxlength': 3000 },
                value: this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('LanguagesSpoken') && this.applicationIntroduceYourself.LanguagesSpoken !== null ? this.applicationIntroduceYourself.LanguagesSpoken.toString() : '',
            }),
            TalentsAbilities: new DynamicField({
                formGroup: this.formGroup,
                label: 'Talents Abilities',
                name: 'TalentsAbilities',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(3500)],
                validators: { 'maxlength': 3500 },
                value: this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('TalentsAbilities') && this.applicationIntroduceYourself.TalentsAbilities !== null ? this.applicationIntroduceYourself.TalentsAbilities.toString() : '',
            }),
            WhyInterestedInBecomingEggDonor: new DynamicField({
                formGroup: this.formGroup,
                label: 'Why Interested In Becoming Egg Donor',
                name: 'WhyInterestedInBecomingEggDonor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(3000)],
                validators: { 'maxlength': 3000 },
                value: this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('WhyInterestedInBecomingEggDonor') && this.applicationIntroduceYourself.WhyInterestedInBecomingEggDonor !== null ? this.applicationIntroduceYourself.WhyInterestedInBecomingEggDonor.toString() : '',
            }),
        };

        this.View = {
            CharacterDescription: new DynamicLabel(
                'Character Description',
                this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('CharacterDescription') && this.applicationIntroduceYourself.CharacterDescription !== null ? this.applicationIntroduceYourself.CharacterDescription.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Complete: new DynamicLabel(
                'Complete',
                this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('Complete') && this.applicationIntroduceYourself.Complete !== null ? this.applicationIntroduceYourself.Complete : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Submitted: new DynamicLabel(
                'Submitted',
                this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('Submitted') && this.applicationIntroduceYourself.Submitted !== null ? this.applicationIntroduceYourself.Submitted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.applicationIntroduceYourself && this.applicationIntroduceYourself.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.applicationIntroduceYourself && this.applicationIntroduceYourself.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ExerciseRoutine: new DynamicLabel(
                'Exercise Routine',
                this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('ExerciseRoutine') && this.applicationIntroduceYourself.ExerciseRoutine !== null ? this.applicationIntroduceYourself.ExerciseRoutine.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Favorites: new DynamicLabel(
                'Favorites',
                this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('Favorites') && this.applicationIntroduceYourself.Favorites !== null ? this.applicationIntroduceYourself.Favorites.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Goals: new DynamicLabel(
                'Goals',
                this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('Goals') && this.applicationIntroduceYourself.Goals !== null ? this.applicationIntroduceYourself.Goals.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LanguagesSpoken: new DynamicLabel(
                'Languages Spoken',
                this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('LanguagesSpoken') && this.applicationIntroduceYourself.LanguagesSpoken !== null ? this.applicationIntroduceYourself.LanguagesSpoken.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TalentsAbilities: new DynamicLabel(
                'Talents Abilities',
                this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('TalentsAbilities') && this.applicationIntroduceYourself.TalentsAbilities !== null ? this.applicationIntroduceYourself.TalentsAbilities.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            WhyInterestedInBecomingEggDonor: new DynamicLabel(
                'Why Interested In Becoming Egg Donor',
                this.applicationIntroduceYourself && this.applicationIntroduceYourself.hasOwnProperty('WhyInterestedInBecomingEggDonor') && this.applicationIntroduceYourself.WhyInterestedInBecomingEggDonor !== null ? this.applicationIntroduceYourself.WhyInterestedInBecomingEggDonor.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
