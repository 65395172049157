import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ClaimValues } from '@mt-ng2/auth-module';
import { DonorDetailComponent } from './donor-detail/donor-detail.component';
import { DonorService } from './donor.service';

import { ClaimTypes } from '@model/ClaimTypes';
import { DonorAccountGuard } from './account.guard';

const donorEntityConfig: any = {
    addressesPath: 'addresses',
    claimType: ClaimTypes.DonorApplication,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    documentsPath: 'documents',
    entityIdParam: 'donorId',
    notesPath: '',
    service: DonorService,
    title: 'Donor Detail',
};

const donorRoutes: Routes = [
    {
        canActivate: [DonorAccountGuard],
        component: DonorDetailComponent,
        data: donorEntityConfig,
        path: `account`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(donorRoutes)],
    providers: [DonorAccountGuard],
})
export class DonorRoutingModule {}
