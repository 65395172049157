<ngx-skeleton-loader count="25" *ngIf="!formCreated"></ngx-skeleton-loader>
<div *ngIf="formCreated" [hidden]="!allFormsReady">
    <form *ngIf="metaData" class="miles-form padded row application-section" [formGroup]="familyHistoryForm">
        <h2>Parents & Grandparents</h2>
        <div class="form-section-border">
            <h3>Mother</h3>
        </div>
        <individual-family-history (onFormCreate)="setFormGroup($event, 'MotherHistory')"
            [individual]="familyHistory.MotherHistory" [heightUnits]="heightUnits" [weightUnits]="weightUnits"
            [unitsOfMeasurement]="unitsOfMeasurement" [countries]="countries"
            [metaData]="metaData"></individual-family-history>
        <div class="form-section-border">
            <h3>Father</h3>
        </div>
        <individual-family-history (onFormCreate)="setFormGroup($event, 'FatherHistory')"
            [individual]="familyHistory.FatherHistory" [heightUnits]="heightUnits" [weightUnits]="weightUnits"
            [unitsOfMeasurement]="unitsOfMeasurement" [countries]="countries"
            [metaData]="metaData"></individual-family-history>
        <div class="form-section-border">
            <h3>Maternal Grandmother</h3>
        </div>
        <individual-family-history (onFormCreate)="setFormGroup($event, 'MaternalGrandmotherHistory')"
            [individual]="familyHistory.MaternalGrandmotherHistory" [heightUnits]="heightUnits"
            [weightUnits]="weightUnits" [unitsOfMeasurement]="unitsOfMeasurement" [countries]="countries"
            [metaData]="metaData">
        </individual-family-history>
        <div class="form-section-border">
            <h3>Maternal Grandfather</h3>
        </div>
        <individual-family-history (onFormCreate)="setFormGroup($event, 'MaternalGrandfatherHistory')"
            [individual]="familyHistory.MaternalGrandfatherHistory" [heightUnits]="heightUnits"
            [weightUnits]="weightUnits" [unitsOfMeasurement]="unitsOfMeasurement" [countries]="countries"
            [metaData]="metaData">
        </individual-family-history>
        <div class="form-section-border">
            <h3>Paternal Grandmother</h3>
        </div>
        <individual-family-history (onFormCreate)="setFormGroup($event, 'PaternalGrandmotherHistory')"
            [individual]="familyHistory.PaternalGrandmotherHistory" [heightUnits]="heightUnits"
            [weightUnits]="weightUnits" [unitsOfMeasurement]="unitsOfMeasurement" [countries]="countries"
            [metaData]="metaData">
        </individual-family-history>
        <div class="form-section-border">
            <h3>Paternal Grandfather</h3>
        </div>
        <individual-family-history (onFormCreate)="setFormGroup($event, 'PaternalGrandfatherHistory')"
            [individual]="familyHistory.PaternalGrandfatherHistory" [heightUnits]="heightUnits"
            [weightUnits]="weightUnits" [unitsOfMeasurement]="unitsOfMeasurement" [countries]="countries"
            [metaData]="metaData">
        </individual-family-history>
        <h2>Siblings</h2>
        <div class="input-scaffold__group" [style.width]="'50%'">
            <div class="input-scaffold">
                <div class="input-scaffold__label">Do you have any siblings?</div>
                <dynamic-field [field]="getSiblingsControl()" (valueChanges)="showSiblingsControls($event)"
                    [formGroup]="familyHistoryForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="hasSiblings">
                <dynamic-field [field]="getHowManySiblingsControl()" (valueChanges)="siblingsToAddNumber = $event">
                </dynamic-field>
            </div>
            <div class="input-scaffold" style="display: flex; align-items: center" *ngIf="hasSiblings">
                <button type="button" class="btn tab-selector btn-outline-primary next"
                    (click)="addSiblingsToFamilyHistory()">
                    Add <span *ngIf="familyHistory.SiblingFamilyHistories.length">More </span>Siblings
                </button>
            </div>
        </div>
        <div *ngFor="let sibling of familyHistory.SiblingFamilyHistories; let i = index">
            <div class="form-section-border">
                <h4 class="heading-with-button">
                    Sibling {{ i + 1 }} <button type="button" class="btn btn-sm" (click)="removeSibling(i)"><i
                            class="fa fa-fw fa-times"></i></button>
                </h4>
            </div>
            <individual-family-history (onFormCreate)="addSiblingFormToFormArray($event)" [siblingIndex]="i"
                [individual]="sibling" [heightUnits]="heightUnits" [weightUnits]="weightUnits" [countries]="countries"
                [unitsOfMeasurement]="unitsOfMeasurement" [metaData]="metaData"></individual-family-history>
        </div>
        <div *ngIf="isComplete">
            <div class="form-section-border">
                <h3>Review</h3>
            </div>
            <div>
                <div class="input-scaffold__label" style="font-style: italic;">
                    Please review your answers, above, then confirm that the information is
                    accurate to the best of your knowledge.
                </div>
                <br />
                <dynamic-field id="Submitted" [field]="abstractFamilyHistoryControls.Submitted" [form]="familyHistoryForm">
                </dynamic-field>
                <br />
            </div>
        </div>
    </form>
</div>