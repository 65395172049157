import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, NavigationStart } from '@angular/router';
import { filter, skip } from 'rxjs/operators';

import { PageTitlesService } from '@mt-ng2/page-titles';
import { IPageVisit } from '../model/page-visit';
import { AuthService } from '@mt-ng2/auth-module';

@Injectable()
/**
 * this service is responsible for registering page visits by route and title for use
 * in the links of the recommended pages.  It does this by listening to the router navigation
 * event and the page title change event in the mt-ng2/page-titles package and then sending
 * that information to the API
 */
export class PageVisitsService {
    private pageTitlesService: PageTitlesService;
    private authService: AuthService;
    private router: Router;
    initialized = false;

    private isAuthenticated = false;
    private title = '';

    constructor(private http: HttpClient, private injector: Injector) {}

    initialize(): void {
        if (this.initialized) {
            return;
        }
        this.pageTitlesService = this.injector.get(PageTitlesService);
        this.pageTitlesService.setPageTitleCalled.subscribe((title) => (this.title = title));

        this.authService = this.injector.get(AuthService);
        this.authService.currentUser.subscribe((currentUser) => {
            this.isAuthenticated = currentUser && currentUser.Id > 0 ? true : false;
        });

        this.router = this.injector.get(Router);
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart), // only get navigaiton start events
                skip(1), // the first one is always empty, so just skip it
            )
            .subscribe(() => {
                // ensure that we are authenticated, cause the endpoint requires a valid token
                // and that we have a title, since without one we don't call the API at all
                if (!(this.isAuthenticated && this.title)) {
                    return;
                }
                const routerUrl = this.router.url;
                this.sendPageVisit(routerUrl, this.title);
                // clear out the title so we can ensure this title won't get reused on the next
                // page, if that page didn't set a new title
                this.title = null;
            });

        this.initialized = true;
    }

    sendPageVisit(routerUrl: string, title: string): void {
        const pageVisit: IPageVisit = {
            Route: routerUrl,
            Title: title,
        };

        const headers = new HttpHeaders({ ignoreProgressBar: '' });
        this.http.post<IPageVisit>(`/pageVisits`, pageVisit, { headers: headers }).subscribe();
    }
}
