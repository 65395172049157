import { Component, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { AppStateService } from '../common/services/app-state.service';
import { finalize } from 'rxjs/operators';
import { LoginOldStyleComponent } from '@mt-ng2/login-module';

@Component({
    selector: 'login-override',
    styles: [``],
    templateUrl: 'login-override.component.html',
})
export class LoginOverrideComponent extends LoginOldStyleComponent {
    loginForm: FormGroup;
    doubleClickDisabled = false;
    constructor(injector: Injector, private appStateService: AppStateService) {
        super(injector);
    }

    onLogin(): void {
        if (this.loginForm.valid) {
            const values = this.loginForm.value;
            this.authService
                .login(values.username, values.password, values.rememberMe)
                .pipe(finalize(() => (this.doubleClickDisabled = false)))
                .subscribe(
                    (successResponse) => {
                        this.notificationsService.success('Login Successful');
                        this.router.navigate([]);
                        this.appStateService.resolveAppState();
                    },
                    (errorResponse) => {
                        if (errorResponse.status === 418) {
                            if (errorResponse.error === 'DomainLoginEmailSent') {
                                this.notificationsService.success('A login link has been emailed to you');
                            } else {
                                this.notificationsService.error('Email/Password is not correct');
                            }
                        }
                    },
                );
        } else {
            this.notificationsService.error('Please check form and try again.');
            setTimeout(() => (this.doubleClickDisabled = false));
            markAllFormFieldsAsTouched(this.loginForm);
        }
    }
}
