<div class="row">
    <div class="col-md-2 col-xs-12">
        <div (click)="uploadSelect.click()" ng2FileDrop [uploader]="uploader" class="drop-box" multiple>
            Drop files here
            <br />
            - OR -
            <br />
            Click to upload
        </div>
        <span style="visibility: hidden">
            <input #uploadSelect id="chooseFileInput" multiple type="file" ng2FileSelect [uploader]="uploader" accept=".jpg,.jpeg" />
        </span>
    </div>
    <div class="col-md-10 col-xs-12" style="margin-bottom: 40px">
        <div class="img-container">
            <div *ngFor="let thumb of thumbnails; let i = index" class="img-box">
                <div class="img">
                    <img [src]="thumb.path" />
                    <button type="button" class="trash-button btn btn-sm btn-danger fa fa-times" (click)="removeFile(i)"></button>
                </div>
            </div>
        </div>
    </div>
</div>
