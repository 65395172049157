import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { EnvironmentService } from '@mt-ng2/environment-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService } from '@mt-ng2/auth-module';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { LoginConfig } from '../../libraries/login-config.library';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    logoFull: string;

    protected authService: AuthService;
    protected notificationsService: NotificationsService;
    protected environmentService: EnvironmentService;
    public config: LoginConfig;
    protected pageTitlesService: PageTitlesService;

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.notificationsService = injector.get(NotificationsService);
        this.environmentService = injector.get(EnvironmentService);
        this.config = injector.get(LoginConfig);
        this.pageTitlesService = injector.get(PageTitlesService);
        this.logoFull = `${this.environmentService.config.imgPath}logo-full.png`;
    }

    ngOnInit(): void {
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the login page.  Because you aren't authed!
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        this.createForm();
        this.setPageTitle();
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Forgot Password');
    }

    createForm(): void {
        if (!this.forgotPasswordForm) {
            this.forgotPasswordForm = new FormGroup({});
        }
        this.forgotPasswordForm.addControl('email', new FormControl('', [Validators.required, Validators.email]));
    }

    emailHasError(): boolean {
        const control = this.forgotPasswordForm.get('email');
        return control.errors && (control.touched || control.errors.maxlength);
    }

    showEmailRequiredError(): boolean {
        const control = this.forgotPasswordForm.get('email');
        return control.touched && control.hasError('required');
    }

    showEmailInvalidError(): boolean {
        const control = this.forgotPasswordForm.get('email');
        return control.touched && control.hasError('email');
    }

    onLogin(): void {
        if (this.forgotPasswordForm.valid) {
            const values = this.forgotPasswordForm.value;
            this.authService.forgot(values.email).subscribe(
                (success) => {
                    this.notificationsService.success(this.config.messageOverrides.forgotPasswordSuccess);
                },
                (error) => this.notificationsService.error(this.config.messageOverrides.forgotPasswordFailure),
            );
        } else {
            markAllFormFieldsAsTouched(this.forgotPasswordForm);
        }
    }
}
