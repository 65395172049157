import { Component, Injector, OnDestroy } from '@angular/core';

import { NavService } from '@mt-ng2/nav-module';

import { ForgotPasswordComponent } from '../separated-portal/forgot-password.component';

@Component({
    selector: 'app-forgot-password',
    templateUrl: '../separated-portal/forgot-password.component.html',
})
export class ForgotPasswordOldStyleComponent extends ForgotPasswordComponent implements OnDestroy {
    protected navService: NavService;

    constructor(injector: Injector) {
        super(injector);
        this.navService = injector.get(NavService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.navService.setShowNav(false);
    }

    ngOnDestroy(): void {
        this.navService.setShowNav(true);
    }
}
