<div [ngClass]="hideTrim ? 'container' : 'auth-layout'">
    <div *ngIf="!hideTrim" class="auth-layout__left">
        <img src="../assets/logo-white.png" height="79" width="100" alt="tweb logo" />
    </div>
    <div class="miles-form padded row application-section" [ngClass]="hideTrim ? '' : 'auth-layout__right'"
        *ngIf="formCreated && !isSubmittedAndSaved" [formGroup]="introduceYourselfForm">
        <div [ngClass]="hideTrim ? '' : 'auth-layout__right__children'">
            <div class="auth-layout__form-container" *ngIf="formCreated">
                <form class="miles-form padded row application-section">
                    <div>
                        <h2 class="auth-layout__h2">Introduce Yourself</h2>
                        <div class="input-scaffold__group">
                            <div class="input-scaffold">
                                <p>
                                    This section is an opportunity to introduce yourself to potential parents. Although
                                    this information you provide is not genetic, it is helpful for a potential parent to
                                    connect emotionally with your shared interests, so please be generous and detailed
                                    with your responses. - Please do not include any identifying information.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="introduceYourselfControls.TalentsAbilities"
                                [form]="introduceYourselfForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="introduceYourselfControls.Favorites"
                                [form]="introduceYourselfForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="introduceYourselfControls.CharacterDescription"
                                [form]="introduceYourselfForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="introduceYourselfControls.Goals"
                                [form]="introduceYourselfForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="introduceYourselfControls.ExerciseRoutine"
                                [form]="introduceYourselfForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="introduceYourselfControls.WhyInterestedInBecomingEggDonor"
                                [form]="introduceYourselfForm"></dynamic-field>
                        </div>
                    </div>
                    <div class="input-scaffold__group">
                        <div class="input-scaffold">
                            <dynamic-field [field]="introduceYourselfControls.LanguagesSpoken"
                                [form]="introduceYourselfForm"></dynamic-field>
                        </div>
                    </div>

                    <div *ngIf="isComplete">
                        <div class="form-section-border">
                            <h3>Review</h3>
                        </div>
                        <div>
                            <div class="input-scaffold__label" style="font-style: italic;">
                                Please review your answers, above, then confirm that the information is
                                accurate to the best of your knowledge.
                            </div>
                            <br />
                            <dynamic-field id="Submitted" [field]="introduceYourselfControls.Submitted"
                                [form]="introduceYourselfForm"> </dynamic-field>
                            <br />
                        </div>
                    </div>
                </form>
                <div class="status-tracker-indicator">
                    <status-indicator [currentSection]="currentStatus" (nextTab)="selectTabs(currentSection.nextTab)"
                        (validateForm)="validateForm()">
                    </status-indicator>
                </div>
            </div>

        </div>
    </div>
</div>