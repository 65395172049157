import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IGender } from '@model/interfaces/gender';

@Injectable({
    providedIn: 'root',
})
export class GenderService extends StaticMetaItemService<IGender> {
    constructor(public http: HttpClient) {
        super('GenderService', 'Gender', 'GenderIds', '/options/genders', http);
    }
}
