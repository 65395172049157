import { Component, OnInit } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DonorService } from '../donor-account/donor.service';

@Component({
    selector: 'verify-email',
    templateUrl: 'verify-email.component.html',
})

export class VerifyEmailComponent implements OnInit {
    userId: number;
    doubleClickIsDisabled = false;

    constructor(private authService: AuthService, private donorService: DonorService, private notificationService: NotificationsService) { }

    ngOnInit(): void {
        this.authService.currentUser.subscribe((user) => {
            this.userId = user.Id;
        });
    }

    SendVerificationEmail(): void {
        this.donorService.sendVerifyEmail(this.userId).subscribe((resp) => {
            this.notificationService.success('Verification email sent!');
        });
    }
}
