import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { ISelectionChangedEvent, MultiselectItem } from '../libraries/multiselect.library';

@Component({
    selector: 'multiselect',
    styleUrls: ['./multiselect.component.less'],
    templateUrl: './multiselect.component.html',
})
export class MultiselectComponent implements OnInit, OnDestroy {
    @Input() items: MultiselectItem[];
    @Input() placeholder: string;
    @Input() entity: string;
    @Input() showSelectAllButtons = true;
    @Input() filterMode = true;
    @Input() maxToShowInSelectedText: number;
    @Input() disabled = false;
    @Input() autoClose: boolean | 'outside' | 'inside';
    @Output() blur: EventEmitter<any> = new EventEmitter<any>();
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();
    @Output('onSelectionChanged') selectionChanged: EventEmitter<ISelectionChangedEvent> = new EventEmitter<ISelectionChangedEvent>();

    hasFocus = false;

    constructor() {}

    ngOnInit(): void {
        if (!this.placeholder) {
            this.placeholder = this.entity ? `no ${this.entity} selected` : 'nothing selected';
        }
        if (this.maxToShowInSelectedText === undefined) {
            this.maxToShowInSelectedText = 3;
        }
        if (this.autoClose === undefined) {
            this.autoClose = 'outside';
        }
    }

    ngOnDestroy(): void {
        if (this.hasFocus) {
            this.blur.emit();
        }
    }

    get allSelected(): boolean {
        return this.items.every((item) => item.Selected);
    }

    get noneSelected(): boolean {
        return this.items.every((item) => !item.Selected);
    }

    get countOfSelected(): number {
        return this.items.filter((item) => item.Selected).length;
    }

    selectedItemsText(): string {
        if (this.filterMode) {
            if (this.allSelected) {
                return `Any ${this.entity}`;
            }

            if (this.noneSelected) {
                return `${this.entity}`;
            }
        } else {
            if (this.noneSelected) {
                return `<em>${this.placeholder}</em>`;
            }
        }

        if (!this.maxToShowInSelectedText || this.countOfSelected <= this.maxToShowInSelectedText) {
            return this.items
                .filter((item) => item.Selected)
                .map((item) => this.getItemText(item))
                .join(', ');
        }

        let returnText = this.items
            .filter((item) => item.Selected)
            .slice(0, this.maxToShowInSelectedText)
            .map((item) => this.getItemText(item))
            .join(', ');

        return `${returnText} +${this.countOfSelected - this.maxToShowInSelectedText} more`;
    }

    getItemText(item: MultiselectItem): string {
        return item && item.Item ? item.Item.Name : '';
    }

    itemSelected(index: number): void {
        const selectedItem = this.items[index];
        selectedItem.Selected = !selectedItem.Selected;
        this.emitSelectionChangedEvent();
    }

    selectAll(checkAll: boolean): void {
        this.items.forEach((item) => {
            item.Selected = checkAll;
        });
        this.emitSelectionChangedEvent();
    }

    emitSelectionChangedEvent(): void {
        const event: ISelectionChangedEvent = {
            items: [...this.items],
            selectedItems: [...this.items.filter((item) => item.Selected).map((item) => item.Item)],
        };
        this.selectionChanged.emit(event);
    }

    openChange(dropdownOpen: boolean): void {
        if (dropdownOpen) {
            this.hasFocus = true;
            this.focus.emit();
        } else {
            this.hasFocus = false;
            this.blur.emit();
        }
    }
}
