import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';

import { StorageService } from '@mt-ng2/common-classes';
import { EnvironmentService } from '@mt-ng2/environment-module';

import { ILoginToken } from '../libraries/token-service.library';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    tokenKey = '';
    xsrfTokenKey = '';

    refreshTokenInProgress: boolean;

    constructor(private environmentService: EnvironmentService, private storageService: StorageService) {
        const tokenPrefix = `${this.environmentService.config.appName}-${this.environmentService.config.appVersion}`;
        this.tokenKey = tokenPrefix + '-jwt';
        this.xsrfTokenKey = tokenPrefix + '-XSRF-TOKEN';
    }

    /**
     * Return the currently saved token from the cookie
     */
    getTokenFromCookie(): Observable<string> {
        // pull token from cookie
        return from(this.storageService.get(this.tokenKey));
    }

    /**
     * Save the jwt token in the cookie
     */
    saveJwtTokenInCookie(tokenObj: ILoginToken): void {
        if (tokenObj.remember) {
            const cookieExpiration = new Date();
            // save refresh token in cookie for 1 month on each refresh
            // auth token will expire before cookie does but refresh will not
            cookieExpiration.setDate(cookieExpiration.getDate() + 30);
            this.storageService.set(this.tokenKey, JSON.stringify(tokenObj), { expires: cookieExpiration });
        } else {
            this.storageService.set(this.tokenKey, JSON.stringify(tokenObj), { expires: '' });
        }
    }

    /**
     * Return the currently saved xsrf token from the cookie
     */
    getXsrfTokenFromCookie(): Observable<string> {
        // pull token from cookie
        return from(this.storageService.get(this.xsrfTokenKey));
    }

    /**
     * Save the xsrf token in the cookie
     */
    saveXsrfTokenInCookie(tokenObj: string, remember: boolean): void {
        if (remember) {
            const cookieExpiration = new Date();
            cookieExpiration.setDate(cookieExpiration.getDate() + 30);
            this.storageService.set(this.xsrfTokenKey, tokenObj, { expires: cookieExpiration });
        } else {
            this.storageService.set(this.xsrfTokenKey, tokenObj, {
                expires: '',
            });
        }
    }

    /**
     * Clear jwt and xsrf token from cookies and observables
     */
    clearTokens(): void {
        this.storageService.remove(this.tokenKey);
        this.storageService.remove(this.xsrfTokenKey);
    }
}
