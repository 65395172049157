import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ILoggedIn } from '@mt-ng2/auth-module';
import { debounceTime } from 'rxjs/operators';
import { ApplicationService, ICurrentSectionAndStatus } from '../../services/application.service';
import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';
import { ApplicationWhoAreYouDynamicControlsPartial } from '@model/partials/application-who-are-you-partial.form-controls';
import { BirthControlTypes } from '@model/enums/birth-control-types.enum';
import { MultiselectItem } from '@mt-ng2/multiselect-control';
import { IAncestry } from '@model/interfaces/ancestry';
import { AncestryTypes } from '@model/enums/ancestry-type.enum';
import assignFormValues from '../../../common/assign-form-values.library';
import { FormSections } from '@model/enums/form-sections.enum';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { GenderService } from '../../../../admin-portal/recipients/outcomes/service/gender.service';
import { EducationCompletedTypes } from '@model/enums/education-completed-types.enum';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { CommonService } from '../../../common/services/common.service';
import { IPreScreening } from '@model/interfaces/pre-screening';
import { IApplicationWhoAreYouDynamicControlsParameters } from '@model/form-controls/application-who-are-you.form-controls';
import { IUser } from '@model/interfaces/user';
import { SharedFunctionsService } from '../../../../admin-portal/common/services/shared-functions-service';

const CONFIG_CONTROLS = [
    'FirstName',
    'MiddleInitial',
    'LastName',
    'Address1',
    'Address2',
    'City',
    'StateCode',
    'Province',
    'Zip',
    'CountryCode',
    'Dob',
    'PlaceOfBirth',
    'ApplicantPhoneNumber',
    'HasTextCommunication',
    'EmergencyContactName',
    'EmergencyContactNumber',
    'BodyStructureId',
    'EyeColorId',
    'NaturalHairColorId',
    'HairTextureId',
    'SkinTypeId',
    'RaceId',
    'HighSchoolGpaId',
    'CollegeMajor',
    'CollegeGpaId',
    'CurrentOccupation',
];
@Component({
    selector: 'who-are-you',
    styles: [
        `
            .header-small-text {
                font-size:12px!important;
                font-weight: normal;
            }
        `,
    ],
    templateUrl: './who-are-you.component.html',
})
export class WhoAreYouComponent implements OnInit, OnDestroy {
    @Input() whoAreYou: IApplicationWhoAreYou;
    @Input() donor: ILoggedIn;
    @Input() denied: boolean;
    @Input() applicationId: number;
    @Input() isWhoAreYouComplete: Subject<boolean>;
    @Input() preScreening: IPreScreening;

    @Output('validateForm') validateForm: EventEmitter<any> = new EventEmitter<any>();
    @Output() onUserChanging: EventEmitter<IUser> = new EventEmitter<IUser>();

    selectedCountryId: number;
    usId: number;
    states: IStateMetaItem[] = [];
    countries: ICountryMetaItem[] = [];

    selectedTextCommunicationId: number;
    // abstract controls
    abstractApplicationWhoAreYouControls: any;
    whoAreYouForm: FormGroup;
    metaData: IPersonalInformationMetaData;
    doubleClickIsDisabled = false;
    formCreated = false;
    motherAncestries: MultiselectItem[];
    fatherAncestries: MultiselectItem[];
    currentStatus: ICurrentSectionAndStatus = {
        formGroup: null,
        nextTab: null,
        nextTabLabel: 'Submit Application',
        routerLink: null,
        sectionId: FormSections.WhoAreYou,
        status: '',
    };
    sharedFunctionsService: SharedFunctionsService;

    subs = new Subscription();
    isSaved: boolean;

    get fatherAncestrySelected(): boolean {
        return this.fatherAncestries.some((itm) => itm.Selected);
    }

    get motherAncestrySelected(): boolean {
        return this.fatherAncestries.some((itm) => itm.Selected);
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private commonService: CommonService,
        private applicationService: ApplicationService,
        private genderService: GenderService,
        private elementRef: ElementRef,
    ) { }

    @HostListener('window:beforeunload', ['$event'])
    warnOfUnsavedChanges(e): any {
        if (this.whoAreYouForm.dirty && !this.denied) {
            e.returnValue = true;
            return false;
        }
    }

    ngOnInit(): void {
        forkJoin([
            this.commonService.getAllStates(),
            this.commonService.getCountries(),
            this.applicationService.getPersonalInformationMetaData(),
            this.genderService.getAll(),
        ]).subscribe(([states, countries, metaData]) => {
            this.states = this.commonService.getStateMetaItems();
            this.countries = this.commonService.getCountryMetaItems();
            this.metaData = metaData;
            this.motherAncestries = metaData.Ancestries.map(
                (a) =>
                    new MultiselectItem(
                        a,
                        this.whoAreYou.MotherAncestries.some((anc) => anc.Id === a.Id),
                    ),
            );
            this.fatherAncestries = metaData.Ancestries.map(
                (a) =>
                    new MultiselectItem(
                        a,
                        this.whoAreYou.FatherAncestries.some((anc) => anc.Id === a.Id),
                    ),
            );

            this.createForm();
            this.currentStatus.status = this.whoAreYou.Submitted ? 'Submitted' : (this.whoAreYou.Complete ? 'Complete' : 'In Progress');
            this.currentStatus.routerLink = ['application', 'submit'];
            this.applicationService.currentSectionAndStatus.next(this.currentStatus);
            this.selectedTextCommunicationId = this.whoAreYou.HasTextCommunication ? 1 : 2;
            this.usId = this.countries.find((country) => country.CountryCode === 'US').Id;
            if (this.whoAreYou.Complete) {
                this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
            }

            this.sharedFunctionsService = new SharedFunctionsService();

            this.selectedCountryId = this.sharedFunctionsService.getCountryIdByCode(this.whoAreYou.CountryCode, this.countries);
        });
        this.subs.add(
            this.applicationService.applicationSaved$.subscribe((saved) => {
                this.isSaved = saved;
                if (saved && this.whoAreYouForm) {
                    markAllFormFieldsAsTouched(this.whoAreYouForm);
                }
            }),
        );
        this.isWhoAreYouComplete.subscribe((isComplete) => {
            if (!isComplete) {
                setTimeout(() => {
                    this.applicationService.scrollToFirstInvalidControl(this.elementRef);
                    this.notificationsService.error('You must fill out all required sections. Please scroll down to see what was missed.');
                }, 1000);
            } else {
                setTimeout(() => {
                    if (!this.whoAreYou.Submitted) {
                        this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
                        this.notificationsService.success('The form is complete. Please review and confirm your entries.');
                    }
                }, 1000);
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    get otherBirthControlSelected(): boolean {
        const birthControlType = this.whoAreYouForm.get('ApplicationWhoAreYou.BirthControlTypeId');
        return birthControlType ? birthControlType.value === BirthControlTypes.Other : false;
    }

    getHasOtherAncestry(type: string): boolean {
        const control = this.whoAreYouForm.get(`ApplicationWhoAreYou.${type}Ancestries`);
        return control ? control.value.some((a: IAncestry) => a.Id === AncestryTypes.Other) : false;
    }

    optionIsSelected(controlName: string): boolean {
        const control = this.whoAreYouForm.get(`ApplicationWhoAreYou.${controlName}`);
        return control && control.value === 1;
    }

    createForm(): void {
        this.getControls();
        this.whoAreYouForm = this.assignFormGroups();
        this.formCreated = true;
        this.currentStatus.formGroup = this.whoAreYouForm;
        if (this.denied) {
            setTimeout(() => this.whoAreYouForm.disable());
        } else {
            this.subs.add(
                this.whoAreYouForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
                    if (this.whoAreYouForm.dirty) {
                        this.assignFormValues();
                    }

                    if (this.whoAreYouForm.valid && this.phoneNumbersMatch()) {
                        const control = this.whoAreYouForm.get('ApplicationWhoAreYou.Submitted');
                        if (control && control.value) {
                            this.currentStatus.status = 'Submitted';
                            this.whoAreYou.Submitted = true;
                        } else {
                            this.currentStatus.status = 'Complete';
                            this.whoAreYou.Complete = true;
                            this.whoAreYou.Submitted = false;
                        }
                    } else {
                        this.currentStatus.status = 'In Progress';
                        this.whoAreYou.Complete = false;
                    }
                    this.applicationService.currentSectionAndStatus.next(this.currentStatus);
                }),
            );
        }
    }

    ///
    /// Do the applicant phone # and confirm applicant phone # entries match?
    ///
    phoneNumbersMatch(): boolean {
        let match = true;
        if (this.formCreated && this.sharedFunctionsService) {
            match = this.sharedFunctionsService.phonesAreEqual(this.whoAreYou.ApplicantPhoneNumber, this.whoAreYou.ConfirmPhoneNumber);
            }
        return match;
    }

    //
    // Determine if the education level completed includes at least some high school.
    // Used to control wether or not the High School GPA field is displayed.
    //
    highSchoolOrAbove(): boolean {
        return this.hasRequiredEducationCompletedType(EducationCompletedTypes.SomeHighSchool);
    }

    //
    // Determine if the education level completed includes at least some college.
    // Used to control wether or not the College GPA field is displayed.
    //
    collegeOrAbove(): boolean {
        return this.hasRequiredEducationCompletedType(EducationCompletedTypes.SomeSchoolUniversity);
    }

    //
    // Determine if the education level completed includes at least the specified level.
    //
    // NOTE: Eight Grade or Below was added after the other entries and ended up with an ID
    // # that is higher than the existing entries - so it must be explicity tested for.
    //
    hasRequiredEducationCompletedType(minRequiredValue: EducationCompletedTypes): boolean {
        let hasRequiredEducation = false;

        if (this.preScreening.EducationCompletedTypeId >= minRequiredValue && this.preScreening.EducationCompletedTypeId !== EducationCompletedTypes.EigthGradeOrAbove) {
            hasRequiredEducation = true;
        }

        return hasRequiredEducation;
    }

    getControls(): void {
        const additionalParameters = {
            formGroup: 'ApplicationWhoAreYou',
            metaData: this.metaData,
            countries: this.countries,
            states: this.states,
        } as IApplicationWhoAreYouDynamicControlsParameters;

        this.abstractApplicationWhoAreYouControls = new ApplicationWhoAreYouDynamicControlsPartial(this.whoAreYou, this.donor, additionalParameters).Form;
    }

    fatherAncestriesChanged(evt): void {
        this.whoAreYouForm.get('ApplicationWhoAreYou.FatherAncestries').setValue(evt.selectedItems);
        this.whoAreYouForm.get('ApplicationWhoAreYou.FatherAncestries').markAsDirty();
        this.whoAreYouForm.get('ApplicationWhoAreYou.FatherAncestries').markAsTouched();
    }

    motherAncestriesChanged(evt): void {
        this.whoAreYouForm.get('ApplicationWhoAreYou.MotherAncestries').setValue(evt.selectedItems);
        this.whoAreYouForm.get('ApplicationWhoAreYou.MotherAncestries').markAsDirty();
        this.whoAreYouForm.get('ApplicationWhoAreYou.MotherAncestries').markAsTouched();
    }

    //
    // When the country changes, refresh the list of states based on the country selection
    //
    setStatesForCountry(value): void {
        this.selectedCountryId = value;
        this.filterStatesForCounty(value);
    }

    //
    // Return a list of country-specific states
    //
    filterStatesForCounty(countryId: number): void {
        const stateCodeControl = this.abstractApplicationWhoAreYouControls.StateCode;
        const countryCode = this.sharedFunctionsService.getCountryCodeById(countryId, this.countries);
        let options = this.sharedFunctionsService.filterStatesForCounty(countryCode, this.states);
        if (options) {
            stateCodeControl.options = options;
        }
    }

    countryHasStateCodes(): boolean {
        let hasStateCodes = false;
        if (this.sharedFunctionsService) {
            hasStateCodes = this.sharedFunctionsService.countryIdHasStatesList(this.selectedCountryId, this.countries, this.states);
        }
        return hasStateCodes;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ApplicationWhoAreYou: this.fb.group({
                FatherAncestries: this.fb.control(
                    this.fatherAncestries.filter((fa) => fa.Selected).map((i) => i.Item),
                    [Validators.required],
                ),
                MotherAncestries: this.fb.control(
                    this.motherAncestries.filter((ma) => ma.Selected).map((i) => i.Item),
                    [Validators.required],
                ),
            }),
        });
    }

    assignFormValues(): void {
        this.UserChanged();

        assignFormValues(this.whoAreYou, this.whoAreYouForm.get('ApplicationWhoAreYou').value);

        this.whoAreYou.HasTextCommunication = this.selectedTextCommunicationId === 1;
        this.donor.CustomOptions.FirstName = this.whoAreYouForm.get('ApplicationWhoAreYou.FirstName');
        this.donor.CustomOptions.MiddleInitial = this.whoAreYouForm.get('ApplicationWhoAreYou.MiddleInitial');
        this.donor.CustomOptions.LastName = this.whoAreYouForm.get('ApplicationWhoAreYou.LastName');
        this.whoAreYou.Address1 = this.whoAreYouForm.get('ApplicationWhoAreYou.Address1').value;
        this.whoAreYou.Address2 = this.whoAreYouForm.get('ApplicationWhoAreYou.Address2').value;
        this.whoAreYou.City = this.whoAreYouForm.get('ApplicationWhoAreYou.City').value;
        let country = this.whoAreYouForm.get('ApplicationWhoAreYou.CountryCode').value;

        this.whoAreYou.CountryCode =
            country !== null
                ? this.countries.find((c) => c.Id === Number(country)).CountryCode
                : null;
        if (this.countryHasStateCodes()) {
            const state = this.states.find((state) => state.Id === Number(this.whoAreYouForm.get('ApplicationWhoAreYou.StateCode').value));
            this.whoAreYou.StateCode = state ? state.StateCode : null;

        } else {
            this.whoAreYou.Province = this.whoAreYouForm.get('ApplicationWhoAreYou.Province').value;
        }

        country = this.whoAreYouForm.get('ApplicationWhoAreYou.PlaceOfBirth').value;
        this.whoAreYou.PlaceOfBirth =
            country !== null
                ? this.countries.find((c) => c.Id === Number(country)).CountryCode
                : null;

        this.whoAreYou.Zip = this.whoAreYouForm.get('ApplicationWhoAreYou.Zip').value;
        this.whoAreYou.ApplicantPhoneNumber = this.whoAreYouForm.get('ApplicationWhoAreYou.ApplicantPhoneNumber').value;
        this.whoAreYou.EmergencyContactName = this.whoAreYouForm.get('ApplicationWhoAreYou.EmergencyContactName').value;
        this.whoAreYou.EmergencyContactNumber = this.whoAreYouForm.get('ApplicationWhoAreYou.EmergencyContactNumber').value;
        this.whoAreYou.FatherAncestries = this.whoAreYouForm.get('ApplicationWhoAreYou.FatherAncestries').value;
        this.whoAreYou.MotherAncestries = this.whoAreYouForm.get('ApplicationWhoAreYou.MotherAncestries').value;
    }

    UserChanged(): void {
        const user = {
            Id: this.donor.Id,
            AuthUserId: this.donor.AuthId,
            Email: this.donor.Email,
            FirstName: this.whoAreYouForm.get('ApplicationWhoAreYou.FirstName').value,
            MiddleInitial: this.whoAreYouForm.get('ApplicationWhoAreYou.MiddleInitial').value,
            LastName: this.whoAreYouForm.get('ApplicationWhoAreYou.LastName').value,
        } as IUser;
        this.onUserChanging.emit(user);
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    get isComplete(): boolean {
        return this.whoAreYou.Complete;
    }

    stateControlCreated(e: FormControl): void {
        if (this.denied) {
            e.disable();
        }
        this.whoAreYouForm.removeControl('Province');
        this.whoAreYouForm.addControl('State', e);
    }

    provinceControlCreated(e: FormControl): void {
        if (this.denied) {
            e.disable();
        }
        this.whoAreYouForm.removeControl('State');
        this.whoAreYouForm.addControl('Province', e);
    }
}
