import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormSections } from '@model/enums/form-sections.enum';
import { IApplication } from '@model/interfaces/application';
import { Subscription } from 'rxjs';
import { ApplicationService, ICurrentSectionAndStatus } from '../services/application.service';

@Component({
    selector: 'status-indicator',
    templateUrl: 'status-indicator.component.html',
})
export class StatusIndicatorComponent implements OnInit, OnDestroy {
    get formIsDirty(): boolean {
        return this.currentSection.formGroup ? this.currentSection.formGroup.dirty : false;
    }
    get setControlsAreValid(): boolean {
        return false;
    }
    @Input() application: IApplication;
    @Input() currentSection: ICurrentSectionAndStatus;
    @Output('nextTab') nextTab: EventEmitter<any> = new EventEmitter<any>();
    @Output('validateForm') validateForm: EventEmitter<any> = new EventEmitter<any>();
    currentWindowWidth: number;

    subs = new Subscription();

    @HostListener('window:resize') onResize(): void {
        this.currentWindowWidth = window.innerWidth;
    }

    constructor(private router: Router, private applicationService: ApplicationService) {}

    ngOnInit(): void {
        this.currentWindowWidth = window.innerWidth;
        this.subs.add(
            this.applicationService.applicationSaved$.subscribe((save) => {
                if (save !== null && this.currentSection.status === 'Submitted') {
                    this.navigate();
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    navigate(): void {
        // don't want to run all formed fields as touched directly on navigation
        this.applicationService.applicationSaved$.next(null);
        if (this.currentSection.sectionId !== FormSections.WhoAreYou) {
            this.nextTab.emit();
        } else {
            this.router.navigate(this.currentSection.routerLink);
        }
    }

    saveApplication(): void {
        setTimeout(() => this.validateForm.emit(), 100);
    }

    tabsDisabled(): boolean {
        return this.currentSection.status === 'In Progress' || this.formIsDirty;
    }
}
