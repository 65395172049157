import { FormGroup, AbstractControl } from '@angular/forms';

import { ILoggedIn, ILoginResponse } from '../models/auth-service.models';
import { ILoginToken } from './token-service.library';

const defaultLoggedInUser: ILoggedIn = {
    AuthId: 0,
    ClaimValues: {},
    CustomOptions: {},
    Email: '',
    Id: 0,
    Name: '',
    ProfileImagePath: 'default.png',
};

export function getDefaultLoggedInUser(): ILoggedIn {
    return { ...defaultLoggedInUser };
}

/***
 * This will fill the default value for customoptions and stich the @see{@link ILoginResponse}
 * properties custom options together to make a single custom object for the
 * front end to work with.
 * @param data
 */
export function stitchCustomOptions(data: ILoginResponse): {} {
    data.LoginResult.CustomOptions = data.LoginResult.CustomOptions || {};
    data.UserDetails.CustomOptions = data.UserDetails.CustomOptions || {};
    const customOptions = {
        ...data.LoginResult.CustomOptions,
        ...data.UserDetails.CustomOptions,
    };
    return customOptions;
}

export function matchPassword(form: FormGroup, formname = 'AuthUser'): boolean {
    let checkform = formname !== null ? form.get(formname) : form;
    let password = checkform.value.Password;
    let confirmPassword = checkform.value.ConfirmPassword;
    if (password !== confirmPassword) {
        flipPasswordsErrors(checkform, true);
        return false;
    } else {
        flipPasswordsErrors(checkform, null);
    }
    return true;
}

function flipPasswordsErrors(form: AbstractControl, value: boolean): void {
    let confirmPasswordForm = form.get('ConfirmPassword');
    let passwordForm = form.get('ConfirmPassword');

    confirmPasswordForm.setErrors({ matchPassword: value });
    passwordForm.setErrors({ matchPassword: value });

    confirmPasswordForm.updateValueAndValidity();
    passwordForm.updateValueAndValidity();
}

export function getLoggedInFromToken(token: ILoginToken): ILoggedIn {
    return {
        AuthId: token.authUserId,
        ClaimValues: token.claimValues,
        CustomOptions: token.customOptions,
        Email: token.email,
        Id: token.userId,
        Name: token.name,
        ProfileImagePath: token.img,
    };
}
