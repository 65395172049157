import { AbstractControl, Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    LabelPositions,
    LabelPosition,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IPreScreening } from '../interfaces/pre-screening';
import { IUnitsOfMeasurement } from '../interfaces/units-of-measurement';
import { IReferralType } from '../interfaces/referral-type';
import { IGender } from '../interfaces/gender';
import { IYesNoOption } from '../interfaces/yes-no-option';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { IEducationCompletedType } from '@model/interfaces/education-completed-type';
import { ICountryMetaItem } from '../interfaces/custom/country-meta-item';
import { UnitsOfMeasurementEnums } from '../enums/units-of-measurement.enum';

export interface IPreScreeningDynamicControlsParameters {
    formGroup?: string;
    countries?: ICountryMetaItem[]
    states?: IStateMetaItem[];
    heightUnits?: IUnitsOfMeasurement[];
    weightUnits?: IUnitsOfMeasurement[];
    referralTypes?: IReferralType[];
    educationCompletedTypes: IEducationCompletedType[];
    genders?: IGender[];
}

export class PreScreeningDynamicControls {

    formGroup?: string;
    countries?: ICountryMetaItem[];
    states?: IStateMetaItem[];
    heightUnits?: IUnitsOfMeasurement[];
    weightUnits?: IUnitsOfMeasurement[];
    referralTypes?: IReferralType[];
    genders?: IGender[];
    educationCompletedTypes: IEducationCompletedType[];
    yesOrNo?: IYesNoOption[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private preScreening?: IPreScreening, additionalParameters?: IPreScreeningDynamicControlsParameters) {

        this.formGroup = additionalParameters && additionalParameters.formGroup || 'PreScreening';
        this.countries = additionalParameters && additionalParameters.countries || undefined;
        this.states = additionalParameters && additionalParameters.states || undefined;
        this.heightUnits = additionalParameters && additionalParameters.heightUnits || undefined;
        this.weightUnits = additionalParameters && additionalParameters.weightUnits || undefined;
        this.referralTypes = additionalParameters && additionalParameters.referralTypes || undefined;
        this.genders = additionalParameters && additionalParameters.genders || undefined;
        this.educationCompletedTypes = additionalParameters && additionalParameters.educationCompletedTypes || undefined;

        this.yesOrNo = [{ Id: 1, Name: 'Yes' }, { Id: 0, Name: 'No' }];

        this.Form = {
            BirthYear: new DynamicField({
                formGroup: this.formGroup,
                label: 'Year of Birth',
                name: 'BirthYear',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                insideBoxValidation: true,
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.BirthYear || null,
            }),
            ConfirmEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Confirm Email',
                name: 'ConfirmEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                    scale: null,
                }),
                insideBoxValidation: true,
                validation: [Validators.maxLength(75), Validators.email, Validators.required],
                validators: { 'maxlength': 75, 'email': true, 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('ConfirmEmail') && this.preScreening.ConfirmEmail !== null && this.preScreening.ConfirmEmail !== undefined ? this.preScreening.ConfirmEmail.toString() : '',
            }),
            Complete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complete',
                name: 'Complete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.preScreening && this.preScreening.hasOwnProperty('Complete') && this.preScreening.Complete !== null ? this.preScreening.Complete : false,
            }),
            CountryCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Country where you Live',
                name: 'CountryCode',
                options: this.countries,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.Dropdown,
                    scale: null,
                }),
                validation: [Validators.maxLength(3), Validators.required],
                validators: { 'maxlength': 3, 'required' : true },
                value: this.getCountryId(),
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.DateModified || null,
            }),
            Denied: new DynamicField({
                formGroup: this.formGroup,
                label: 'Denied.',
                name: 'Denied',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: false,
            }),
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                    scale: null,
                }),
                insideBoxValidation: true,
                validation: [Validators.maxLength(75), Validators.email, Validators.required],
                validators: { 'maxlength': 75, 'email': true, 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('Email') && this.preScreening.Email !== null ? this.preScreening.Email.toString() : '',
            }),
            EducationCompletedTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Highest Level of Education Completed',
                name: 'EducationCompletedTypeId',
                options: this.educationCompletedTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('EducationCompletedTypeId') && this.preScreening.EducationCompletedTypeId !== null ? this.preScreening.EducationCompletedTypeId : null,
            }),
            GenderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Gender',
                name: 'GenderId',
                options: this.genders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('GenderId') && this.preScreening.GenderId !== null ? this.preScreening.GenderId : null,
            }),
            HasBothOvaries: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Both Ovaries',
                name: 'HasBothOvaries',
                options: this.yesOrNo,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('HasBothOvaries') && this.preScreening.HasBothOvaries !== null ? (this.preScreening.HasBothOvaries ? 1 : 0) : null,
            }),
            HasFamilyMedicalHistory: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Family Medical History',
                name: 'HasFamilyMedicalHistory',
                options: this.yesOrNo,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('HasFamilyMedicalHistory') && this.preScreening.HasFamilyMedicalHistory !== null ? (this.preScreening.HasFamilyMedicalHistory ? 1 : 0) : null,
            }),
            HasHiv: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Hiv',
                name: 'HasHiv',
                options: this.yesOrNo,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('HasHiv') && this.preScreening.HasHiv !== null ? (this.preScreening.HasHiv ? 1 : 0) : null,
            }),
            HasGivenBirth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Given Birth',
                name: 'HasGivenBirth',
                options: this.yesOrNo,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('HasGivenBirth') && this.preScreening.HasGivenBirth !== null ? (this.preScreening.HasGivenBirth ? 1 : 0) : null,
            }),
            HasPreviouslyDonatedEggs: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Previously Donated Eggs',
                name: 'HasPreviouslyDonatedEggs',
                options: this.yesOrNo,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('HasPreviouslyDonatedEggs') && this.preScreening.HasPreviouslyDonatedEggs !== null ? (this.preScreening.HasPreviouslyDonatedEggs ? 1 : 0) : null,
            }),
            HasRegularPeriodsWithoutBirthControl: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Regular Periods Without Birth Control',
                name: 'HasRegularPeriodsWithoutBirthControl',
                options: this.yesOrNo,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('HasRegularPeriodsWithoutBirthControl') && this.preScreening.HasRegularPeriodsWithoutBirthControl !== null ? (this.preScreening.HasRegularPeriodsWithoutBirthControl ? 1 : 0) : null,
            }),
            HasStd: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Std',
                name: 'HasStd',
                options: this.yesOrNo,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('HasStd') && this.preScreening.HasStd !== null ? (this.preScreening.HasStd ? 1 : 0) : null,
            }),
            Height: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height',
                name: 'Height',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Float,
                    scale: 2,
                }),
                insideBoxValidation: true,
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.Height || null,
            }),
            HeightUnitId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height Unit',
                name: 'HeightUnitId',
                options: this.heightUnits,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.HeightUnitId || null,
            }),
            HowManyLiveBirths: new DynamicField({
                formGroup: this.formGroup,
                label: 'How many live births have you had and what is the year of birth and gender?',
                name: 'HowManyLiveBirths',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                insideBoxValidation: true,
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.HowManyLiveBirths || null,
            }),
            IsAdopted: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Adopted',
                name: 'IsAdopted',
                options: this.yesOrNo,
                labelPosition: new LabelPosition({ position: LabelPositions.Top }),
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('IsAdopted') && this.preScreening.IsAdopted !== null ? (this.preScreening.IsAdopted ? 1 : 0) : null,
            }),
            IsPregnantOrBreastFeeding: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Pregnant or Breast Feeding',
                name: 'IsPregnantOrBreastFeeding',
                options: this.yesOrNo,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('IsPregnantOrBreastFeeding') && this.preScreening.IsPregnantOrBreastFeeding !== null ? (this.preScreening.IsPregnantOrBreastFeeding ? 1 : 0) : null,
            }),
            IsUsCitizenOrGreenCardHolder: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is US Citizen or Green Card Holder',
                name: 'IsUsCitizenOrGreenCardHolder',
                options: this.yesOrNo,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('IsUsCitizenOrGreenCardHolder') && this.preScreening.IsUsCitizenOrGreenCardHolder !== null ? (this.preScreening.IsUsCitizenOrGreenCardHolder ? 1 : 0) : null,
            }),
            OtherReferral: new DynamicField({
                formGroup: this.formGroup,
                label: 'Referral Source',
                name: 'OtherReferral',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                    scale: null,
                }),
                insideBoxValidation: true,
                validation: [Validators.maxLength(100), Validators.required],
                validators: { 'maxlength': 100, 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('OtherReferral') && this.preScreening.OtherReferral !== null && this.preScreening.OtherReferral !== undefined ? this.preScreening.OtherReferral.toString() : '',
            }),
            Province: new DynamicField({
                formGroup: this.formGroup,
                label: 'Province',
                name: 'Province',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                    scale: null,
                }),
                insideBoxValidation: true,
                validation: [Validators.maxLength(50), Validators.required],
                validators: { 'maxlength': 50, 'required': true },
                value: this.preScreening && this.preScreening.hasOwnProperty('Province') && this.preScreening.Province !== null && this.preScreening.Province !== undefined ? this.preScreening.Province.toString() : '',
            }),
            ReferralTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Where did you hear about us?',
                name: 'ReferralTypeId',
                options: this.referralTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.ReferralTypeId || null,
            }),
            StateCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'State/province where you Live',
                name: 'StateCode',
                options: this.getStatesList(),
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.Dropdown,
                    scale: null,
                }),
                validation: [Validators.maxLength(3), Validators.required],
                validators: { 'maxlength': 3, 'required': true },
                value: this.getStateId(),
            }),
            Submitted: new DynamicField({
                formGroup: this.formGroup,
                label: 'The information above is correct.',
                name: 'Submitted',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: false,
            }),
            Weight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight',
                name: 'Weight',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                insideBoxValidation: true,
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.Weight || null,
            }),
            WeightUnitId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight Unit',
                name: 'WeightUnitId',
                options: this.weightUnits,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.RadioButtonList,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: this.preScreening && this.preScreening.WeightUnitId || null,
            }),
        };

        this.View = {
            BirthYear: new DynamicLabel(
                'Birth Year',
                this.preScreening && this.preScreening.BirthYear || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ConfirmEmail: new DynamicLabel(
                'Confirm Email',
                this.preScreening && this.preScreening.hasOwnProperty('ConfirmEmail') && this.preScreening.ConfirmEmail !== null && this.preScreening.ConfirmEmail !== undefined ? this.preScreening.ConfirmEmail.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Complete: new DynamicLabel(
                'Complete',
                this.preScreening && this.preScreening.hasOwnProperty('Complete') && this.preScreening.Complete !== null ? this.preScreening.Complete : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            CountryCode: new DynamicLabel(
                'Country',
                this.preScreening.CountryCode ? this.countries.find((country) => country.CountryCode === this.preScreening.CountryCode).Name : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.preScreening && this.preScreening.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.preScreening && this.preScreening.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Denied: new DynamicLabel(
                'Denied',
                this.preScreening && this.preScreening.hasOwnProperty('Denied') && this.preScreening.Denied !== null ? this.preScreening.Denied : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Email: new DynamicLabel(
                'Email',
                this.preScreening && this.preScreening.hasOwnProperty('Email') && this.preScreening.Email !== null ? this.preScreening.Email.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            EducationCompletedTypeId: new DynamicLabel(
                'Education Completed',
                getMetaItemValue(this.educationCompletedTypes, this.preScreening && this.preScreening.hasOwnProperty('EducationCompletedTypeId') && this.preScreening.EducationCompletedTypeId !== null ? this.preScreening.EducationCompletedTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            GenderId: new DynamicLabel(
                'Gender',
                getMetaItemValue(this.genders, this.preScreening && this.preScreening.hasOwnProperty('GenderId') && this.preScreening.GenderId !== null ? this.preScreening.GenderId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasBothOvaries: new DynamicLabel(
                'Has Both Ovaries',
                this.preScreening && this.preScreening.hasOwnProperty('HasBothOvaries') && this.preScreening.HasBothOvaries !== null ? this.preScreening.HasBothOvaries : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasFamilyMedicalHistory: new DynamicLabel(
                'Has Family Medical History',
                this.preScreening && this.preScreening.hasOwnProperty('HasFamilyMedicalHistory') && this.preScreening.HasFamilyMedicalHistory !== null ? this.preScreening.HasFamilyMedicalHistory : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasHiv: new DynamicLabel(
                'Has Hiv',
                this.preScreening && this.preScreening.hasOwnProperty('HasHiv') && this.preScreening.HasHiv !== null ? this.preScreening.HasHiv : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasGivenBirth: new DynamicLabel(
                'Has Given Birth',
                this.preScreening && this.preScreening.hasOwnProperty('HasGivenBirth') && this.preScreening.HasGivenBirth !== null ? this.preScreening.HasGivenBirth : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasPreviouslyDonatedEggs: new DynamicLabel(
                'Has Previously Donated Eggs',
                this.preScreening && this.preScreening.hasOwnProperty('HasPreviouslyDonatedEggs') && this.preScreening.HasPreviouslyDonatedEggs !== null ? this.preScreening.HasPreviouslyDonatedEggs : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasRegularPeriodsWithoutBirthControl: new DynamicLabel(
                'Has Regular Periods w/o Birth Control',
                this.preScreening && this.preScreening.hasOwnProperty('HasRegularPeriodsWithoutBirthControl') && this.preScreening.HasRegularPeriodsWithoutBirthControl !== null ? this.preScreening.HasRegularPeriodsWithoutBirthControl : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasStd: new DynamicLabel(
                'Has Std',
                this.preScreening && this.preScreening.hasOwnProperty('HasStd') && this.preScreening.HasStd !== null ? this.preScreening.HasStd : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Height: new DynamicLabel(
                'Height',
                this.getHeight(),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HeightUnitId: new DynamicLabel(
                'Height Unit',
                getMetaItemValue(this.heightUnits, this.preScreening && this.preScreening.hasOwnProperty('Height') && this.preScreening.Height !== null ? this.preScreening.Height : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            HowManyLiveBirths: new DynamicLabel(
                '# of Live Births',
                this.preScreening && this.preScreening.HowManyLiveBirths || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsAdopted: new DynamicLabel(
                'Is Adopted',
                this.preScreening && this.preScreening.hasOwnProperty('IsAdopted') && this.preScreening.IsAdopted !== null ? this.preScreening.IsAdopted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsPregnantOrBreastFeeding: new DynamicLabel(
                'Is Pregnant or Breast Feeding',
                this.preScreening && this.preScreening.hasOwnProperty('IsPregnantOrBreastFeeding') && this.preScreening.IsPregnantOrBreastFeeding !== null ? this.preScreening.IsPregnantOrBreastFeeding : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsUsCitizenOrGreenCardHolder: new DynamicLabel(
                'Is US Citizen or Green Card Holder',
                this.preScreening && this.preScreening.hasOwnProperty('IsUsCitizenOrGreenCardHolder') && this.preScreening.IsUsCitizenOrGreenCardHolder !== null ? this.preScreening.IsUsCitizenOrGreenCardHolder : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            OtherReferral: new DynamicLabel(
                'Referral Source',
                this.preScreening && this.preScreening.hasOwnProperty('OtherReferral') && this.preScreening.OtherReferral !== null && this.preScreening.OtherReferral !== undefined ? this.preScreening.OtherReferral.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Province: new DynamicLabel(
                'Province',
                this.preScreening && this.preScreening.hasOwnProperty('Province') && this.preScreening.Province !== null ? this.preScreening.Province.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReferralTypeId: new DynamicLabel(
                'Where did you hear about us?',
                getMetaItemValue(this.referralTypes, this.preScreening && this.preScreening.hasOwnProperty('ReferralTypeId') && this.preScreening.ReferralTypeId !== null ? this.preScreening.ReferralTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            StateCode: new DynamicLabel(
                'State/province where you Live',
                this.preScreening && this.preScreening.hasOwnProperty('StateCode') && this.preScreening.StateCode !== null ? this.preScreening.StateCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Submitted: new DynamicLabel(
                'Submitted',
                this.preScreening && this.preScreening.hasOwnProperty('Submitted') && this.preScreening.Submitted !== null ? this.preScreening.Submitted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Weight: new DynamicLabel(
                'Weight',
                this.preScreening && this.preScreening.hasOwnProperty('Weight') && this.preScreening.Weight,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            WeightUnitId: new DynamicLabel(
                'Weight Unit',
                getMetaItemValue(this.weightUnits, this.preScreening && this.preScreening.hasOwnProperty('Weight') && this.preScreening.Weight !== null ? this.preScreening.Weight : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
        };
    }

    getHeight(): string {
        let height = null;
        if (this.preScreening
            && this.preScreening.hasOwnProperty('HeightUnitId')
            && this.preScreening.HeightUnitId !== null
            && this.preScreening.hasOwnProperty('Height')
            && this.preScreening.Height !== null) {
            if (this.preScreening.HeightUnitId === UnitsOfMeasurementEnums.Meters) {
                height = this.preScreening.Height;
            } else {
                height = Math.floor(this.preScreening.Height / 12) + '\' ' + this.preScreening.Height % 12 + '"';
            }
        }
        return height;
    }

    getCountryId(): number {
        let Id = null;

        if (this.preScreening && this.preScreening.hasOwnProperty('CountryCode') && this.preScreening.CountryCode !== null) {
            let country = this.countries.find((c) => c.CountryCode.trim() === this.preScreening.CountryCode.trim());
            if (country) {
                Id = country.Id;
            }

        }
        return Id;
    }

    getStateId(): number {
        let Id = null;

        if (this.preScreening && this.preScreening.hasOwnProperty('CountryCode') && this.preScreening.CountryCode !== null) {
            let country = this.countries.find((c) => c.CountryCode.trim() === this.preScreening.CountryCode.trim());
            if (country) {
                if (this.preScreening && this.preScreening.hasOwnProperty('StateCode') && this.preScreening.StateCode !== null) {
                    let state = this.states.find((c) => c.CountryCode.trim() === country.CountryCode.trim() && c.StateCode.trim() === this.preScreening.StateCode.trim());
                    if (state) {
                        Id = state.Id;
                    }
                }
            }
        }
        return Id;
    }

    getStatesList(): IStateMetaItem[] {
        let states = [];
        if (this.preScreening && this.preScreening.hasOwnProperty('CountryCode') && this.preScreening.CountryCode !== null) {
            states = this.states.filter((s) => s.CountryCode.trim() === this.preScreening.CountryCode.trim());
        }
        return states;
    }
}
