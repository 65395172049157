import { BehaviorSubject } from 'rxjs';
import { ClaimValues } from '@mt-ng2/auth-module';

import { IKeyboardShortcutListenerOptions } from '@mt-ng2/keyboard-shortcuts-module';

/**
 * class meant to be extended for custom nav content header component
 * the header property is assigned when the component is instantiated and
 * will contain the header item that this component was created for
 */
export class NavContentDynamicHeaderComponent {
    header: INavSidebarHeaderItem;
}

/**
 * class meant to be extended for custom nav content row component
 * the row property is assigned when the component is instantiated and
 * will contain the row item that this component was created for
 */
export class NavContentDynamicRowComponent {
    row: INavSidebarRowItem;
}

/**
 * interface for a section of content in the sidebar
 * @property header - config for the header for this content
 * @property headerComponent - a component to be rendered in place of the standard header
 * @property rows - array of row items to show in this content
 * @property rowComponent - a component to be rendered in place of the standard row item
 */
export interface INavSidebarContent {
    header?: INavSidebarHeaderItem;
    headerComponent?: any;
    rows: INavSidebarParentRowItem[] | INavSidebarRowItem[];
    rowComponent?: any;
}

/**
 * class for a section of content in the sidebar
 * @property header - config for the header for this content
 * @property headerComponent - a component to be rendered in place of the standard header
 * @property rows - array of row items to show in this content
 * @property rowComponent - a component to be rendered in place of the standard row item
 */
export class NavSidebarContent implements INavSidebarContent {
    header?: INavSidebarHeaderItem;
    headerComponent?: any;
    rows: INavSidebarParentRowItem[] | INavSidebarRowItem[];
    rowComponent?: any;

    constructor(content: INavSidebarContent) {
        Object.assign(this, content);
    }

    get headerHasComponent(): boolean {
        return this.headerComponent ? true : false;
    }

    get rowHasComponent(): boolean {
        return this.rowComponent ? true : false;
    }
}

/**
 * class that holds a content section in an observable subject, so that
 * any changes can be handled by the sidebar component
 */
export class NavSidebarContentContainer {
    content: BehaviorSubject<NavSidebarContent>;

    constructor(content: INavSidebarContent) {
        this.content = new BehaviorSubject(new NavSidebarContent(content));
    }
}

/**
 * interface for a sidebar service that holds all content sections in an
 * observable subject, so that any changes can be handled by the sidebar component
 */
export interface INavSidebarService {
    content: BehaviorSubject<NavSidebarContentContainer[]>;
}

export interface INavSidebarContentItemBase {
    content: string;
    claimType: number;
    claimValues: number[];
}

export interface INavSidebarExpandable {
    canToggleExpand: boolean;
    expanded: boolean;
}

export class NavSidebarContentItemBase implements INavSidebarContentItemBase {
    content: string;
    claimType: number;
    claimValues: number[];

    constructor(contentItemBase: INavSidebarContentItemBase) {
        Object.assign(this, contentItemBase);
    }
}

export interface INavSidebarRowItem extends INavSidebarContentItemBase {
    icon: string;
    link: string;
    settingsLink?: string;
    settingsClaimType?: number;
    settingsClaimValues?: number[];
    addLink?: string;
    addClaimType?: number;
    addClaimValues?: number[];
    rowComponent?: any;
    shortcut?: IKeyboardShortcutListenerOptions;
}

export interface INavSidebarRowItemConstructor {
    content: string;
    icon?: string;
    link?: string;
    settingsLink?: string;
    settingsClaimType?: number;
    settingsClaimValues?: number[];
    addLink?: string;
    addClaimType?: number;
    addClaimValues?: number[];
    claimType?: number;
    claimValues?: number[];
    rowComponent?: any;
}

export class NavSidebarRowItem implements INavSidebarRowItem {
    icon: string;
    link: string;
    settingsLink: string;
    settingsClaimType: number;
    settingsClaimValues: number[];
    addLink: string;
    addClaimType: number;
    addClaimValues: number[];
    content: string;
    claimType: number;
    claimValues: number[];
    rowComponent: any;
    shortcut: IKeyboardShortcutListenerOptions;

    constructor(rowItem: INavSidebarRowItemConstructor | INavSidebarParentRowItemConstructor) {
        const defaultRowItem: INavSidebarRowItem = {
            addClaimType: null,
            addClaimValues: null,
            addLink: null,
            claimType: null,
            claimValues: null,
            content: null,
            icon: null,
            link: null,
            rowComponent: null,
            settingsClaimType: null,
            settingsClaimValues: null,
            settingsLink: null,
            shortcut: null,
        };
        Object.assign(this, defaultRowItem, rowItem);
        if (this.claimType && !this.claimValues) {
            this.claimValues = [ClaimValues.ReadOnly, ClaimValues.FullAccess];
        }
        if (this.addClaimType && !this.addClaimValues) {
            this.addClaimValues = [ClaimValues.ReadOnly, ClaimValues.FullAccess];
        }
        if (this.settingsClaimType && !this.settingsClaimValues) {
            this.settingsClaimValues = [ClaimValues.ReadOnly, ClaimValues.FullAccess];
        }
    }

    rowHasComponent(rowComponent?: any): boolean {
        return this.rowComponent || rowComponent ? true : false;
    }

    getRowComponent(rowComponent?: any): NavSidebarRowItem {
        return this.rowComponent ? this.rowComponent : rowComponent;
    }
}

export interface INavSidebarParentRowItem extends INavSidebarRowItem, INavSidebarExpandable {
    children: NavSidebarRowItem[];
}

export interface INavSidebarParentRowItemConstructor {
    children: NavSidebarRowItem[];
    content: string;
    icon?: string;
    link?: string;
    canToggleExpand?: boolean;
    expanded?: boolean;
    settingsLink?: string;
    settingsClaimType?: number;
    settingsClaimValues?: number[];
    addLink?: string;
    addClaimType?: number;
    addClaimValues?: number[];
    claimType?: number;
    claimValues?: number[];
    rowComponent?: any;
    shortcut?: IKeyboardShortcutListenerOptions;
}

export class NavSidebarParentRowItem implements INavSidebarParentRowItem {
    canToggleExpand: boolean;
    expanded: boolean;
    children: NavSidebarRowItem[];
    icon: string;
    link: string;
    settingsLink: string;
    settingsClaimType: number;
    settingsClaimValues: number[];
    addLink: string;
    addClaimType: number;
    addClaimValues: number[];
    content: string;
    claimType: number;
    claimValues: number[];
    rowComponent: any;
    shortcut: IKeyboardShortcutListenerOptions;

    constructor(parentRowItem: INavSidebarParentRowItemConstructor) {
        const defaultParentRowItem: INavSidebarParentRowItem = {
            addClaimType: null,
            addClaimValues: null,
            addLink: null,
            canToggleExpand: true,
            children: [],
            claimType: null,
            claimValues: null,
            content: null,
            expanded: true,
            icon: null,
            link: null,
            rowComponent: null,
            settingsClaimType: null,
            settingsClaimValues: null,
            settingsLink: null,
        };
        Object.assign(this, defaultParentRowItem, parentRowItem);
        if (this.claimType && !this.claimValues) {
            this.claimValues = [ClaimValues.ReadOnly, ClaimValues.FullAccess];
        }
        if (this.addClaimType && !this.addClaimValues) {
            this.addClaimValues = [ClaimValues.ReadOnly, ClaimValues.FullAccess];
        }
        if (this.settingsClaimType && !this.settingsClaimValues) {
            this.settingsClaimValues = [ClaimValues.ReadOnly, ClaimValues.FullAccess];
        }
    }

    rowHasComponent(rowComponent?: any): boolean {
        return this.rowComponent || rowComponent ? true : false;
    }

    getRowComponent(rowComponent?: any): NavSidebarRowItem {
        return this.rowComponent ? this.rowComponent : rowComponent;
    }
}

export interface INavSidebarHeaderItem extends INavSidebarContentItemBase, INavSidebarExpandable {}

export interface INavSidebarHeaderItemConstructor {
    canToggleExpand?: boolean;
    content: string;
    claimType?: number;
    claimValues?: number[];
    expanded?: boolean;
}

export class NavSidebarHeaderItem implements INavSidebarHeaderItem {
    canToggleExpand: boolean;
    content: string;
    claimType: number;
    claimValues: number[];

    expanded: boolean;

    constructor(headerItem: INavSidebarHeaderItemConstructor) {
        const defaultHeaderItem: INavSidebarHeaderItem = {
            canToggleExpand: false,
            claimType: null,
            claimValues: null,
            content: null,
            expanded: true,
        };
        Object.assign(this, defaultHeaderItem, headerItem);
        if (this.claimType && !this.claimValues) {
            this.claimValues = [ClaimValues.ReadOnly, ClaimValues.FullAccess];
        }
    }
}
