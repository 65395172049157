import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IUserRole } from '@model/interfaces/user-role';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { AuthService, ILoginResponse } from '@mt-ng2/auth-module';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthEntityService {
    constructor(public http: HttpClient, private environmentService: EnvironmentService, private authService: AuthService) {}

    getAllRoles(): any {
        const searchParams: SearchParams = {
            extraParams: null,
            query: '*',
            skip: 0,
            take: 999,
        };
        const params = this.getHttpParams(searchParams);
        return this.http.get<IUserRole>('/userRoles/_search', { observe: 'response', params: params });
    }

    changeAccess(authUserId: number, hasAccess: boolean): any {
        return this.http.put(`/authUsers/${authUserId}/hasAccess/${hasAccess}`, {});
    }

    updatePortalAccess(authUserId: number, username: string, roleId: number): any {
        return this.http.put(`/authUsers/${authUserId}/portalAccess`, { Username: username, UserRoleId: roleId });
    }

    savePassword(authUserId: number, password: string, oldPassword: string, confirmPassword: string): any {
        return this.http.put(`/authUsers/updatePassword`, {
            AuthUserId: authUserId,
            Confirmation: confirmPassword,
            OldPassword: oldPassword,
            Password: password,
        });
    }

    isAccountLocked(authUserId: number): Observable<boolean> {
        return this.http.get<boolean>(`/authUsers/${authUserId}/lockedStatus`);
    }

    unlockAccount(authUserId: number): any {
        return this.http.post(`/authUsers/${authUserId}/unlock`, {});
    }

    protected getHttpParams(searchparameters: SearchParams): HttpParams {
        let params = new HttpParams();
        if (searchparameters.query) {
            params = params.append('query', searchparameters.query);
        }
        if (searchparameters.skip) {
            params = params.append('skip', searchparameters.skip.toString());
        }
        if (searchparameters.take) {
            params = params.append('take', searchparameters.take.toString());
        }
        if (searchparameters.order) {
            params = params.append('order', searchparameters.order.toString());
        }
        if (searchparameters.orderDirection) {
            params = params.append('orderDirection', searchparameters.orderDirection.toString());
        }
        if (searchparameters.extraParams && searchparameters.extraParams.length > 0) {
            let extraparams = new HttpParams();
            searchparameters.extraParams.forEach((param) => {
                if (param.valueArray) {
                    if (param.valueArray.length > 0) {
                        extraparams = extraparams.append(param.name, param.valueArray.toString());
                    }
                } else {
                    if (param.value.length > 0) {
                        extraparams = extraparams.set(param.name, param.value);
                    }
                }
            });
            if (extraparams.keys().length > 0) {
                params = params.append('extraparams', extraparams.toString());
            }
        }
        return params;
    }

    donorAccess(resetKey: string, userId: number): Observable<ILoginResponse> {
        const data: any = {
            AuthClientID: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            ResetKey: resetKey,
            UserId: userId,
        };
        return this.http
            .post<ILoginResponse>('/authUsers/donorAccess', data, {
                headers: { BypassAuth: 'true' },
            })
            .pipe(tap((response) => this.authService.saveToken(response, false)));
    }

    verifyDonorEmail(validationKey: string, userId: number): Observable<ILoginResponse> {
        const data: any = {
            AuthClientID: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            UserId: userId,
            ValidationKey: validationKey,
        };
        return this.http
            .post<ILoginResponse>('/authUsers/verifyEmail', data, {
                headers: { BypassAuth: 'true' },
            })
            .pipe(tap((response) => this.authService.saveToken(response, false)));
    }
}
