import { of } from 'rxjs';

import { PageVisitsService } from './page-visits.service';

export function pageVisitsServiceInitializerfactory(pageVisitsService: PageVisitsService): any {
    return function(): Promise<any> {
        pageVisitsService.initialize(); // call the initialization function
        return of(1).toPromise(); // need to return a promise ... so there it is :)
    };
}
