import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { MeasurementTypeEnums } from '@model/enums/measurement-types.enum';

@Injectable()
export class UnitsOfMeasurementService extends StaticMetaItemService<IUnitsOfMeasurement> {
    constructor(public http: HttpClient) {
        super('UnitsOfMeasurementService', 'Units of Measurement', 'MeasurementIds', '/options/unitsOfMeasurement', http);
    }

    getHeightUnits(): IUnitsOfMeasurement[] {
        return this.items.filter((unitsOfMeasurement) => unitsOfMeasurement.MeasurementTypeId === MeasurementTypeEnums.HeightAdult);
    }

    getWeightUnits(): IUnitsOfMeasurement[] {
        return this.items.filter((unitsOfMeasurement) => unitsOfMeasurement.MeasurementTypeId === MeasurementTypeEnums.WeightAdult);
    }
}
