import { Component, Input, EventEmitter, Output, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoleRecommendedPageService } from '../../../recommended-pages/user-role-recommended-page.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUserRoleRecommendedPage } from '../../../model/user-role-recommended-page';
import { IModalWrapperApi } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-user-role-recommended-page',
    styles: [
        `
            .fa {
                transition: transform 0.2s;
            }
            .fa:hover {
                transform: scale(1.3);
            }
            .fa-trash {
                margin-right: 15px;
            }
            .no-hover-highlight {
                -webkit-filter: brightness(100%);
            }
        `,
    ],
    templateUrl: './user-role-recommended-page.component.html',
})
export class UserRoleRecommendedPageComponent {
    @Input() recommendedPage: IUserRoleRecommendedPage;
    @Input() userRoleId: number;
    @Output('pageRemoved') pageRemoved: EventEmitter<number> = new EventEmitter<number>();
    @Output('pageAdded') pageAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

    isEditing = false;
    modalApi: IModalWrapperApi;

    constructor(
        public router: Router,
        private userRoleRecommendedPageService: UserRoleRecommendedPageService,
        private notificationsService: NotificationsService,
    ) {}

    savePage(): void {
        this.recommendedPage.Route = this.parseRoute(this.recommendedPage.Route);
        if (this.recommendedPage.Id > 0) {
            this.userRoleRecommendedPageService.update(this.recommendedPage).subscribe((answer) => {
                this.notificationsService.success('Updated Page Successfully');
                this.isEditing = false;
            });
        } else {
            this.recommendedPage.UserRoleId = this.userRoleId;
            this.userRoleRecommendedPageService.create(this.recommendedPage).subscribe((answer) => {
                this.notificationsService.success('Page Created Successfully');
                this.isEditing = false;
                this.recommendedPage.Id = answer;
            });
        }
    }

    parseRoute(url: string): string {
        return url.substring(url.indexOf('#') + 1, url.length);
    }

    cancelEdit(): void {
        if (this.isEditing) {
            this.isEditing = false;
        } else {
            this.pageAdded.emit(false);
        }
    }

    showRemoveModal(): void {
        if (this.modalApi && this.modalApi.show) {
            this.modalApi.show();
        }
    }

    saveAndCloseModal(): void {
        let pageId = this.recommendedPage.Id;
        this.modalApi.close();
        this.userRoleRecommendedPageService.delete(this.recommendedPage.Id).subscribe((answer) => {
            this.notificationsService.success('Removed Page Successfully');
            this.pageRemoved.emit(pageId);
        });
    }

    closeModal(): void {
        this.modalApi.close();
    }
}
