<div *ngIf="currentSection">
    <div class="row">
        <div class="col-xs-2 col-s-2 col-md-2">
        </div>
        <div class="col-xs-8 col-s-8 col-md-8" style="text-align: center;">
            <div class="status-text">Status: {{ currentSection.status }}</div>
            <div>
                <div *ngIf="formIsDirty && currentSection.status !== 'Submitted'">
                    <button class="btn btn-flat btn-danger app-saver-button" type="button" (click)="saveApplication()">
                        Save Application
                    </button>
                </div>
                <div *ngIf="formIsDirty && currentSection.status === 'Submitted'">
                    <button class="btn btn-flat btn-danger app-saver-button" type="button" (click)="saveApplication()">
                        Save and Continue
                    </button>
                </div>
                <div *ngIf="!formIsDirty">
                    <button class="btn btn-flat btn-success app-saver-button" type="button" disabled="true">
                        Application Section Saved
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>