import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IApplicationFamilyHistory } from '../interfaces/application-family-history';
import { IFamilyHistory } from '../interfaces/family-history';

export interface IApplicationFamilyHistoryDynamicControlsParameters {
    formGroup?: string;
    fatherHistories?: IFamilyHistory[];
    motherHistories?: IFamilyHistory[];
    maternalGrandmotherHistories?: IFamilyHistory[];
    maternalGrandfatherHistories?: IFamilyHistory[];
    paternalGrandfatherHistories?: IFamilyHistory[];
    paternalGrandmotherHistories?: IFamilyHistory[];
}

export class ApplicationFamilyHistoryDynamicControls {

    formGroup: string;
    fatherHistories: IFamilyHistory[];
    motherHistories: IFamilyHistory[];
    maternalGrandmotherHistories: IFamilyHistory[];
    maternalGrandfatherHistories: IFamilyHistory[];
    paternalGrandfatherHistories: IFamilyHistory[];
    paternalGrandmotherHistories: IFamilyHistory[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private applicationfamilyhistory?: IApplicationFamilyHistory, additionalParameters?: IApplicationFamilyHistoryDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ApplicationFamilyHistory';
        this.fatherHistories = additionalParameters && additionalParameters.fatherHistories || undefined;
        this.motherHistories = additionalParameters && additionalParameters.motherHistories || undefined;
        this.maternalGrandmotherHistories = additionalParameters && additionalParameters.maternalGrandmotherHistories || undefined;
        this.maternalGrandfatherHistories = additionalParameters && additionalParameters.maternalGrandfatherHistories || undefined;
        this.paternalGrandfatherHistories = additionalParameters && additionalParameters.paternalGrandfatherHistories || undefined;
        this.paternalGrandmotherHistories = additionalParameters && additionalParameters.paternalGrandmotherHistories || undefined;

        this.Form = {
            Complete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complete',
                name: 'Complete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationfamilyhistory && this.applicationfamilyhistory.hasOwnProperty('Complete') && this.applicationfamilyhistory.Complete !== null ? this.applicationfamilyhistory.Complete : false,
            }),
            Submitted: new DynamicField({
                formGroup: this.formGroup,
                label: 'The information above is correct.',
                name: 'Submitted',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: false,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.applicationfamilyhistory && this.applicationfamilyhistory.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationfamilyhistory && this.applicationfamilyhistory.DateModified || null,
            }),
            FatherHistoryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Father History',
                name: 'FatherHistoryId',
                options: this.fatherHistories,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationfamilyhistory && this.applicationfamilyhistory.FatherHistoryId || null,
            }),
            MaternalGrandfatherHistoryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Maternal Grandfather History',
                name: 'MaternalGrandfatherHistoryId',
                options: this.maternalGrandfatherHistories,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationfamilyhistory && this.applicationfamilyhistory.MaternalGrandfatherHistoryId || null,
            }),
            MaternalGrandmotherHistoryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Maternal Grandmother History',
                name: 'MaternalGrandmotherHistoryId',
                options: this.maternalGrandmotherHistories,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationfamilyhistory && this.applicationfamilyhistory.MaternalGrandmotherHistoryId || null,
            }),
            MotherHistoryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mother History',
                name: 'MotherHistoryId',
                options: this.motherHistories,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationfamilyhistory && this.applicationfamilyhistory.MotherHistoryId || null,
            }),
            PaternalGrandfatherHistoryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Paternal Grandfather History',
                name: 'PaternalGrandfatherHistoryId',
                options: this.paternalGrandfatherHistories,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationfamilyhistory && this.applicationfamilyhistory.PaternalGrandfatherHistoryId || null,
            }),
            PaternalGrandmotherHistoryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Paternal Grandmother History',
                name: 'PaternalGrandmotherHistoryId',
                options: this.paternalGrandmotherHistories,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.applicationfamilyhistory && this.applicationfamilyhistory.PaternalGrandmotherHistoryId || null,
            }),
        };

        this.View = {
            Complete: new DynamicLabel(
                'Complete',
                this.applicationfamilyhistory && this.applicationfamilyhistory.hasOwnProperty('Complete') && this.applicationfamilyhistory.Complete !== null ? this.applicationfamilyhistory.Complete : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Submitted: new DynamicLabel(
                'Submitted',
                this.applicationfamilyhistory && this.applicationfamilyhistory.hasOwnProperty('Submitted') && this.applicationfamilyhistory.Submitted !== null ? this.applicationfamilyhistory.Submitted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.applicationfamilyhistory && this.applicationfamilyhistory.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.applicationfamilyhistory && this.applicationfamilyhistory.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            FatherHistoryId: new DynamicLabel(
                'Father History',
                getMetaItemValue(this.fatherHistories, this.applicationfamilyhistory && this.applicationfamilyhistory.hasOwnProperty('FatherHistoryId') && this.applicationfamilyhistory.FatherHistoryId !== null ? this.applicationfamilyhistory.FatherHistoryId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            MaternalGrandfatherHistoryId: new DynamicLabel(
                'Maternal Grandfather History',
                getMetaItemValue(this.maternalGrandfatherHistories, this.applicationfamilyhistory && this.applicationfamilyhistory.hasOwnProperty('MaternalGrandfatherHistoryId') && this.applicationfamilyhistory.MaternalGrandfatherHistoryId !== null ? this.applicationfamilyhistory.MaternalGrandfatherHistoryId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            MaternalGrandmotherHistoryId: new DynamicLabel(
                'Maternal Grandmother History',
                getMetaItemValue(this.maternalGrandmotherHistories, this.applicationfamilyhistory && this.applicationfamilyhistory.hasOwnProperty('MaternalGrandmotherHistoryId') && this.applicationfamilyhistory.MaternalGrandmotherHistoryId !== null ? this.applicationfamilyhistory.MaternalGrandmotherHistoryId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            MotherHistoryId: new DynamicLabel(
                'Mother History',
                getMetaItemValue(this.motherHistories, this.applicationfamilyhistory && this.applicationfamilyhistory.hasOwnProperty('MotherHistoryId') && this.applicationfamilyhistory.MotherHistoryId !== null ? this.applicationfamilyhistory.MotherHistoryId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            PaternalGrandfatherHistoryId: new DynamicLabel(
                'Paternal Grandfather History',
                getMetaItemValue(this.paternalGrandfatherHistories, this.applicationfamilyhistory && this.applicationfamilyhistory.hasOwnProperty('PaternalGrandfatherHistoryId') && this.applicationfamilyhistory.PaternalGrandfatherHistoryId !== null ? this.applicationfamilyhistory.PaternalGrandfatherHistoryId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            PaternalGrandmotherHistoryId: new DynamicLabel(
                'Paternal Grandmother History',
                getMetaItemValue(this.paternalGrandmotherHistories, this.applicationfamilyhistory && this.applicationfamilyhistory.hasOwnProperty('PaternalGrandmotherHistoryId') && this.applicationfamilyhistory.PaternalGrandmotherHistoryId !== null ? this.applicationfamilyhistory.PaternalGrandmotherHistoryId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
