<div class="auth-layout">
    <div class="auth-layout__left">
        <img src="../assets/logo-white.png" height="79" width="150" alt="tweb logo" />
    </div>
    <div class="auth-layout__right" *ngIf="!badInfo && unsubscribing">
        <div class="auth-layout__right__children">
            <div class="auth-layout__form-container">
                <h2 class="auth-layout__h2">Unsubscribe</h2>
                <p>To stop receiving information from The World Egg & Sperm Bank at <b>{{ emailAddress }}</b>, please
                    click the <b>Unsubscribe</b> button, below.</p>
                <br />
                <button type="button" (click)="unsubscribeUser()" mt-doubleClickDisabled
                    [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="margin-bottom__xl button button--primary">
                    Unsubscribe
                </button>
            </div>
        </div>
    </div>
    <div class="auth-layout__right" *ngIf="badInfo">
        <div class="auth-layout__right__children">
            <div class="auth-layout__form-container">
                <h2 class="auth-layout__h2">Unsubscribe Errror</h2>
                <p>
                    Unfortunately, we could not find a donor with the id # provided in the Url and/or the e-mail address
                    provided in the Url does not match the e-mail address
                    of the specified user.
                </p>
                <br />
                <p>
                    We are unable to stop notifications being sent without a valid donor and matching e-mail address.
                </p>
                <br />
            </div>
        </div>
    </div>
    <div class="auth-layout__right" *ngIf="unsubscribeError">
        <div class="auth-layout__right__children">
            <div class="auth-layout__form-container">
                <h2 class="auth-layout__h2">Unsubscribe Errror</h2>
                <p>
                    Unfortunately, we are unable to stop notifications being sent to this e-mail address at this time.
                    Please try again later.
                </p>
                <br />
            </div>
        </div>
    </div>
    <div class="auth-layout__right" *ngIf="unsubscribeSuccess">
        <div class="auth-layout__right__children">
            <div class="auth-layout__form-container">
                <h2 class="auth-layout__h2">Confirmed</h2>
                <p>
                    You will no longer receive e-mail notifications from the World Egg & Sperm Bank.
                </p>
                <br />
                <p>If you change your mind, please send us a message at <a
                        href="info@theworldeggbank.com">info@theworldeggbank.com</a>, or give us a call on <a
                        href='tel:6026781906'>(602) 678-1906.</a>
                </p>
            </div>
        </div>
    </div>
</div>