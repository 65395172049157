export enum AncestryTypes {
    African_American = 1,
    Alsace = 2,
    Argentinean = 3,
    Armenian = 4,
    Austrian = 5,
    Belgian = 6,
    Bolivian = 7,
    Brazilian = 8,
    Bulgarian = 9,
    Canadian = 10,
    Chilean = 11,
    Chinese = 12,
    Colombian = 13,
    Costa_Rican = 14,
    Creole = 15,
    Croatian = 16,
    Czech = 17,
    Danish = 18,
    Dutch = 19,
    East_Indian = 20,
    Ecuadorian = 21,
    Egyptian = 22,
    English = 23,
    Ethiopian = 24,
    Filipino = 25,
    Finnish = 26,
    French = 27,
    French_Canadian = 28,
    German = 29,
    Greek = 30,
    Guatemalan = 31,
    Honduran = 32,
    Hungarian = 33,
    Icelandic = 34,
    Iranian = 35,
    Irish = 36,
    Israeli = 37,
    Italian = 38,
    Jamaican = 39,
    Japanese = 40,
    Jewish = 41,
    Korean = 42,
    Lebanese = 43,
    Mexican = 44,
    Moroccan = 45,
    Native_American = 46,
    Nicaraguan = 47,
    Norwegian = 48,
    Pacific_Islander = 49,
    Pakistani = 50,
    Palestinian = 51,
    Panamanian = 52,
    Paraguayan = 53,
    Persian = 54,
    Polish = 55,
    Portuguese = 56,
    Puerto_Rican = 57,
    Romanian = 58,
    Russian = 59,
    Scandinavian = 60,
    Scottish = 61,
    Slavic = 62,
    Slovakian = 63,
    South_African = 64,
    Spanish = 65,
    Swedish = 66,
    Swiss = 67,
    Taiwanese = 68,
    Thai = 69,
    Turkish = 70,
    Ukrainian = 71,
    Uruguayan = 72,
    Venezuelan = 73,
    Vietnamese = 74,
    Welsh = 75,
    Yugoslavian = 76,
    Zairian = 77,
    Other = 78,
}
