import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from '@model/interfaces/user';
import { IDonorRegistrationPayload } from '@model/interfaces/custom/donor-registration-payload';

@Injectable({
    providedIn: 'root',
})
export class RegistrationService {
    private emptyUser: IUser = {
        AuthUserId: 0,
        DateCreated: new Date(),
        Email: null,
        FirstName: null,
        Id: 0,
        LastName: null,
        Version: null,
    };

    constructor(public http: HttpClient) { }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    registerDonor(data: IDonorRegistrationPayload): Observable<number> {
        return this.http.post<number>(`/donor-portal/register`, data);
    }
}
