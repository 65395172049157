import { Component, OnInit, Renderer2, AfterViewInit, NgZone, Input, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService, IGoogleLoginObject, MtAuthGuard } from '@mt-ng2/auth-module';

import { IGoogleProfile } from '../../libraries/google-sign-in.library';
import { LoginService } from '../../services/login.service';
import { LoginConfig } from '../../libraries/login-config.library';

declare const gapi: any;

@Component({
    selector: 'app-google-login',
    styleUrls: ['./google-login.component.less'],
    templateUrl: './google-login.component.html',
})
export class GoogleLoginComponent implements OnInit, AfterViewInit {
    @Input() config: LoginConfig;
    auth2: any;
    returnUrl: string;

    protected authService: AuthService;
    protected router: Router;
    protected notificationsService: NotificationsService;
    protected document: Document;
    protected ngZone: NgZone;
    protected loginService: LoginService;
    protected route: ActivatedRoute;

    constructor(injector: Injector, protected renderer2: Renderer2) {
        this.authService = injector.get(AuthService);
        this.router = injector.get(Router);
        this.notificationsService = injector.get(NotificationsService);
        this.document = injector.get(DOCUMENT);
        this.ngZone = injector.get(NgZone);
        this.loginService = injector.get(LoginService);
        this.route = injector.get(ActivatedRoute);
    }

    ngOnInit(): void {
        this.getGoogleScripts();
        this.returnUrl = this.route.snapshot.queryParams[MtAuthGuard.Return_Url_QueryParam];
    }

    getGoogleScripts(): void {
        if (!this.config.googleAuthConfig.googleApiKey) {
            throw 'googleAuthConfig is missing the googleApiKey';
        }
        // shoot google api script tags onto page
        let s = this.renderer2.createElement('script');
        s.src = 'https://apis.google.com/js/platform.js';
        s.async = true;
        s.defer = true;
        this.renderer2.appendChild(this.document.body, s);
        s = this.renderer2.createElement('script');
        s.src = 'https://apis.google.com/js/api.js';
        s.async = true;
        s.defer = true;
        this.renderer2.appendChild(this.document.body, s);
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.setupGoogleSignIn(), 1000);
    }

    setupGoogleSignIn(): void {
        gapi.load('auth2', () => {
            this.auth2 = gapi.auth2.init({
                client_id: this.config.googleAuthConfig.googleApiKey,
            });
            this.auth2.attachClickHandler(
                document.getElementById('googleSignInButton'),
                {},
                this.onGoogleSignIn.bind(this),
                this.onGoogleSignInFailure.bind(this),
            );
        });
    }

    onGoogleSignIn(googleUser): void {
        const profile = googleUser.getBasicProfile();
        const googleProfile: IGoogleProfile = {
            email: profile.getEmail(),
            familyName: profile.getFamilyName(),
            givenName: profile.getGivenName(),
            id: profile.getId(),
            imageUrl: profile.getImageUrl(),
            name: profile.getName(),
        };
        this.ngZone.run(() => {
            const token: string = googleUser.getAuthResponse().id_token;
            this.handleGoogleSignIn(googleProfile, token);
        });
        this.loginService.signedInGoogleUser.next(googleProfile);
    }

    handleGoogleSignIn(googleUser: IGoogleProfile, token: string): void {
        const googleLoginObject: IGoogleLoginObject = {
            email: googleUser.email,
            firstName: googleUser.givenName,
            lastName: googleUser.familyName,
            token: token,
        };
        this.authService.googleLogin(googleLoginObject).subscribe(
            (successResponse) => {
                // this is a workaround to a known issue
                // https://github.com/angular/angular/issues/18254
                if (this.returnUrl) {
                    this.router.navigateByUrl(this.returnUrl);
                } else {
                    this.router.navigate(['/home']);
                }
            },
            (errorResponse) => {
                if (errorResponse.status === 418) {
                    this.notificationsService.error(this.config.messageOverrides.googleSignInFailureEmail);
                } else {
                    this.notificationsService.error(this.config.messageOverrides.googleSignInFailure);
                }
            },
        );
    }

    onGoogleSignInFailure(): void {}
}
