import {
    GoogleAuthConfig,
    LoginComponentLink,
    ILoginConfig,
    LoginModuleConfigToken,
    IMessageOverrides,
    IRememberFeature,
    RememberOptions,
} from '@mt-ng2/login-module-config';
import { Injectable, Inject, Optional } from '@angular/core';
import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';

@Injectable()
export class LoginConfig {
    static readonly failedPattern = 'Valid passwords must be at least 8 characters, have a capital letter and number.';
    static readonly forgotPasswordLabel =
        'Provide the email associated with your account and click Submit. An email will be sent with a link to reset your password.';
    static readonly forgotPasswordSuccess = `Reset link generated. Please check your email for further instructions.`;
    static readonly googleSignInFailureEmail = `Email not found in our system. Please try again later, or contact your system administrator.`;
    static readonly loginLabel = 'Sign in to start your session';
    static readonly resetPasswordLabel = 'Please set a new Password for your account.';
    static readonly signInButtonText = 'Sign In';
    static readonly signInFailure = `Sorry, we were unable to process your request at this time. It's not your fault. Please try again later, or contact your system administrator.`;
    static readonly userNamePasswordFailure = 'Username or Password is not correct.';

    private _rememberOption: RememberOptions;
    message?: string;
    signInButtonText?: string;
    allowRememberMe?: boolean;
    forgotPasswordMessage?: string;
    googleAuthConfig?: GoogleAuthConfig;
    hideRegularSignIn?: boolean;
    passwordPattern: RegExp;
    failedPatternMessage: string;
    loginComponentLinks: LoginComponentLink[];
    messageOverrides: IMessageOverrides;
    rememberFeature: IRememberFeature;

    errorHandler = new ComponentErrorHandler('ILoginConfig', '@mt-ng2/login-module');

    constructor(@Inject(LoginModuleConfigToken) @Optional() config: ILoginConfig) {
        if (!config) {
            config = {};
        }
        if (config.failedPatternMessage || config.forgotPasswordMessage || config.message || config.signInButtonText || config.allowRememberMe) {
            this.errorHandler.addWarning(
                `failedPatternMessage, forgotPasswordMessage, message and signInButtonText properties were deprecated in June 2020. Please use messageOverrides instead.`,
            );
        }
        if (config.allowRememberMe) {
            this.errorHandler.addWarning(`allowRememberMe property was deprecated in October 2020. Please use rememberFeature instead.`);
        }
        this.rememberFeature = this.loadRememberFeatureDefaults(config.rememberFeature);
        this._rememberOption = this.translateDeprecatedRemember(config.allowRememberMe);
        this.message = config?.message ?? LoginConfig.loginLabel;
        this.signInButtonText = config?.signInButtonText ?? LoginConfig.signInButtonText;
        this.allowRememberMe =
            config && typeof config.allowRememberMe !== 'undefined' && config.allowRememberMe !== null ? config.allowRememberMe : true;
        this.forgotPasswordMessage = config?.forgotPasswordMessage ?? LoginConfig.forgotPasswordLabel;
        this.googleAuthConfig = config?.googleAuthConfig ?? null;
        this.hideRegularSignIn = config?.hideRegularSignIn ?? false;
        this.passwordPattern = config.passwordPattern
            ? new RegExp(config.passwordPattern)
            : new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d!$%@#£€*?&]{8,}');
        this.failedPatternMessage = config.failedPatternMessage;
        this.loginComponentLinks = config?.loginComponentLinks ?? [new LoginComponentLink('<a>I forgot my password</a>', '/forgotpassword')];
        this.messageOverrides = this.loadMessageDefaults(config.messageOverrides ?? {});
    }

    private loadMessageDefaults(messageOverrides: IMessageOverrides): IMessageOverrides {
        let defaultMessages: IMessageOverrides = {};
        defaultMessages.failedPattern = messageOverrides.failedPattern ?? LoginConfig.failedPattern;
        defaultMessages.forgotPasswordFailure = messageOverrides.forgotPasswordFailure ?? LoginConfig.signInFailure;
        defaultMessages.forgotPasswordLabel = messageOverrides.forgotPasswordLabel ?? LoginConfig.forgotPasswordLabel;
        defaultMessages.forgotPasswordSuccess = messageOverrides.forgotPasswordSuccess ?? LoginConfig.forgotPasswordSuccess;
        defaultMessages.googleSignInFailure = messageOverrides.googleSignInFailure ?? LoginConfig.signInFailure;
        defaultMessages.googleSignInFailureEmail = messageOverrides.googleSignInFailureEmail ?? LoginConfig.googleSignInFailureEmail;
        defaultMessages.loginLabel = messageOverrides.loginLabel ?? LoginConfig.loginLabel;
        defaultMessages.signInButtonText = messageOverrides.signInButtonText ?? LoginConfig.signInButtonText;
        defaultMessages.userNamePasswordFailure = messageOverrides.userNamePasswordFailure ?? LoginConfig.userNamePasswordFailure;
        defaultMessages.resetPasswordLabel = messageOverrides.resetPasswordLabel ?? LoginConfig.resetPasswordLabel;
        return defaultMessages;
    }

    private loadRememberFeatureDefaults(rememberFeature: IRememberFeature): IRememberFeature {
        let defaultRemembers: IRememberFeature = { rememberOptions: 'rememberMe' };
        return rememberFeature ?? defaultRemembers;
    }

    private translateDeprecatedRemember(allowRememberMe: boolean): RememberOptions {
        if (allowRememberMe === true || allowRememberMe === false) {
            return allowRememberMe ? 'rememberMe' : null;
        }
        return this.rememberFeature?.rememberOptions ?? null;
    }

    public getRememberOptions(): RememberOptions {
        return this._rememberOption;
    }
}
