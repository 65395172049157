import {
    Input,
    Injector,
    ViewContainerRef,
    Renderer2,
    ComponentFactoryResolver,
    ComponentRef,
    NgZone,
    ElementRef,
    OnDestroy,
    ViewChild,
    Output,
    EventEmitter,
    OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';

import { PopupService } from './popup.services';
import { TypeAheadWindowComponent } from './type-ahead-window-component';
import { getProperty, Key, ISelectionChangedEvent, ITypeAheadAPI } from './libraries/type-ahead.library';
import { positionElements, PlacementArray } from './libraries/positioning.library';

export abstract class TypeAheadBaseComponent implements OnInit, OnDestroy {
    public errorHandler: ComponentErrorHandler;
    protected _popupService: PopupService<TypeAheadWindowComponent>;
    protected _windowRef: ComponentRef<TypeAheadWindowComponent>;

    subscriptions = new Subscription();

    inputControl = new FormControl();

    selected: any = null;
    /**
     * This has to be set first for the rest of the component to work.
     * We put it at the top so it starts first, but technically there is
     * a race condition with @see {@link selectedItem}.  Maybe someone can
     * figure out a better way to handle these when you come across this
     * comment <3.
     */
    @Input() nameProperty = 'Name';
    @Input('selectedItem')
    set selectedItem(value: any) {
        if (value) {
            this.selected = value;
            this.inputControl.patchValue(this.getValue(value));
        } else {
            this.selected = null;
            this.inputControl.patchValue(null);
        }
    }
    @Input('disabled')
    set disabled(value: boolean) {
        value ? this.inputControl.disable() : this.inputControl.enable();
    }

    @ViewChild('inputElement', { static: false }) inputElement: ElementRef;
    @Output('selectionChanged') onSelectionChanged = new EventEmitter<ISelectionChangedEvent>();
    @Output('ready') onReady = new EventEmitter<ITypeAheadAPI>();

    /** Placement of a typeahead accepts:
     * "top", "top-left", "top-right", "bottom", "bottom-left", "bottom-right",
     * "left", "left-top", "left-bottom", "right", "right-top", "right-bottom"
     * and array of above values.
     */
    @Input() placement: PlacementArray = 'bottom-left';

    @Input() isModal = false;
    @Input('z-index') zIndex = 1090;
    @Input() maxToShow = 10;
    @Input('empty-text') emptyText = '';
    @ViewChild('inputElementNoItems', { static: false }) inputElementNoItems: ElementRef;
    @Input() minimumCharactersToShow: number;

    constructor(injector: Injector, vcr: ViewContainerRef, renderer: Renderer2, resolver: ComponentFactoryResolver, ngZone: NgZone) {
        this._popupService = new PopupService<TypeAheadWindowComponent>(TypeAheadWindowComponent, injector, vcr, renderer, resolver);

        this.subscriptions.add(
            ngZone.onStable.subscribe(() => {
                if (this.isPopupOpen()) {
                    positionElements(this.inputElement.nativeElement, this._windowRef.location.nativeElement, this.placement, !this.isModal);
                    renderer.setStyle(this._windowRef.location.nativeElement, 'z-index', this.zIndex);
                }
            }),
        );
    }

    ngOnInit(): void {
        this.onReady.emit({
            clearValue: this.clearControlValue.bind(this),
            focus: this.focusMe.bind(this),
            setValue: this.handleSelection.bind(this),
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    focusMe(): void {
        if (this.inputElement && this.inputElement.nativeElement && this.inputElement.nativeElement.focus) {
            this.inputElement.nativeElement.focus();
        }
    }

    clearControlValue(): void {
        this.handleSelection(null);
    }

    getValue(item: any): string {
        try {
            const property = getProperty(item, this.nameProperty);
            if (property === undefined) {
                throw new Error(`Property with name ${this.nameProperty} not found`);
            }
            return property;
        } catch (error) {
            this.errorHandler.addError('getValue failed, check nameProperty to ensure it is valid');
            throw error;
        }
    }

    onBlur(): void {
        if (!this.selected || (this.selected && this.inputElement.nativeElement.value !== this.getValue(this.selected))) {
            this.handleSelection(null);
        }
        this.closePopup();
    }

    handleSelection(selected: any): void {
        this.selected = selected;
        if (selected) {
            this.inputControl.patchValue(this.getValue(selected));
        } else {
            this.inputControl.patchValue('');
        }
        this.onSelectionChanged.emit({ selection: selected });
    }

    /**
     * Returns true if the typeahead popup window is displayed
     */
    isPopupOpen(): boolean {
        return this._windowRef != null;
    }

    public closePopup(): void {
        this._popupService.close();
        this._windowRef = null;
    }

    handleKeyDown(event: KeyboardEvent): void {
        if (!this.isPopupOpen()) {
            return;
        }
        // tslint:disable-next-line:switch-default
        switch (event.which) {
            case Key.ArrowDown:
                event.preventDefault();
                this._windowRef.instance.moveActiveNext();
                this._windowRef.instance.cdr.detectChanges();
                break;
            case Key.ArrowUp:
                event.preventDefault();
                this._windowRef.instance.moveActivePrevious();
                this._windowRef.instance.cdr.detectChanges();
                break;
            case Key.Enter:
            case Key.Tab:
                if (!this.isPopupOpen()) {
                    break;
                }
                const result = this._windowRef.instance.getActive();
                if (result) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.handleSelection(result);
                } else {
                    this.handleSelection(null);
                }
                this.closePopup();
                break;
            case Key.Escape:
                event.preventDefault();
                this.handleSelection(null);
                this.closePopup();
                break;
        }
    }
}
