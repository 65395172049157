import { Component, OnInit, OnDestroy, Injector, isDevMode } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Subscription } from 'rxjs';

import { ClaimsService } from '@mt-ng2/auth-module';

import {
    INavSidebarService,
    NavSidebarContent,
    NavSidebarContentContainer,
    INavSidebarExpandable,
    INavSidebarRowItem,
    INavSidebarParentRowItem,
    NavSidebarServiceToken,
} from '@mt-ng2/nav-module-config';
import { NavService } from '../nav.service';
import { AccessRequestsModuleConfigService } from '@mt-ng2/access-requests-module-config';
import { take } from 'rxjs/operators';

@Component({
    animations: [
        trigger('childRowAnim', [
            transition(':enter', [style({ height: '0px', opacity: 0, overflow: 'hidden' }), animate('350ms', style({ height: '100%', opacity: 1 }))]),
            transition(':leave', [style({ height: '100%', opacity: 1, overflow: 'hidden' }), animate('200ms', style({ height: '0px', opacity: 0 }))]),
        ]),
    ],
    selector: `sidebar-content-section`,
    template: `
        <!-- here is where we are repeating contents -->
        <ng-container *ngFor="let content of contentItems">
            <ul
                *ngIf="!content.headerHasComponent"
                class="sidebar-menu"
                (click)="toggleExpanded(content.header)"
                [style.cursor]="content.header.canToggleExpand ? 'pointer' : 'default'"
            >
                <li class="header" [innerHtml]="content.header.content"></li>
            </ul>
            <ng-container
                *ngIf="!sidebarCollapsed && content.headerHasComponent && showMe(content.header)"
                navDynamicContent
                [componentRef]="content.headerComponent"
                [item]="content.header"
            ></ng-container>
            <ul [@childRowAnim] *ngIf="content.header.expanded" class="sidebar-menu nav-tree">
                <li class="treeview" *ngFor="let row of content.rows">
                    <app-nav-menu-item *ngIf="!row.rowHasComponent(content.rowComponent)" [rowItem]="row"></app-nav-menu-item>
                    <ng-container
                        *ngIf="!sidebarCollapsed && row.rowHasComponent(content.rowComponent) && showMe(row)"
                        navDynamicContent
                        [componentRef]="row.getRowComponent(content.rowComponent)"
                        [item]="row"
                    ></ng-container>
                    <ul
                        [@childRowAnim]
                        class="treeview-menu menu-open"
                        [style.display]="row.expanded ? 'block' : 'none'"
                        *ngIf="row.children && row.children.length && row.expanded && showMe(row)"
                    >
                        <li *ngFor="let childRow of row.children">
                            <app-nav-menu-item *ngIf="!childRow.rowHasComponent(content.rowComponent)" [rowItem]="childRow"></app-nav-menu-item>
                            <ng-container
                                *ngIf="!sidebarCollapsed && childRow.rowHasComponent(content.rowComponent) && showMe(childRow)"
                                navDynamicContent
                                [componentRef]="childRow.getRowComponent(content.rowComponent)"
                                [item]="childRow"
                            ></ng-container>
                        </li>
                    </ul>
                </li>
            </ul>
        </ng-container>
    `,
})
export class SidebarContentSectionComponent implements OnInit, OnDestroy {
    sidebarCollapsed: boolean;

    sidebarService: INavSidebarService;

    contentItems: NavSidebarContent[] = [];

    subscriptions: Subscription = new Subscription();
    contentSubscriptions: Subscription = new Subscription();

    accessRequestsEnabled: boolean;

    protected navService: NavService;
    protected claimsService: ClaimsService;
    protected accessRequestsModuleConfigService: AccessRequestsModuleConfigService;

    constructor(injector: Injector) {
        this.sidebarService = injector.get(NavSidebarServiceToken);
        this.navService = injector.get(NavService);
        this.claimsService = injector.get(ClaimsService);
        this.accessRequestsModuleConfigService = injector.get(AccessRequestsModuleConfigService);
        this.setContents(this.sidebarService.content.getValue());
        this.subscriptions.add(this.sidebarService.content.subscribe((content) => this.setContents(content)));
    }

    ngOnInit(): void {
        this.sidebarCollapsed = this.navService.sidebarCollapsed.getValue();
        this.subscriptions.add(
            this.navService.sidebarCollapsed.subscribe((sidebarCollapsed: boolean) => {
                this.sidebarCollapsed = sidebarCollapsed;
            }),
        );
        this.accessRequestsModuleConfigService.accessRequestsEnabled
            .pipe(take(1))
            .subscribe((accessRequestsEnabled) => (this.accessRequestsEnabled = accessRequestsEnabled));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.contentSubscriptions.unsubscribe();
    }

    setContents(contents: NavSidebarContentContainer[]): void {
        this.contentSubscriptions.unsubscribe();
        const contentItems = contents.map((item, index) => {
            this.contentSubscriptions.add(
                item.content.subscribe((content) => {
                    this.contentItems[index] = content;
                }),
            );
            return item.content.getValue();
        });
        this.contentItems = contentItems;

        this.checkForWarningsAboutShortcuts();
    }

    /**
     * this function checks for content that has the shortcut
     * property when the hasKeyboardShortcutsModule is false
     * and produces a warning for the dev when it finds this
     */
    protected checkForWarningsAboutShortcuts(): void {
        // this is only checked in dev mode
        if (!isDevMode) {
            return;
        }
        // there is nothing to check if the hasKeyboardShortcutsModule is true
        if (this.navService.hasKeyboardShortcutsModule) {
            return;
        }

        this.contentItems.forEach((content) => {
            (content.rows as any[]).forEach((row) => {
                if (row.shortcut) {
                    console.warn(
                        'Some of your menu item content has shortcuts defined, but the KeyboardShortcutsModule has not been enabled.  Be sure that your app has the KeyboardShortcutsModule.forRoot() call and that the hasKeyboardShortcutsModule in your nav config is set to true.',
                    );
                    return;
                }
            });
        });
    }

    toggleExpanded(item: INavSidebarExpandable): void {
        if (!item.canToggleExpand) {
            return;
        }
        item.expanded = !item.expanded;
    }

    showMe(rowItem: INavSidebarRowItem | INavSidebarParentRowItem): boolean {
        if (this.accessRequestsEnabled) {
            return true;
        }

        if (rowItem.claimType && rowItem.claimValues && rowItem.claimValues.length > 0) {
            return this.claimsService.hasClaim(rowItem.claimType, rowItem.claimValues);
        }
        return true;
    }
}
