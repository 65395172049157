import { Component, Input, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDonor } from '@model/interfaces/donor';
import { environment } from '../../environments/environment';
import { IDonorImage } from '@model/interfaces/donor-image';
import { PhotoUploadService } from '../../donor-application/services/photo-upload.service';
import { FileItem } from 'ng2-file-upload';

@Component({
    selector: 'app-donor-photos',
    templateUrl: './donor-photos.component.html',
})
export class DonorPhotosComponent implements OnInit {
    @Input() donor: IDonor;
    @Input() canEdit: boolean;
    @Input() canDelete: boolean;

    isEditing = false;
    doubleClickIsDisabled = false;
    photosForUpload: FileItem[] = [];
    imagesPath: string;
    useLocalImages: boolean;
    localImagePath: string;

    constructor(private notificationsService: NotificationsService, private photoUploadService: PhotoUploadService) {}

    ngOnInit(): void {
        this.imagesPath = environment.s3ImgPath;
        this.useLocalImages = environment.useLocalImages;
        this.localImagePath = environment.imgPath;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    deletePhoto(photo: IDonorImage, event: Event): void {
        event.stopPropagation();
        this.photoUploadService.deletePhoto(photo.DonorId, photo.ImageId).subscribe(() => {
            this.donor.DonorImages = this.donor.DonorImages.filter((di) => di.ImageId !== photo.ImageId);
            this.notificationsService.success('Photo deleted successfully.');
        });
    }

    tryUploadDocuments(): void {
        const files = this.photosForUpload.map((p) => p._file);
        if (files.length <= 0) {
            this.enableDoubleClick();
            return this.notificationsService.error('No files to upload');
        }
        this.photoUploadService.uploadPhotos(this.donor.Id, files).subscribe((files) => {
            this.completeDocumentsSave(files);
        });
    }

    setPhotosForUpload(event: FileItem[]): void {
        this.photosForUpload = event;
    }

    completeDocumentsSave(files: IDonorImage[]): void {
        this.enableDoubleClick();
        this.isEditing = false;
        this.donor.DonorImages.push(...files);
        this.notificationsService.success('Photos uploaded successfully.');
    }

    setDocumentUploadStatus(doc: FileItem, uploaded: boolean): void {
        doc.isUploading = false;
        doc.isUploaded = uploaded;
        doc.isError = !uploaded;
    }

    cancelUpload(): void {
        this.isEditing = false;
        this.photosForUpload = [];
    }

    enableDoubleClick(): void {
        setTimeout(() => (this.doubleClickIsDisabled = false), 200);
    }
}
