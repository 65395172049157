import { Component, Input, ContentChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { trigger, transition, animate, style } from '@angular/animations';
import { IRecommendedRoute } from '../../../model/recommended-route';

@Component({
    animations: [
        trigger('itemAnim', [
            transition(':enter', [
                style({ transform: 'translateX(100%)', opacity: 0 }),
                animate(
                    '300ms',
                    style({ transform: 'translateX(0)', opacity: 1 }),
                ),
            ]),
        ]),
    ],
    selector: 'app-recommended-page',
    styles: [
        `
            .route-card {
                transition: transform 0.2s;
                padding-top: 2px;
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 2px;
                background-color: #fff;
                color: #777;
                cursor: pointer;
            }
            .route-card:hover {
                transform: scale(1.03);
            }
            .route-card:hover h2 {
                color: white;
            }
            .route-card .route-title {
                margin-right: 30px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                padding-bottom: 15px;
            }
            .route-card:hover .route-title {
                color: cornflowerblue;
                text-decoration: underline;
            }
            .route-card .route-arrow {
                visibility: hidden;
                position: absolute;
                top: 20px;
                right: 50px;
                transition: transform 0.5s;
                font-size: 30px;
                color: cornflowerblue;
            }
            .route-card:hover .route-arrow {
                visibility: visible;
                transform: translateX(30px);
            }
            .route-card .route-text {
                font-weight: 700;
                font-size: 18px;
                border-bottom: solid 1px #ccc;
                padding-bottom: 5px;
                color: white;
                display: none;
            }
            .route-card:hover .route-text {
                display: inline-block;
            }
            .route-separator {
                margin-top: 0;
                margin-bottom: 0;
                border: 0;
                border-top: 1px solid #ddd;
            }
        `,
    ],
    templateUrl: './recommended-page.component.html',
})
export class RecommendedPageComponent {
    @ContentChild(TemplateRef) templateRef;

    subscriptions: Subscription = new Subscription();
    @Input() recommendedRoute: IRecommendedRoute;
    @Input() lastItem = false;

    constructor(public router: Router) { }

    navigateToRecommendedPage(): void {
        this.router.navigate([`/${this.recommendedRoute.Route}`]);
    }
}
