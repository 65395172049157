export enum BirthControlTypes {
    Pills = 1,
    Patch = 2,
    Vaginal_Ring = 3,
    IUD = 4,
    Implant_Nexplanon_Implanon = 5,
    Depo_Provera_Injection = 6,
    Condoms = 7,
    Diaphragm_Cervical_Cup = 8,
    Tubal_Litgation = 9,
    Hysterectomy = 10,
    Partner_Hysterectomy = 11,
    Other = 12,
    None = 13,
}
