import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';
import { IUser } from '@model/interfaces/user';
import { Observable } from 'rxjs';

export const emptyApplicationWhoAreYou: IApplicationWhoAreYou = {
    BodyStructureId: null,
    CollegeGpaId: null,
    CollegeMajor: null,
    Complete: null,
    Submitted: null,
    DateCreated: new Date(),
    EyeColorId: null,
    HairTextureId: null,
    HighSchoolGpaId: null,
    Id: 0,
    NaturalHairColorId: null,
    OtherAncestryFather: null,
    OtherAncestryMother: null,
    RaceId: null,
    SkinTypeId: null,
};

@Injectable({
    providedIn: 'root',
})
export class ApplicationWhoAreYouService extends BaseService<IApplicationWhoAreYou> {
    constructor(public http: HttpClient) {
        super('/donor-portal/application-who-are-yous', http);
    }

    getEmptyApplicationWhoAreYou(): IApplicationWhoAreYou {
        return { ...emptyApplicationWhoAreYou };
    }

      ///
    /// Update who-are-you and user (first name, last name etc.) information
    ///
    updateWhoAreYouEtc(whoAreYou: IApplicationWhoAreYou, user: IUser): Observable<any> {
        const data = { ApplicationWhoAreYou: whoAreYou, User: user };
        return this.http.post(`/donor-portal/application-who-are-yous/update-who-are-you-etc/`, data);
    }
}
