import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { sortByProperty } from '@mt-ng2/common-functions';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IState } from '@model/interfaces/state';
import { ICountry } from '@model/interfaces/country';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';

@Injectable()
export class CommonService {
    private _states: IState[];
    private _statesMeta: IStateMetaItem[];
    private _countries: ICountry[];
    private _countriesMeta: ICountryMetaItem[];

    constructor(private http: HttpClient) {}

    getStates(): Observable<IState[]> {
        if (!this._states) {
            return this.http.get<IState[]>('/options/states').pipe(
                map((answer) => {
                    return answer.filter((item) => item.CountryCode === 'US');
                }),
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._states = answer;
                }),
            );
        } else {
            return of(this._states);
        }
    }

    getAllStates(): Observable<IState[]> {
        if (!this._states) {
            return this.http.get<IState[]>('/options/states').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._states = answer;
                }),
            );
        } else {
            return of(this._states);
        }
    }

    getCountries(): Observable<ICountry[]> {
        if (!this._countries) {
            return this.http.get<ICountry[]>('/options/countries').pipe(
                tap((answer) => {
                    this._countries = answer;
                }),
            );
        } else {
            return of(this._countries);
        }
    }

    getStateMetaItems(): IStateMetaItem[] {
        if (!this._statesMeta) {
            this._statesMeta = this._states.map((state, i) => {
                return {
                    CountryCode: state.CountryCode,
                    Id: i,
                    Name: state.Name,
                    StateCode: state.StateCode,
                };
            });
        }
        return this._statesMeta;
    }

    getCountryMetaItems(): ICountryMetaItem[] {
        if (!this._countriesMeta) {
            this._countriesMeta = this._countries.map((country, i) => {
                return {
                    Alpha3Code: country.Alpha3Code,
                    CountryCode: country.CountryCode,
                    Id: i,
                    IsAccountRegion: country.IsAccountRegion,
                    Name: country.Name,
                };
            });
        }
        return this._countriesMeta;
    }

    getAllStateMetaItems(): IStateMetaItem[] {
        if (!this._statesMeta) {
            this._statesMeta = this._states.map((state, i) => {
                return {
                    CountryCode: state.CountryCode,
                    Id: i,
                    Name: state.Name,
                    StateCode: state.StateCode,
                };
            });
        }
        return this._statesMeta;
    }
}
