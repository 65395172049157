export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    UserRoles = 4,
    Audit = 6,
    Clinics = 7,
    DonorApplication = 9,
    Donors = 10,
    Appointments = 11,
    Notifications = 12,
    Recipients = 13,
    DonorCycles = 14,
    ManagedLists = 15,
    DonorCycleScreening = 16,
    DonorCycleStim = 17,
    DonorCycleRetrieval = 18,
    Reservations = 19,
    ViewEditSSN = 21,
    Reports = 22,
    ApplicantDonor_Dataset = 23,
    Sales_Dataset = 24,
    FrozenEggInventory_Dataset = 25,
    Download_Donor_Documents = 26,
    Delete_Donor_Documents = 27,
    Delete_Warmings = 28,
    Edit_Application_Notes = 29,
    Edit_Donor_Notes = 30,
    Edit_Recipient_Notes = 31,
    Edit_Clinic_Notes = 32,
}
