import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IApplicationImageDTO } from '@model/interfaces/custom/application-images-dto';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { ApplicationService } from '../../donor-application/services/application.service';

@Component({
    selector: 'application-identity-photo-uploader',
    styleUrls: [],
    templateUrl: './application-identity-photo-uploader.component.html',
})
export class ApplicationIdentityPhotoUploaderComponent implements OnInit {
    @Input() applicationId: Subject<number>;
    @Output('onQueueChange') onQueueChange: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
    allowedMimeType: string[] = ['image/jpg', 'image/jpeg'];
    maxFileSize = 100 * 1024 * 1024;
    isHTML5 = true;
    uploader: FileUploader;
    localImageUrl: string;
    thumbnails: { file: FileItem; path: SafeUrl; ready: boolean }[] = [];

    adminUploadedPhotos: FileItem[] = [];

    constructor(public sanitizer: DomSanitizer, private applicationService: ApplicationService, public notificationService: NotificationsService) {}

    ngOnInit(): void {
        this.uploader = new FileUploader({
            allowedMimeType: this.allowedMimeType,
            isHTML5: this.isHTML5,
            maxFileSize: this.maxFileSize,
        });
        this.uploader.onAfterAddingFile = (file) => {
            const image_file = file._file;
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.thumbnails.push({ file: file, path: this.sanitizer.bypassSecurityTrustUrl(reader.result.toString()), ready: true });
            });
            reader.readAsDataURL(image_file);
            this.onQueueChange.emit(this.uploader.queue);
        };

        this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {
            this.notificationService.error('File must be jpg or jpeg format.');
        };

        this.applicationId.subscribe((id) => {
            if (id) {
                this.getAdminUploadedIdentityImages(id);
            }
        });
    }

    removeFile(index: number): void {
        this.thumbnails = this.thumbnails.filter((f, i) => i !== index);
        this.uploader.queue = this.uploader.queue.filter((f, i) => i !== index);
        this.onQueueChange.emit(this.uploader.queue);
    }

    getAdminUploadedIdentityImages(applicationId: number): void {
        this.applicationService.getAdminUploadedIdentityImages(applicationId).subscribe((identityImages: IApplicationImageDTO[]) => {
            for (const identityImage of identityImages) {
                let imageFile = this.getImageFile(identityImage);
                let fileUploaderOptions: FileUploaderOptions = {
                    allowedMimeType: this.allowedMimeType,
                    isHTML5: this.isHTML5,
                    maxFileSize: this.maxFileSize,
                };
                let fileUploader = new FileUploader(fileUploaderOptions);
                let imageFileItem = new FileItem(fileUploader, imageFile, fileUploaderOptions);

                this.addThumbnail(imageFileItem, imageFile);
                this.uploader.queue.push(imageFileItem);
                this.adminUploadedPhotos.push(imageFileItem);
                this.onQueueChange.emit(this.uploader.queue);
            }
        });
    }

    private addThumbnail(imageFileItem: FileItem, imageFile: File): void {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.thumbnails.push({ file: imageFileItem, path: this.sanitizer.bypassSecurityTrustUrl(reader.result.toString()), ready: true });
        });
        reader.readAsDataURL(imageFile);
    }

    private getImageFile(image: IApplicationImageDTO): File {
        let output = this.convertBase64StringToUint8Array(image.Data);
        let blob = new Blob([output as BlobPart], { type: 'image/jpeg' });
        return new File([blob], image.Name, { lastModified: new Date().getTime(), type: blob.type });
    }

    private convertBase64StringToUint8Array(imageDataString: string): Uint8Array {
        let byteCharacters = atob(imageDataString);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteNumbers);
    }
}
