import { ILoginConfig } from '@mt-ng2/login-module-config';
import { environment } from '../../environments/environment';

export const LoginConfigOverride: ILoginConfig = {
    googleAuthConfig: environment.googleApiKey ? { googleApiKey: environment.googleApiKey } : null,
    messageOverrides: {
        failedPattern: 'Valid passwords must be at least 8 characters, have a capital letter and number.',
    },
    passwordPattern: '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d!$%@#£€*?&]{8,}',
    rememberFeature: {
        rememberOptions: 'rememberMe',
    },
};
