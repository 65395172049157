import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IAddress, IPhone } from '@model/interfaces/base';

import { AuthService } from '@mt-ng2/auth-module';
import { IDonor } from '@model/interfaces/donor';
import { DonorStatuses } from '../../model/enums/donor-statuses.enum';
import { DonorService } from '../donor.service';
import { Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
declare let gtag: Function;

@Component({
    selector: 'app-donor-detail',
    templateUrl: './donor-detail.component.html',
})
export class DonorDetailComponent implements OnInit {
    donor: IDonor;
    currentUser;
    canEdit: boolean;
    donorAddress: IAddress;
    donorPhones: IPhone[] = [];
    editingComponent: Subject<any> = new Subject();
    doubleClickIsDisabled = false;
    canEditPhotos = false;

    constructor(private donorService: DonorService, private authService: AuthService, private notificationsService: NotificationsService, private router: Router) {}

    ngOnInit(): void {
        this.getDonor();
        this.setUpConversionTracker();
    }

    getDonor(): void {
        this.donorService.getDonorByUserId().subscribe((donor) => {
            this.donor = donor;
            // check that donor has been published
            // TODO: Will need to add check for interview scheduled as well
            this.canEdit = this.donor.DonorStatusId === DonorStatuses.Published;
            this.currentUser = this.authService.currentUser.getValue();
            if (donor.Address) {
                this.donorAddress = donor.Address;
                this.donorPhones = donor.User.UserPhones;
            } else {
                this.donorAddress = null;
            }

        });
    }

    saveAddress(address): void {
        this.donorService.saveAddress(this.donor.Id, address).subscribe((answer) => {
            if (answer?.AddressId) {
                address.Id = answer.AddressId;
            }
            this.notificationsService.success('Address Saved Successfully');
            this.donor.Address = address;
            this.donorAddress = address;
        });
    }

    downloadFactSheet(): void {
        this.donorService.downloadFactsSheet().subscribe((x: any) => {
            const thefile = new Blob([<ArrayBuffer>x], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, 'My Fact Sheet.pdf');
        });
    }

    private setUpConversionTracker(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                gtag('event', 'conversion', {'send_to': 'AW-851385468/eNcbCNnEv5ADEPy4_JUD'});
            });
    }
}
