<div class="miles-card padded">
    <h4>Documents to Review and Sign</h4>
    <div *ngFor="let template of templates">
        <div class="row">
            <div class="col-md-12">
                <div class="display-padded">
                    <b [ngClass]="{ 'docs-to-sign': !template.Completed }">{{ template.DocumentTemplate.Name }}</b
                    ><button
                        *ngIf="!template.Completed"
                        class="pull-right btn btn-flat btn-primary"
                        (click)="getDocument(template.DocumentTemplateId)"
                        [disabled]="!isVerified"
                    >
                        View
                    </button>
                    <span *ngIf="template.Completed" class="pull-right"><em>Completed</em></span>
                </div>
            </div>
        </div>
    </div>
</div>

<modal-wrapper *ngIf="pendingDocuments" [options]="modalOptions">
    <h4>You have document(s) which require your attention.</h4>
    <p *ngIf="!isVerified" class="small brandPurple">However, you must verify your email before you can view and sign documents.</p>
</modal-wrapper>
