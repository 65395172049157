import { Component } from '@angular/core';

@Component({
    selector: 'reset-banner',
    styles: [
        `
            .reset-banner {
                z-index: 1;
                background-color: #652365;
                color: white;
                border-radius: 10px;
                width: 100%;
                text-align: center;
                padding: 10px;
            }
        `,
    ],
    template: `
        <div class="reset-banner">
            <p class="paragraph__large ">
                If this is your first time accessing this site after November 14th, please use the 'Forgot Password' link to reset your password and
                log in.
            </p>
        </div>
    `,
})
export class ResetBannerComponent {
    constructor() {}
}
