export enum DonorStatuses {
    Pending = 1,
    InterviewScheduled = 2,
    DonorConsentComplete = 3,
    HfeaDocumentsComplete = 4,
    Published = 5,
    Denied = 6,
    Approved = 7,
    TemporarilyInactive = 8,
    PermanentlyInactive = 9,
    Inactive = 10,
    InterviewComplete = 11,
}
