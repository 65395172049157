import { Component, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormControlComponentBase } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'tweb-select-dynamic-field-component',
    template: `
        <dynamic-form-select-wrapper [parentControl]="thisControl" [formGroup]="getGroup()">
            <label class="input-label">
                <span class="input-scaffold__label"
                    >{{ config.label }}
                    <span *ngIf="required" style="color:red"> *</span>
                </span>
                <select
                    #inputElement
                    [style.cursor]="'pointer'"
                    [class.disabled]="config.disabled"
                    (blur)="onBlur()"
                    (focus)="onFocus()"
                    [formControlName]="config.name"
                >
                    <option *ngFor="let option of config.options" [ngValue]="option.Id">
                        {{ option.Name }}
                    </option>
                </select>
            </label>
        </dynamic-form-select-wrapper>
    `,
})
export class TwebSelectComponent extends CustomFormControlComponentBase {
    required = true;
    constructor(changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }

    ngOnInit(): void {
        this.config.insideBoxValidation = true;
        this.required = this.config.validators.required || this.config.validation.includes(Validators.required);
    }
}
