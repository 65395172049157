import { Component, Input, OnInit, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { ISharedEntityComponentConfig, IIsSharedEntity } from '../interfaces/shared-entity';
import { BlankSharedEntityConfig } from '../classes/shared-entity.library';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { Observable, forkJoin } from 'rxjs';
import { IEntity } from '@mt-ng2/entity-list-module';
import { IMetaItemListDefinition, IIsMetaItemService } from '@mt-ng2/base-service';

@Component({
    selector: 'shared-entity',
    template: `
        <div
            class="miles-editable miles-card padded"
            *ngIf="!isEditing"
            (mouseenter)="isHovered = true"
            (mouseleave)="isHovered = false"
            (click)="edit()"
            [style.cursor]="_canEdit ? 'pointer' : 'default'"
        >
            <h4>
                {{ _sharedEntityConfig.EntityName }}
                <span class="pull-right" *ngIf="_canEdit" [hidden]="!isHovered">
                    <i class="fa fa-lg fa-edit"></i>
                </span>
            </h4>
            <app-dynamic-view *ngIf="entityId > 0" [config]="config.viewOnly"></app-dynamic-view>
            <i *ngIf="entityId === 0">Not entered</i>
        </div>
        <div *ngIf="isEditing" class="miles-form padded">
            <h4>{{ _sharedEntityConfig.EntityName }}</h4>
            <app-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="save($event)">
                <button type="submit" Class="btn btn-flat btn-success">Save</button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </app-dynamic-form>
        </div>
    `,
})
export class SharedEntityComponent implements OnInit {
    @Input() entityId: number;

    _sharedEntityEnum: number;
    @Input('sharedEntityId')
    set sharedEntity(value: number) {
        this._sharedEntityEnum = value;
    }

    private _entity: IEntity = { Id: 0 };
    get entity(): IEntity {
        return this._entity;
    }
    set entity(value: IEntity) {
        this._entity = value;
        this.config = this._sharedEntityConfig.getDynamicFormConfig(value);
    }

    _sharedEntityConfig: ISharedEntityComponentConfig<IEntity> = new BlankSharedEntityConfig();
    config: IDynamicFormConfig = { formObject: [], viewOnly: [] };
    service: IIsSharedEntity<IEntity>;
    isEditing = false;
    isHovered: boolean;
    parentId: number;

    _canEdit: boolean;
    @Input('canEdit')
    set canEdit(value: boolean) {
        this._canEdit = value;
    }

    constructor(
        private route: ActivatedRoute,
        private injector: Injector,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.setVariables();
    }

    setVariables(): void {
        const config: IEntityRouteConfig = <IEntityRouteConfig>this.route.parent.snapshot.data;
        if (config?.entityIdParam) {
            this.parentId = +this.route.parent.snapshot.paramMap.get(config.entityIdParam);
        }
        if (config) {
            let sharedEntityConfig = <any>config;
            let sharedEntity = sharedEntityConfig.sharedEntities.find((item) => item.sharedEntity === this._sharedEntityEnum);
            this.service = this.injector.get(sharedEntity.service);
            this._sharedEntityConfig = new sharedEntity.config();
        }
        if (this._canEdit === undefined) {
            this._canEdit = config?.claimType ? this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]) : false;
        }

        this.getInitilizationProcesses().subscribe((answer) => {
            this._sharedEntityConfig.setMetaItemLists(answer);
            if (this.entityId > 0) {
                this.getEntity();
            } else {
                this.entity = { ...this._sharedEntityConfig.EmptyEntity };
            }
        });
    }

    getInitilizationProcesses(): Observable<IMetaItemListDefinition[]> {
        let observables = [];
        let metaItemServices = this._sharedEntityConfig.MetaItemServices;
        for (let mis of metaItemServices) {
            let metaItemService = <IIsMetaItemService>this.injector.get(mis);
            observables.push(metaItemService.MetaItemListServiceInitilizer.getItems());
        }
        return forkJoin<IMetaItemListDefinition>(observables);
    }

    getEntity(): void {
        this.service.getEntity(this.parentId, this.entityId).subscribe((entity) => {
            this.entity = entity;
        });
    }

    edit(): void {
        if (this._canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    save(entityForm): void {
        if (entityForm.valid) {
            let entity = this._sharedEntityConfig.getFormValues(this.entity, entityForm.value);
            this.saveEntity(entity);
        } else {
            this.notificationsService.error('Save Failed');
            markAllFormFieldsAsTouched(entityForm);
        }
    }

    private saveEntity(entity: IEntity): void {
        this.service.saveEntity(this.parentId, entity).subscribe(
            (success) => {
                this.notificationsService.success(`${this._sharedEntityConfig.EntityName} Saved Successfully`);
                if (this.entityId === 0) {
                    this.entityId = success;
                }
                this.getEntity();
                this.isEditing = false;
            },
            (error) => this.notificationsService.error('Save Failed'),
        );
    }
}
