import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { ModalWrapperComponent } from './components/modal-wrapper.component';

@NgModule({
    declarations: [ModalWrapperComponent],
    exports: [ModalWrapperComponent],
    imports: [CommonModule, SweetAlert2Module.forRoot()],
})
export class ModalModule {}
