import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { IApplicationValidationDTO } from '@model/interfaces/custom/application-validation-dto';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApplicationService } from './services/application.service';

@Injectable()
export class CanSubmitGuard implements CanActivate {
    constructor(private applicationService: ApplicationService) {}

    canActivate(): Observable<boolean> {
        return this.applicationService.checkFormComplete().pipe(
            map((response: IApplicationValidationDTO) => {
                return (
                    response.PreScreeningComplete && response.PreScreeningSubmitted &&
                    response.MedicalIssuesComplete && response.MedicalIssuesSubmitted &&
                    response.FamilyHistoryComplete && response.FamilyHistorySubmitted &&
                    response.WhoAreYouComplete && response.WhoAreYouSubmitted &&
                    !response.Complete &&
                    !response.Denied
                );
            }),
            catchError((err) => {
                return of(false);
            }),
        );
    }
}
