import { Component, Input, OnInit } from '@angular/core';
import { FileItem } from 'ng2-file-upload';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IDocumentType } from '@model/interfaces/document-type';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDonorDocument } from '@model/interfaces/donor-document';
import { saveAs } from 'file-saver';
import { DocumentEntityTypes } from '@model/enums/document-entity-types';
import { DocumentTypeDynamicControls } from '@model/form-controls/document-type.form-controls';
import { DonorDocumentTypes } from '@model/enums/document-types';
import { DonorIdsService } from './donor-ids.service';

@Component({
    selector: 'app-donor-ids',
    templateUrl: './donor-ids.component.html',
})
export class DonorIdsComponent implements OnInit {
    @Input() donorId: number;

    showUploadArea = false;
    isUploading = false;

    maxFileSize = 10000000;
    allowedDocumentTypes: string[] = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'application/pdf',
    ];
    documentTypeId: number = DonorDocumentTypes.DriversLicense;

    documentArray: IDonorDocument[] = [];

    constructor(public donorIdsService: DonorIdsService, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.getDocuments();
    }

    error(errorMessage: string): void {
        this.notificationsService.error(errorMessage);
    }

    save(file: FileItem): void {
        this.isUploading = true;
        this.donorIdsService.saveDocument(this.donorId, this.documentTypeId, file._file).subscribe(() => {
            this.getDocuments();
            this.closePreview();
        });
    }

    closePreview(): void {
        this.showUploadArea = false;
        this.isUploading = false;
    }

    cancel(): void {
        this.showUploadArea = false;
    }

    getDocuments(): void {
        this.donorIdsService.getDocuments(this.donorId).subscribe((documents) => {
            this.documentArray = documents;
        });
    }

    deleteDocument(documentId: number): void {
        this.donorIdsService.deleteDocument(this.donorId, documentId).subscribe(() => {
            this.getDocuments();
        });
    }

    downloadDocument(index: number, documentId: number): void {
        this.donorIdsService.getDocumentById(this.donorId, documentId).subscribe((doc: any) => {
            const thefile = new Blob([<ArrayBuffer>doc], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, this.documentArray[index].Document.Name);
        });
    }
}
