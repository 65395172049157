import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IFamilyHistory } from '@model/interfaces/family-history';

export const emptyFamilyHistory: IFamilyHistory = {
    Id: 0,
};

@Injectable({
    providedIn: 'root',
})
export class FamilyHistoryService extends BaseService<IFamilyHistory> {
    constructor(public http: HttpClient) {
        super('/familyhistories', http);
    }

    getEmptyFamilyHistory(): IFamilyHistory {
        return { ...emptyFamilyHistory };
    }
}
