import { DynamicFormModuleConfig } from './../../libraries/dynamic-form-module.config';
import { Component, Injector, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbTimeStruct, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';

import { CustomFormControlComponentBase } from '../custom-form-component.base';
import { ITimepickerControlComponentApi } from '@mt-ng2/timepicker-control';

@Component({
    styles: [
        `
            .clear-button {
                margin-left: 5px;
            }
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
            .time-input-group {
                display: flex;
                flex-direction: row;
            }
        `,
    ],
    templateUrl: './form-time-picker.component.html',
})
export class FormTimePickerComponent extends CustomFormControlComponentBase {
    @ViewChild('timePicker', { static: false }) timePicker: ElementRef;
    @Output() blur: EventEmitter<any> = new EventEmitter<any>();
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();

    timepickerControlApi: ITimepickerControlComponentApi;
    timeValue: NgbTimeStruct;
    timeControl: FormControl;

    get showClearButton(): boolean {
        let shouldShowClearButton = this.config.type.timepickerOptions?.showClearButton;
        if (shouldShowClearButton === undefined) {
            return this.moduleConfig.timePickerShowClearButton ?? false;
        } else {
            return shouldShowClearButton;
        }
    }

    constructor(injector: Injector, private moduleConfig: DynamicFormModuleConfig) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.timeControl = new FormControl(this.timeValue);
        if (this.config.value && typeof this.config.value === 'string') {
            this.setTime(this.config.value);
        }
        this.subscriptions.add(this.timeControl.valueChanges.subscribe(this.timeSelected.bind(this)));
        this.subscriptions.add(this.valueChanges.subscribe(this.setTime.bind(this)));
    }

    setTime(time: string): void {
        if (!time) {
            this.timeControl.setValue(null);
            return;
        }
        let [hours, minutes, seconds] = time.split(':').map((item) => parseInt(item, 10));
        this.timeValue = {
            hour: hours,
            minute: minutes,
            second: seconds,
        } as NgbTimeStruct;
        this.timeControl.setValue(this.timeValue);
        this.changeDetectorRef.detectChanges();
    }

    focusMe(): void {
        this.timepickerControlApi.focus();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.subscriptions.add(
            this.getControl().statusChanges.subscribe((status) => {
                const isDisabled = status === 'DISABLED';
                let timePicker: any = this.timePicker;
                (timePicker as NgbTimepicker).setDisabledState(isDisabled);
            }),
        );
        if (this.isDisabled) {
            let timePicker = this.timePicker as any;
            (timePicker as NgbTimepicker).setDisabledState(true);
            this.changeDetectorRef.detectChanges();
        }
    }

    timepickerHasMeridian(): boolean {
        return this.config.type.timepickerOptions?.meridian ?? false;
    }

    timepickerHasSeconds(): boolean {
        return this.config.type.timepickerOptions?.seconds ?? false;
    }

    timepickerHasSpinners(): boolean {
        return this.config.type.timepickerOptions?.spinners ?? false;
    }

    timeSelected(timeValue?: NgbTimeStruct): void {
        if ((timeValue as any) === '' && this.getControl() && this.getControl().value !== '') {
            this.getControl().setValue(null);
            return;
        }
        const time = timeValue ? (timeValue as NgbTimeStruct) : (this.timeControl.value as NgbTimeStruct);
        // bypass if first time through and unset
        if (!time || !this.timeIsValid(time)) {
            return;
        }
        // patch value
        const timeAsString = `${this.padTimeWithZero(time.hour)}:${this.padTimeWithZero(time.minute)}:${this.padTimeWithZero(time.second)}`;
        if (timeAsString !== this.getControl().value) {
            this.getControl().setValue(timeAsString);
            this.getControl().markAsDirty();
            // also doing markastouched since blur is not working form timepicker
            this.getControl().markAsTouched();
        }
    }

    private timeIsValid(time: NgbTimeStruct): boolean {
        return (time?.hour || time?.hour === 0) && (time?.minute || time?.minute === 0) && (time?.second || time?.second === 0) ? true : false;
    }

    private padTimeWithZero(number): string {
        let numberAsString = number.toString();
        return numberAsString.length === 1 ? `0${numberAsString}` : numberAsString;
    }

    clear(): void {
        this.getControl().reset();
    }
}
