import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface ISearchbarControlAPI {
    clearSearch: () => void;
    getSearchControl: () => FormControl;
}

@Component({
    selector: 'mt-search-bar',
    template: `
        <div>
            <div class="input-group">
                <input type="text" [formControl]="searchControl" class="form-control" placeholder="Search" />
                <span class="input-group-btn">
                    <button type="button" (click)="clearSearch()" class="btn btn-default btn-nohover btn-flat">
                        <i class="fa"></i>
                        <i class="fa fa-remove" aria-hidden="true"></i>
                        <i class="fa"></i>
                    </button>
                </span>
            </div>
        </div>
    `,
})
export class MtSearchBarComponent implements OnInit, OnDestroy {
    @Output('searchControlCreated')
    searchControlCreated: EventEmitter<FormControl> = new EventEmitter<FormControl>();
    @Output('onSearch')
    onSearch: EventEmitter<string> = new EventEmitter<string>();
    @Output('ready') onReady = new EventEmitter<ISearchbarControlAPI>();

    searchControl = new FormControl();

    searchControlSubscription: Subscription;

    ngOnInit(): void {
        this.searchControlSubscription = this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => this.onSearch.emit(value));

        this.searchControlCreated.emit(this.searchControl);

        this.onReady.emit({
            clearSearch: this.clearSearch.bind(this),
            getSearchControl: (() => this.searchControl).bind(this),
        });
    }

    ngOnDestroy(): void {
        this.searchControlSubscription.unsubscribe();
    }

    clearSearch(): void {
        this.searchControl.setValue('');
    }
}
